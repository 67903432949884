export enum LibraryPageEnum {
  TABLE_HEADER = "Library",
  THUMBNAIL_COLUMN_LABEL = "Thumbnail",
  DETAILS_COLUMN_LABEL = "Details",
  PRICE_COLUMN_LABEL = "Price",
  ACTIVE_COLUMN_LABEL = "Status",
  AI_COLUMN_LABEL = "AI-enabled",
  CREATED_DATE_COLUMN_LABEL = "Created",
  MENU_ACTIVATE_OPTION_LABEL = "Activate",
  MENU_DEACTIVATE_OPTION_LABEL = "Deactivate",
  MENU_VIEW_OPTION_LABEL = "View",
  MENU_EDIT_OPTION_LABEL = "Edit",
}
