/** @jsxRuntime classic */
/** @jsx jsx */
// vendor
import { jsx, css } from "@emotion/react";
import Typography from "@mui/material/Typography";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const ICON_SIZE_MAP = {
  large: "100px",
  medium: "75px",
  small: "50px",
};

const WRAPPER_SIZE_MAP = {
  large: "125px",
  medium: "100px",
  small: "75px",
};

export function FileThumbnail({
  fileName,
  size = "small",
}: {
  fileName: string;
  size?: "small" | "medium" | "large";
}) {
  return (
    <div
      css={css({
        display: "flex",
        flexDirection: "column",
        margin: "12px",
        height: WRAPPER_SIZE_MAP[size],
        width: WRAPPER_SIZE_MAP[size],
      })}
    >
      <FilePresentIcon sx={{ color: "gray", fontSize: ICON_SIZE_MAP[size] }} />
      <Typography variant="caption" sx={{ color: "gray" }}>
        {fileName}
      </Typography>
    </div>
  );
}
