// vendor
import { useState } from "react";
import { Outlet } from "react-router-dom";

// material ui
import MuiContainer from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// internal
import { Header } from "../Header";
import { NavDrawer } from "../NavDrawer";
import { useNotifications } from "../../providers";

const boxStyles = {
  width: "100%",
  height: "100%",
  backgroundColor: "#e1e1e1",
};

const LayoutContainer = styled(MuiContainer)(({ theme }) => ({
  height: "100%",
  minHeight: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: 0,
  [theme.breakpoints.up("sm")]: {
    paddingLeft: `calc(${theme.spacing(8)} + 1px)`,
  },
}));

export function Layout() {
  const { notification, handleClose } = useNotifications();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={boxStyles}>
      <Header onMenuClick={handleDrawerOpen} open={drawerOpen} />
      <NavDrawer handleDrawerClose={handleDrawerClose} open={drawerOpen} />
      <LayoutContainer maxWidth="xl" disableGutters>
        <Outlet />
      </LayoutContainer>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={notification.open}
        autoHideDuration={notification.timeout}
        onClose={handleClose}
        message={notification.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
}
