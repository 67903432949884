// vendor
import React, { useState, useMemo, useEffect } from "react";

// material ui
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";

// internal
import { MenuTableCell } from "../../components";
import { formatTimestampToLocalAlt } from "../../utils";
import { useAllCustomers } from "../../hooks";
import { CustomerDrawer, ViewType } from "./CustomerDrawer";

// types
import type { CustomerSearchFilters } from "../../types";

function SortableTableHeaderCell({
  field,
  onClick,
  orderBy,
  order,
  label,
}: {
  label: string;
  field: CustomerSearchFilters["sort"]["order_by"];
  onClick: () => void;
  orderBy: CustomerSearchFilters["sort"]["order_by"];
  order: CustomerSearchFilters["sort"]["direction"];
}) {
  return (
    <TableCell
      align="left"
      padding="normal"
      sortDirection={orderBy === field ? order : false}
    >
      <TableSortLabel
        active={orderBy === field}
        direction={orderBy === field ? order : "desc"}
        onClick={onClick}
      >
        {label}
        {orderBy === field ? (
          <Box component="span" sx={visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
}

export function CustomerTable() {
  const [drawerState, setDrawerState] = useState<{
    open: boolean;
    customerUUID: string | null;
    activeView: ViewType;
  }>({
    open: false,
    customerUUID: null,
    activeView: "none",
  });

  const { customersList, getAllCustomers, searchFilters } = useAllCustomers();

  useEffect(() => {
    getAllCustomers(searchFilters);
  }, []);

  const transfromedCustomersList = useMemo(() => {
    return customersList.customers.map((customer, index) => {
      return {
        ...customer,
        customer_number: index + 1 + searchFilters.page * searchFilters.limit,
        created_date: formatTimestampToLocalAlt(customer.created_date),
        updated_date: formatTimestampToLocalAlt(customer.updated_date),
      };
    });
  }, [customersList.customers, searchFilters.page, searchFilters.limit]);

  const handleChangePage = (event: unknown, newPage: number) => {
    // setPage(newPage);
    getAllCustomers({ ...searchFilters, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // setLimit(parseInt(event.target.value, 10));
    // setPage(0);
    getAllCustomers({
      ...searchFilters,
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  const updateSearchSort = (
    field: CustomerSearchFilters["sort"]["order_by"],
  ) => {
    const isAsc =
      searchFilters.sort.order_by === field &&
      searchFilters.sort.direction === "asc";
    getAllCustomers({
      ...searchFilters,
      page: 0,
      sort: { order_by: field, direction: isAsc ? "desc" : "asc" },
    });
  };

  const handleCloseDrawer = (open: boolean) => {
    setDrawerState({ open, customerUUID: null, activeView: "none" });
    getAllCustomers(searchFilters);
  };

  return (
    <Container maxWidth="xl" sx={{ paddingTop: "75px" }}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {"Customers"}
            </Typography>
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    {""}
                  </TableCell>
                  <SortableTableHeaderCell
                    label="First name"
                    field="first_name"
                    onClick={() => updateSearchSort("first_name")}
                    orderBy={searchFilters.sort.order_by}
                    order={searchFilters.sort.direction}
                  />
                  <SortableTableHeaderCell
                    label="Last name"
                    field="last_name"
                    onClick={() => updateSearchSort("last_name")}
                    orderBy={searchFilters.sort.order_by}
                    order={searchFilters.sort.direction}
                  />
                  <SortableTableHeaderCell
                    label="Email"
                    field="email"
                    onClick={() => updateSearchSort("email")}
                    orderBy={searchFilters.sort.order_by}
                    order={searchFilters.sort.direction}
                  />
                  <SortableTableHeaderCell
                    label="City"
                    field="city"
                    onClick={() => updateSearchSort("city")}
                    orderBy={searchFilters.sort.order_by}
                    order={searchFilters.sort.direction}
                  />
                  <SortableTableHeaderCell
                    label="State"
                    field="state"
                    onClick={() => updateSearchSort("state")}
                    orderBy={searchFilters.sort.order_by}
                    order={searchFilters.sort.direction}
                  />
                  <SortableTableHeaderCell
                    label="Updated"
                    field="updated_date"
                    onClick={() => updateSearchSort("updated_date")}
                    orderBy={searchFilters.sort.order_by}
                    order={searchFilters.sort.direction}
                  />
                  <SortableTableHeaderCell
                    label="Created"
                    field="created_date"
                    onClick={() => updateSearchSort("created_date")}
                    orderBy={searchFilters.sort.order_by}
                    order={searchFilters.sort.direction}
                  />
                  <TableCell align="left" padding="normal" />
                </TableRow>
              </TableHead>
              <TableBody>
                {transfromedCustomersList.map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="left">{row.customer_number}</TableCell>
                      <TableCell align="left">
                        {row.first_name ?? "-"}
                      </TableCell>
                      <TableCell align="left">{row.last_name ?? "-"}</TableCell>
                      <TableCell align="left">{row.email ?? "-"}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.state}</TableCell>
                      <TableCell align="left">{row?.updated_date}</TableCell>
                      <TableCell align="left">{row?.created_date}</TableCell>
                      <TableCell>
                        <MenuTableCell
                          options={[
                            {
                              key: "view-edit",
                              label: "Quickview",
                              onClickOption: () =>
                                setDrawerState({
                                  open: true,
                                  customerUUID: row.uuid,
                                  activeView: "detail",
                                }),
                            },
                            {
                              key: "activity",
                              label: "Activity",
                              onClickOption: () =>
                                setDrawerState({
                                  open: true,
                                  customerUUID: row.uuid,
                                  activeView: "activity",
                                }),
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 40, 30, 20, 10]}
            component="div"
            count={customersList.total}
            rowsPerPage={searchFilters.limit}
            page={searchFilters.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {drawerState.customerUUID && (
          <CustomerDrawer
            open={drawerState.open}
            customerUUID={drawerState.customerUUID}
            activeView={drawerState.activeView}
            onClose={handleCloseDrawer}
          />
        )}
      </Box>
    </Container>
  );
}
