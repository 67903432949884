// vendor
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// internal
import { useAuth, useNotifications } from "../../providers";
import { FormTextInput, Logo } from "../../components";
import { LOGIN_SCHEMA } from "../../utils";
import { LoggedInRoutesEnum, LoggedOutRoutesEnum } from "../../constants";

interface IFormInput {
  email: string;
  password: string;
}

export function Login() {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const { showNotificaton } = useNotifications();
  const [showError, setShowError] = useState<boolean>(false);

  const { control, handleSubmit } = useForm<IFormInput>({
    resolver: yupResolver(LOGIN_SCHEMA),
  });

  async function handleSignIn(data: IFormInput) {
    setShowError(false);
    const correctedEmail = data.email.toLowerCase();
    return signin(correctedEmail, data.password)
      .then(() => {
        navigate(LoggedInRoutesEnum.DASHBOARD_PAGE);
      })
      .catch(() => {
        setShowError(true);
        showNotificaton("Something went wrong!", 4000);
      });
  }
  return (
    <>
      <Logo parentStyles={{ marginBottom: "2rem" }} />
      {!showError ? (
        <Alert severity="info" sx={{ marginBottom: "2rem" }}>
          If this is your first time logging in with a password, you will need
          to use the Forgot Password option to reset and pick a password
        </Alert>
      ) : (
        <Alert severity="error" sx={{ marginBottom: "2rem" }}>
          There was an error logging in. Please try again.
        </Alert>
      )}
      <FormTextInput
        name="email"
        control={control}
        label="Email"
        size="medium"
      />
      <FormTextInput
        name="password"
        control={control}
        label="Password"
        type="password"
        size="medium"
      />
      <Box
        sx={{
          mt: -2,
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => navigate(LoggedOutRoutesEnum.FORGOT_PASS_PAGE)}
        >
          Forgot Password
        </Button>
      </Box>
      <Button
        size="large"
        variant="contained"
        onClick={handleSubmit(handleSignIn)}
        sx={{ mt: 2, mb: 2 }}
        fullWidth
      >
        Login
      </Button>
      <Button
        variant="text"
        onClick={() => navigate(LoggedOutRoutesEnum.SIGNUP_PAGE)}
      >
        Sign Up
      </Button>
    </>
  );
}
