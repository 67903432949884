// vendor
import { useState, useMemo, useEffect } from "react";
import { eachDayOfInterval, sub, format } from "date-fns";

// material ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HelpIcon from "@mui/icons-material/Help";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import LinearProgress from "@mui/material/LinearProgress";

// internal
import {
  Thumbnail,
  LineChart,
  ImageSelect,
  ProgressContainer,
} from "../../components";
import { useWidgetAnalytics } from "../../hooks";
import { WidgetAnalyticsEnum } from "../../constants";

// types
import { Widget, Image, MonthRange, WidgetStatusEnum } from "../../types";

export function WidgetAnalytics({ widgetData }: { widgetData: Widget }) {
  const [selectedImage, setSelectedImage] = useState<Image>(
    widgetData.images[0] ?? {},
  );
  const [selectedRange, setSelectedRange] = useState<MonthRange>(1);

  const { analyticsResponse, inProgress } = useWidgetAnalytics(
    widgetData.uuid,
    selectedImage.uuid,
    selectedRange,
  );

  const handleRangeChange = (event: SelectChangeEvent) => {
    const newRange = Number(event.target.value); // comes in as a string
    setSelectedRange(newRange as MonthRange);
  };

  useEffect(() => {
    if (widgetData && widgetData.images && widgetData.images.length > 0) {
      setSelectedImage(widgetData.images[0]);
    }
  }, [widgetData]);

  const chartData = useMemo(() => {
    if (analyticsResponse && analyticsResponse.visitor_history) {
      const result = eachDayOfInterval({
        start: sub(new Date(), { days: selectedRange * 30 }),
        end: new Date(),
      });

      const dataMap = analyticsResponse.visitor_history.reduce(
        (accum: { [k: string]: any }, curr) => {
          const key = `${format(new Date(curr.date), "dd-MM-yy")}`;
          if (!accum[key]) {
            accum[key] = curr;
          }
          return accum;
        },
        {},
      );
      return {
        intervals: result.map((date) => format(date, "M/dd")),
        series: result.map((date) => {
          const key = `${format(new Date(date), "dd-MM-yy")}`;
          if (dataMap[key]) {
            return Number(dataMap[key].count);
          } else {
            return 0;
          }
        }),
      };
    } else {
      return {
        intervals: [],
        series: [],
      };
    }
  }, [selectedRange, analyticsResponse]);

  const viewsData = useMemo(() => {
    if (analyticsResponse && analyticsResponse.source_counts) {
      return analyticsResponse.source_counts.reduce(
        (accum: { [k: string]: string }, curr) => {
          const key = curr.analytic_utm_source ?? "unknown";
          accum[key] = curr.count;
          return accum;
        },
        {
          linkedin: "0",
          twitter: "0",
          facebook: "0",
          instagram: "0",
          pinterest: "0",
          unknown: "0",
        },
      );
    } else {
      return {};
    }
  }, [analyticsResponse]);

  if (!!inProgress) {
    return <ProgressContainer />;
  }

  if (
    widgetData.widget_status === WidgetStatusEnum.NEW ||
    widgetData.widget_status === WidgetStatusEnum.QUEUED
  ) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Alert severity="warning" sx={{ marginBottom: "24px" }}>
          {WidgetAnalyticsEnum.INACTIVE_WARNING}
        </Alert>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box>
          <ImageSelect
            images={widgetData.images}
            value={selectedImage.id}
            onChangeFromParent={(img) => setSelectedImage(img)}
          />
        </Box>
        <Box sx={{ paddingTop: "42px" }}>
          <Thumbnail url={selectedImage.image_url} size="xxxlarge" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="select-time-label">
              {WidgetAnalyticsEnum.TIME_FRAME_SELECT_LABEL}
            </InputLabel>
            <Select
              labelId="select-time-label"
              id="select-time"
              value={String(selectedRange)}
              label="Time Frame"
              onChange={handleRangeChange}
              sx={{ maxWidth: "400px" }}
            >
              <MenuItem value={1}>{"last 30 days"}</MenuItem>
              <MenuItem value={2}>{"last 60 days"}</MenuItem>
              <MenuItem value={3}>{"last 90 days"}</MenuItem>
              <MenuItem value={6}>{"last 6 months"}</MenuItem>
              <MenuItem value={12}>{"last 12 months"}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ paddingTop: "42px" }}>
          <LineChart
            label={WidgetAnalyticsEnum.CHART_LABEL}
            intervals={chartData.intervals}
            series={chartData.series}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          {WidgetAnalyticsEnum.TOTAL_ENGAGE_SUB_HEADER}
        </Typography>
        <Typography
          variant="subtitle1"
          component="span"
          sx={{
            padding: "6px 12px",
            display: "inline",
            fontSize: "24px",
            fill: "gray",
          }}
        >
          {analyticsResponse?.unique_visitors ?? 0}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          {WidgetAnalyticsEnum.LINK_VIEW_SUB_HEADER}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              padding: "6px 12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LinkedInIcon
              sx={{
                fontSize: "40px",
                display: "inline",
                marginRight: "6px",
                fill: "gray",
              }}
            />
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ display: "inline", fontSize: "24px", fill: "gray" }}
            >
              {viewsData["linkedin"]}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "6px 12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InstagramIcon
              sx={{
                fontSize: "40px",
                display: "inline",
                marginRight: "6px",
                fill: "gray",
              }}
            />
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ display: "inline", fontSize: "24px", fill: "gray" }}
            >
              {viewsData["instagram"]}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "6px 12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FacebookIcon
              sx={{
                fontSize: "40px",
                display: "inline",
                marginRight: "6px",
                fill: "gray",
              }}
            />
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ display: "inline", fontSize: "24px", fill: "gray" }}
            >
              {viewsData["facebook"]}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "6px 12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PinterestIcon
              sx={{
                fontSize: "40px",
                display: "inline",
                marginRight: "6px",
                fill: "gray",
              }}
            />
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ display: "inline", fontSize: "24px", fill: "gray" }}
            >
              {viewsData["pinterest"]}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "6px 12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TwitterIcon
              sx={{
                fontSize: "40px",
                display: "inline",
                marginRight: "6px",
                fill: "gray",
              }}
            />
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ display: "inline", fontSize: "24px", fill: "gray" }}
            >
              {viewsData["twitter"]}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "6px 12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HelpIcon
              sx={{
                fontSize: "40px",
                display: "inline",
                marginRight: "6px",
                fill: "gray",
              }}
            />
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ display: "inline", fontSize: "24px", fill: "gray" }}
            >
              {viewsData["unknown"]}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" component="h5">
          {WidgetAnalyticsEnum.REFERER_SUB_HEADER}
        </Typography>
        <Paper sx={{ marginTop: "24px" }}>
          {inProgress && <LinearProgress />}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    {"Rank"}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {"HTTP Referer"}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {"Visitor Count"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analyticsResponse?.referrer_counts &&
                  analyticsResponse.referrer_counts.map((refer, idx) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={refer.analytic_http_referrer}
                      >
                        <TableCell align="left">{idx + 1}</TableCell>
                        <TableCell align="left">{`https://${refer.analytic_http_referrer}`}</TableCell>
                        <TableCell align="left">{refer.count ?? "-"}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
