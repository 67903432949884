// vendor
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

// material ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// internal
import { useWidget } from "../../../hooks";
import { MessageWindow } from "./MessageWindow";
import { MessageInput } from "./MessageInput";
import { ChatDetails } from "../ChatDetails/ChatDetails";
import { firebaseUserAtom } from "../../../recoil";
import { useMessages } from "../../../providers";

type MessageInterfaceProps = {
  handleOnClickExit: () => void;
  conversationUid?: string;
};

export function MessageInterface(props: MessageInterfaceProps) {
  const { handleOnClickExit, conversationUid } = props;
  const {
    targetConversation,
    targetConversationMessages,
    sendMessage,
    updateLastReadMessage,
    handleSetTargetConversation,
  } = useMessages();
  const { widget } = useWidget(targetConversation?.widget_uuid);

  const firebaseUser = useRecoilValue(firebaseUserAtom);

  useEffect(() => {
    if (!!conversationUid) {
      handleSetTargetConversation(conversationUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationUid]);

  useEffect(() => {
    updateLastReadMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetConversation?.conversation_uid]);

  function onSendMessage(msg: string) {
    sendMessage(msg);
  }

  function handleExit() {
    handleOnClickExit();
  }

  const isActiveParticipant =
    targetConversation?.members &&
    firebaseUser?.uid &&
    targetConversation?.members.includes(firebaseUser?.uid);

  const showMessageInput =
    targetConversation &&
    !targetConversation.is_ai_enabled &&
    isActiveParticipant;

  return (
    <Grid container spacing={0} sx={{ marginTop: "75px" }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", height: "calc(100vh - 85px)" }}
      >
        <Box
          sx={{
            paddingBottom: "3px",
            border: "1px solid #d2d2d2",
            borderRadius: "5px",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${widget?.images[0]?.image_url})`,
            backgroundPosition: "center center",
            backgroundSize: "90%",
            flex: "1",
            height: "calc(100% - 75px)",
          }}
        >
          <MessageWindow
            messages={targetConversationMessages}
            user={firebaseUser}
          />
          <Box>
            {showMessageInput && <MessageInput onSendMessage={onSendMessage} />}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ padding: "0px 20px" }}>
        <Box
          sx={{
            paddingBottom: "3px",
            height: "100%",
            paddingLeft: "20px",
          }}
        >
          <ChatDetails handleExit={handleExit} />
        </Box>
      </Grid>
    </Grid>
  );
}
