import { useState, useEffect } from "react";
import { uploadFileFromUser, getFilesFromList } from "../../handlers";
import { TrainingFile, TrainingFileSearchResponse } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  errorMessage: string;
  fileListData: { files: TrainingFile[]; total: number };
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  errorMessage: string;
  fileListData: { files: TrainingFile[]; total: number };
  getFiles: (
    fileIdList: number[],
  ) => Promise<void | TrainingFileSearchResponse>;
  postFile: (file: File) => Promise<void | TrainingFile>;
};

export function useTrainingFileService(consumerFileList: number[]): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    errorMessage: "",
    fileListData: { files: [], total: 0 },
  });

  const getFiles = async (
    fileIdList: number[],
  ): Promise<void | TrainingFileSearchResponse> => {
    setRequestState({
      inProgress: true,
      error: false,
      errorMessage: "",
      fileListData: { files: [], total: 0 },
    });
    return getFilesFromList(fileIdList)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          errorMessage: "",
          fileListData: resp,
        });
        return resp;
      })
      .catch(() => {
        setRequestState({
          inProgress: false,
          error: true,
          errorMessage: "Training file fetch failure",
          fileListData: { files: [], total: 0 },
        });
      });
  };

  const postFile = async (file: File): Promise<void | TrainingFile> => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return uploadFileFromUser(file)
      .then((resp) => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
          };
        });
        return resp;
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
            errorMessage: "Training file upload error",
          };
        });
      });
  };

  useEffect(() => {
    if (consumerFileList && consumerFileList.length > 0) {
      getFiles(consumerFileList);
    }
  }, [consumerFileList]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    errorMessage: requestState.errorMessage,
    fileListData: requestState.fileListData,
    getFiles,
    postFile,
  };
}
