// vendor
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export function EditableField({
  fieldName,
  defaultValue,
  fieldLabel,
  schema,
  onUpdate,
  onCancel,
}: {
  fieldName: string;
  defaultValue: string;
  fieldLabel: string;
  schema: any;
  onUpdate: (data: any) => void;
  onCancel: () => void;
}) {
  const { control, handleSubmit } = useForm<{ [fieldName: string]: any }>({
    resolver: yupResolver(schema),
    defaultValues: {
      [fieldName]: defaultValue,
    },
  });
  async function handleUpdate(data: { [fieldName: string]: any }) {
    onUpdate(data);
  }
  return (
    <>
      <Grid item xs={4}>
        <Controller
          name={fieldName}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              size="small"
              error={!!error}
              onChange={onChange}
              value={value ?? ""}
              fullWidth
              label={fieldLabel}
              variant="outlined"
              type="text"
              sx={{ height: "40px" }}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="outlined"
          onClick={onCancel}
          fullWidth
          sx={{ ml: 2, height: "40px" }}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          onClick={handleSubmit(handleUpdate)}
          fullWidth
          sx={{ ml: 2, height: "40px" }}
        >
          Update
        </Button>
      </Grid>
    </>
  );
}
