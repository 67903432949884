// vendor
import React from "react";

// material ui
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// internal
import { CustomerDetail } from "./CustomerDetail";
import { CustomerActvity } from "./CustomerActivity";

export type ViewType = "none" | "detail" | "activity";

export function CustomerDrawer({
  open,
  customerUUID,
  onClose,
  activeView = "none",
}: {
  open: boolean;
  customerUUID: string;
  activeView: ViewType;
  onClose: (open: boolean) => void;
}) {
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      onClose(open);
    };

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box sx={{ padding: "75px 24px 24px 24px", maxWidth: "50vw" }}>
        <Button
          color="primary"
          variant="text"
          onClick={() => onClose(false)}
          sx={{ marginBottom: "6px" }}
        >
          {"<< Close"}
        </Button>
        {activeView === "detail" && (
          <CustomerDetail customerUUID={customerUUID} />
        )}
        {activeView === "activity" && (
          <CustomerActvity customerUUID={customerUUID} />
        )}
      </Box>
    </Drawer>
  );
}
