import { useState, useEffect } from "react";
import { getAllOrgTrainingFiles } from "../../handlers";
import { TrainingFile } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  fileData: { training_files: TrainingFile[]; total: number };
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  fileData: { training_files: TrainingFile[]; total: number };
};

export function useAllTrainingFiles(
  page: number,
  limit: number,
  already_in_use: boolean,
): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    fileData: { training_files: [], total: 0 },
  });

  const getTrainingFiles = async (
    parentPage: number,
    parentLimit: number,
    parentInUse: boolean,
  ): Promise<void> => {
    setRequestState({
      inProgress: true,
      error: false,
      fileData: { training_files: [], total: 0 },
    });
    return getAllOrgTrainingFiles(parentPage, parentLimit, parentInUse)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          fileData: resp,
        });
      })
      .catch(() => {
        setRequestState({
          inProgress: false,
          error: true,
          fileData: { training_files: [], total: 0 },
        });
      });
  };

  useEffect(() => {
    getTrainingFiles(page, limit, already_in_use);
  }, [page, limit, already_in_use]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    fileData: requestState.fileData,
  };
}
