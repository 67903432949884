import { useState, useEffect } from "react";
import { uploadLogoFromUser, getImagesFromList } from "../../handlers";
import { Image, ImageSearchResponse } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  errorMessage: string;
  logoData: Image | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  errorMessage: string;
  logoData: Image | null;
  getLogo: (parentLogoImageId: number) => Promise<void | ImageSearchResponse>;
  postLogo: (file: File) => Promise<void | Image>;
};

export function useLogo(logoImageID?: number): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    errorMessage: "",
    logoData: null,
  });

  const getLogo = async (
    parentLogoImageId: number,
  ): Promise<void | ImageSearchResponse> => {
    setRequestState({
      inProgress: true,
      error: false,
      errorMessage: "",
      logoData: null,
    });
    return getImagesFromList([parentLogoImageId])
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          errorMessage: "",
          logoData: resp.images[0],
        });
        return resp;
      })
      .catch(() => {
        setRequestState({
          inProgress: false,
          error: true,
          errorMessage: "Logo fetch failure",
          logoData: null,
        });
      });
  };

  const postLogo = async (file: File): Promise<void | Image> => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return uploadLogoFromUser(file)
      .then((resp) => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
          };
        });
        return resp;
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
            errorMessage: "Logo upload error",
          };
        });
      });
  };

  useEffect(() => {
    if (!!logoImageID) {
      getLogo(logoImageID);
    }
  }, [logoImageID]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    errorMessage: requestState.errorMessage,
    logoData: requestState.logoData,
    getLogo,
    postLogo,
  };
}
