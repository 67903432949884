// vendor
import { useNavigate } from "react-router-dom";

// material ui
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// internal
import { WalkThrough, WalkThroughStepsEnum } from "../WalkThrough";
import Badge from "@mui/material/Badge";

// material icons
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import SettingsIcon from "@mui/icons-material/Settings";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";

// internal
import {
  NAV_DRAWER_WIDTH,
  LoggedInRoutesEnum,
  NavBarEnum,
} from "../../constants";
import { useUserStats } from "../../providers";

type DrawerProps = {
  open?: boolean;
  handleDrawerClose?: () => void;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: NAV_DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: NAV_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function NavDrawer({
  open = false,
  handleDrawerClose = () => {},
}: DrawerProps) {
  const navigate = useNavigate();
  const { userStats } = useUserStats();

  const conversationCount =
    (userStats.unread_conversations ?? 0) +
    (userStats.conversations_requiring_support ?? 0);

  const handleNavigation = (path: string) => {
    handleDrawerClose();
    navigate(path);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation(LoggedInRoutesEnum.DASHBOARD_PAGE)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={NavBarEnum.DASHBOARD}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <WalkThrough locationID={WalkThroughStepsEnum.NAV_CREATE_WIDGET_BTN}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleNavigation(LoggedInRoutesEnum.CREATE_PAGE)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                primary={NavBarEnum.CREATE}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </WalkThrough>

        <WalkThrough locationID={WalkThroughStepsEnum.WIDGET_LIBRARY}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleNavigation(LoggedInRoutesEnum.LIBRARY_PAGE)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PhotoLibraryIcon />
              </ListItemIcon>
              <ListItemText
                primary={NavBarEnum.LIBRARY}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </WalkThrough>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              handleNavigation(LoggedInRoutesEnum.CONVERSATIONS_PAGE)
            }
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {conversationCount > 0 ? (
                <Badge badgeContent={conversationCount} color="error">
                  <QuestionAnswerIcon />
                </Badge>
              ) : (
                <QuestionAnswerIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={NavBarEnum.CONVERSATIONS}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation(LoggedInRoutesEnum.CUSTOMERS_PAGE)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText
              primary={NavBarEnum.CUSTOMERS}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              handleNavigation(LoggedInRoutesEnum.WIDGET_SEARCH_PAGE)
            }
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ImageSearchIcon />
            </ListItemIcon>
            <ListItemText
              primary={NavBarEnum.SEARCH}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation(LoggedInRoutesEnum.IMAGES_PAGE)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PermMediaIcon />
            </ListItemIcon>
            <ListItemText
              primary={NavBarEnum.IMAGES}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              handleNavigation(LoggedInRoutesEnum.TRAINING_FILES_PAGE)
            }
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <FolderCopyIcon />
            </ListItemIcon>
            <ListItemText
              primary={NavBarEnum.TRAINING_FILES}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ marginTop: "auto" }}>
          <ListItemButton
            onClick={() => handleNavigation(LoggedInRoutesEnum.SETTINGS_PAGE)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={NavBarEnum.SETTINGS}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
