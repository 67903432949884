import { useState, useEffect } from "react";
import { getImagesFromList, uploadImageFromUser } from "../../handlers";
import { Image, ImageSearchResponse } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  errorMessage: string;
  imageListData: { images: Image[]; total: number };
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  errorMessage: string;
  imageListData: { images: Image[]; total: number };
  getImages: (imageIdList: number[]) => Promise<void | ImageSearchResponse>;
  postImage: (file: File) => Promise<void | Image>;
};

export function useImageService(consumerImageList: number[]): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    errorMessage: "",
    imageListData: { images: [], total: 0 },
  });

  const getImages = async (
    imageIdList: number[],
  ): Promise<void | ImageSearchResponse> => {
    setRequestState({
      inProgress: true,
      error: false,
      errorMessage: "",
      imageListData: { images: [], total: 0 },
    });
    getImagesFromList(imageIdList)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          errorMessage: "",
          imageListData: resp,
        });
        return resp;
      })
      .catch(() => {
        setRequestState({
          inProgress: false,
          error: true,
          errorMessage: "Image fetch failure",
          imageListData: { images: [], total: 0 },
        });
      });
  };

  const postImage = async (file: File): Promise<void | Image> => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return uploadImageFromUser(file)
      .then((resp) => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
          };
        });
        return resp;
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
            errorMessage: "Image upload error",
          };
        });
      });
  };

  useEffect(() => {
    if (Array.isArray(consumerImageList)) {
      getImages(consumerImageList);
    }
  }, [consumerImageList]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    errorMessage: requestState.errorMessage,
    imageListData: requestState.imageListData,
    getImages,
    postImage,
  };
}
