// vendor
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// material ui
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// internal
import { useAuth, useNotifications } from "../../providers";
import { Logo } from "../../components";
import { LoggedOutRoutesEnum, LoggedInRoutesEnum } from "../../constants";
import { useLocalStorage, LocalStorageItems } from "../../hooks";

export function PasswordlessVerification() {
  const { verifyPasswordlessLink, signInWithPasswordLessLink } = useAuth();
  const navigate = useNavigate();
  const { showNotificaton } = useNotifications();
  const { getLocalStorageItem } = useLocalStorage();
  const [viewState, setViewState] = useState<{
    showError: boolean;
    showSuccess: boolean;
  }>({
    showError: false,
    showSuccess: false,
  });

  async function verifyAndSignIn() {
    const isValidHref = await verifyPasswordlessLink(window?.location?.href);
    // validate the firebase query params
    if (isValidHref) {
      let email = getLocalStorageItem(LocalStorageItems.SIGNIN_EMAIL);
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }

      signInWithPasswordLessLink(email as string, window.location.href)
        .then(() => {
          setViewState({
            showError: false,
            showSuccess: true,
          });
          navigate(LoggedInRoutesEnum.DASHBOARD_PAGE);
        })
        .catch(() => {
          setViewState({
            showError: true,
            showSuccess: false,
          });
          showNotificaton("Something went wrong!", 4000);
        });
    } else {
      setViewState({
        showError: true,
        showSuccess: false,
      });
    }
  }

  useEffect(() => {
    verifyAndSignIn();
  }, []);

  return (
    <>
      <Logo parentStyles={{ marginBottom: "4rem" }} />
      {!!viewState.showError && (
        <>
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            There was an issue verifying your email and the link provided.
            Please return to the login page and try again.
          </Alert>
          <Button
            variant="text"
            onClick={() => navigate(LoggedOutRoutesEnum.LOGIN_PAGE)}
          >
            Back To Login Page
          </Button>
        </>
      )}
      {!!viewState.showSuccess && (
        <Alert severity="success" sx={{ marginBottom: "12px" }}>
          Email verified!
        </Alert>
      )}
      {!viewState.showError && !viewState.showSuccess && (
        <>
          <Alert severity="info" sx={{ marginBottom: "12px" }}>
            One moment, while we verify your email...
          </Alert>
          <CircularProgress />
        </>
      )}
    </>
  );
}
