// vendor
import axios from "axios";

// internal
import { getHeaders, API_BASE } from "../shared";

// types
import type {
  WidgetAnalyticsResponse,
  MonthRange,
  CustomerAnalyticsSearchResponse,
} from "../../types";

export const getWidgetAnalyticsData = async (
  widget_uuid: string,
  image_uuid: string,
  month_range: MonthRange,
): Promise<WidgetAnalyticsResponse | null> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/analytics/widget`,
      headers,
      data: { widget_uuid, image_uuid, month_range },
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch analytics", e);
    throw e;
  }
  return result;
};

export const getCustomerAnalyticsData = async (
  customer_uuid: string,
): Promise<CustomerAnalyticsSearchResponse | null> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/analytics/customer`,
      headers,
      data: { customer_uuid },
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch analytics", e);
    throw e;
  }
  return result;
};
