// vendor
import { PropsWithChildren } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";

// internal
import {
  ErrorPage,
  Login,
  PasswordlessSignUp,
  PasswordlessLogin,
  PasswordlessVerification,
  ForgotPassword,
  Dashboard,
  Conversations,
  ChatView,
  CreateWidget,
  CustomerTable,
  WidgetLibrary,
  WidgetView,
  EditWidget,
  WidgetSearchPage,
  Settings,
  ImagesPage,
  TrainingFilesPage,
} from "../containers";
import { Layout, AuthLayout } from "../components";
import { globalLoggedInAtom } from "../recoil";
import { LoggedOutRoutesEnum, LoggedInRoutesEnum } from "../constants";

function PrivateRoute({ children }: PropsWithChildren) {
  const userIsLoggedIn = useRecoilValue(globalLoggedInAtom);
  return !userIsLoggedIn ? (
    <Navigate to={LoggedOutRoutesEnum.LOGIN_PAGE} replace />
  ) : (
    children
  );
}

function PublicRoute({ children }: PropsWithChildren) {
  const userIsLoggedIn = useRecoilValue(globalLoggedInAtom);
  return !userIsLoggedIn ? (
    children
  ) : (
    <Navigate to={LoggedInRoutesEnum.DASHBOARD_PAGE} replace />
  );
}

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={LoggedOutRoutesEnum.LOGGED_OUT_PREFIX}
          element={<AuthLayout />}
          errorElement={<ErrorPage />}
        >
          <Route
            path={LoggedOutRoutesEnum.SIGNUP_PAGE}
            element={
              <PublicRoute>
                <PasswordlessSignUp />
              </PublicRoute>
            }
          />
          <Route
            path={LoggedOutRoutesEnum.LOGIN_PAGE}
            element={
              <PublicRoute>
                <PasswordlessLogin />
              </PublicRoute>
            }
          />
          <Route
            path={LoggedOutRoutesEnum.PASSWORD_LOGIN_PAGE}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={LoggedOutRoutesEnum.PASSWORDLESS_VERIFY}
            element={
              <PublicRoute>
                <PasswordlessVerification />
              </PublicRoute>
            }
          />
          <Route
            path={LoggedOutRoutesEnum.FORGOT_PASS_PAGE}
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path={LoggedOutRoutesEnum.LOGGED_OUT_PREFIX}
            element={<Navigate to={LoggedOutRoutesEnum.LOGIN_PAGE} replace />}
          />
        </Route>
        <Route
          path={LoggedInRoutesEnum.LOGGED_IN_PREFIX}
          element={<Layout />}
          errorElement={<ErrorPage />}
        >
          <Route
            path={LoggedInRoutesEnum.DASHBOARD_PAGE}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.CONVERSATIONS_PAGE}
            element={
              <PrivateRoute>
                <Conversations />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.CHAT_VIEW_PAGE}
            element={
              <PrivateRoute>
                <ChatView />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.CREATE_PAGE}
            element={
              <PrivateRoute>
                <CreateWidget />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.EDIT_PAGE}
            element={
              <PrivateRoute>
                <EditWidget />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.CUSTOMERS_PAGE}
            element={
              <PrivateRoute>
                <CustomerTable />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.LIBRARY_PAGE}
            element={
              <PrivateRoute>
                <WidgetLibrary />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.WIDGET_VIEW_PAGE}
            element={
              <PrivateRoute>
                <WidgetView />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.WIDGET_SEARCH_PAGE}
            element={
              <PrivateRoute>
                <WidgetSearchPage />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.IMAGES_PAGE}
            element={
              <PrivateRoute>
                <ImagesPage />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.TRAINING_FILES_PAGE}
            element={
              <PrivateRoute>
                <TrainingFilesPage />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.SETTINGS_PAGE}
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path={LoggedInRoutesEnum.LOGGED_IN_PREFIX}
            element={
              <Navigate to={LoggedInRoutesEnum.DASHBOARD_PAGE} replace />
            }
          />
        </Route>
        <Route
          path="*"
          element={<Navigate to={LoggedOutRoutesEnum.LOGIN_PAGE} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
