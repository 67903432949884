// vendor
import { useMemo } from "react";

// material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";

// internal
import { useCustomerAnalytics } from "../../hooks";
import { formatDateOnly } from "../../utils";

// types
import type { CustomerAnalyticsSearchResponse, ActiveDate } from "../../types";

export function CustomerAnalyticsTable({
  data,
}: {
  data: CustomerAnalyticsSearchResponse;
}) {
  const transformedDates = useMemo(() => {
    return data.active_dates.map((date: ActiveDate, idx) => {
      return {
        number: idx + 1,
        date: formatDateOnly(date.date),
        count: date.count,
      };
    });
  }, [data]);

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {"Active Days"}
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="left" padding="normal">
                {"Entry"}
              </TableCell>
              <TableCell align="left" padding="normal">
                {"Date"}
              </TableCell>
              <TableCell align="left" padding="normal">
                {"Visit"}
              </TableCell>
              <TableCell align="left" padding="normal" />
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedDates.map((row) => (
              <TableRow hover tabIndex={-1} key={row.date}>
                <TableCell align="left">{row.number}</TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function CustomerActvity({ customerUUID }: { customerUUID: string }) {
  const { analyticsResponse, inProgress } = useCustomerAnalytics(customerUUID);

  return (
    <Box>
      <Typography variant="h3" component="h3" sx={{ marginBottom: "24px" }}>
        {"Customer Activity"}
      </Typography>
      <Box sx={{ padding: "24px 0", borderTop: "1px solid lightgray" }}>
        {inProgress && <CircularProgress />}
        {!inProgress && !analyticsResponse && (
          <Alert severity="info">
            There is not enough activity yet to display.
          </Alert>
        )}
        {!inProgress && !!analyticsResponse && (
          <CustomerAnalyticsTable data={analyticsResponse} />
        )}
      </Box>
    </Box>
  );
}
