// vendor
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HexColorPicker } from "react-colorful";

// material ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

// internal
import { useOrganization, useAppUser, useLogo } from "../../hooks";
import { UPDATE_ORG_SCHEMA } from "../../utils";
import {
  FormTextInput,
  FormSwitch,
  ImageUpload,
  Thumbnail,
} from "../../components";
import { Organization } from "../../types";

interface UpdateOrgFormInput {
  name: string;
  branding_hex: string;
  header_hex: string;
  message_hex: string;
  email_domain?: string;
  allow_domain_join: "true" | "false";
  use_custom_branding: "true" | "false";
  logo_image_id?: number;
}

export function LogoUpload({
  existingLogoId,
  onUploadLogoFromParent = () => {},
}: {
  existingLogoId?: number;
  onUploadLogoFromParent?: (logo_id: number) => void;
}) {
  const { logoData, postLogo } = useLogo(existingLogoId);

  async function handleNewLogo(file: File) {
    const response = await postLogo(file);
    if (response?.id) {
      onUploadLogoFromParent(response.id);
    }
  }

  return (
    <>
      <Grid item xs={12} md={4}>
        <Box sx={{ marginTop: "24px" }}>
          <ImageUpload
            label={!!existingLogoId ? "Upload New Logo" : "Upload Logo"}
            size="medium"
            onSelectImageFromParent={handleNewLogo}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ marginTop: "24px" }}>
          {logoData?.image_url && (
            <Thumbnail url={logoData?.image_url} size="large" />
          )}
        </Box>
      </Grid>
      <Grid item xs={0} md={4} />
    </>
  );
}

export function UpdateOrganizationForm({
  organization,
  updateInProgress,
  onClose,
  onUpdate,
}: {
  organization: Organization;
  updateInProgress: boolean;
  onClose: () => void;
  onUpdate: (data: UpdateOrgFormInput) => void;
}) {
  const { control, handleSubmit, getValues, setValue, watch } =
    useForm<UpdateOrgFormInput>({
      resolver: yupResolver(UPDATE_ORG_SCHEMA),
      defaultValues: {
        name: organization?.name ? organization.name : "",
        branding_hex: organization?.branding_hex
          ? `#${organization?.branding_hex}`
          : "#7030A0",
        header_hex: organization?.header_hex
          ? `#${organization?.header_hex}`
          : "#FFFFFF",
        message_hex: organization?.message_hex
          ? `#${organization?.message_hex}`
          : "#D2D2D2",
        email_domain: organization?.email_domain
          ? organization?.email_domain
          : undefined,
        allow_domain_join: organization?.allow_domain_join ? "true" : "false",
        use_custom_branding: organization?.use_custom_branding
          ? "true"
          : "false",
        logo_image_id: organization?.logo_image?.id
          ? organization?.logo_image?.id
          : undefined,
      },
    });

  const currentBrandingHexValue = watch("branding_hex");
  const currentHeaderHexValue = watch("header_hex");
  const currentMessageHexValue = watch("message_hex");
  const currentLogoValue = watch("logo_image_id");

  async function handleUpdate(data: UpdateOrgFormInput) {
    return onUpdate(data);
  }
  return (
    <Box sx={{ marginBottom: "24px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingRight: "24px",
        }}
      >
        <Typography variant="h5" component="h5" sx={{ marginBottom: "24px" }}>
          {"Edit Settings"}
        </Typography>
        <Button
          variant="text"
          size="large"
          startIcon={<CloseIcon />}
          onClick={onClose}
        >
          {"Close"}
        </Button>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" sx={{ marginBottom: "24px" }}>
            {"Company Info"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            name="name"
            control={control}
            label="Company name"
            size="medium"
          />
        </Grid>
        <Grid item xs={0} md={6} />
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" sx={{ marginBottom: "24px" }}>
            {"Company Email Domain"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSwitch
            name="allow_domain_join"
            control={control}
            onLabel={"Users that share my email domain can join my account"}
            offLabel={"Users must be added manually"}
            size="medium"
          />
        </Grid>
        <Grid item xs={0} md={6} />
        <Grid item xs={12} md={6}>
          <FormTextInput
            name="email_domain"
            control={control}
            label="Email domain"
            size="medium"
          />
        </Grid>
        <Grid item xs={0} md={6} />
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" sx={{ marginBottom: "24px" }}>
            {"Custom Branding"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormSwitch
            name="use_custom_branding"
            control={control}
            onLabel={"Use custom branding"}
            offLabel={"Use default branding"}
            size="medium"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            component="p"
            sx={{ marginBottom: "12px" }}
          >
            {"Primary UI Color:"}
          </Typography>
          <HexColorPicker
            color={currentBrandingHexValue ?? getValues().branding_hex}
            onChange={(val) => setValue("branding_hex", val)}
          />
          <Typography
            variant="caption"
            component="p"
            sx={{ marginTop: "12px", textTransform: "uppercase" }}
          >
            {currentBrandingHexValue}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            component="p"
            sx={{ marginBottom: "12px" }}
          >
            {"Header Color:"}
          </Typography>
          <HexColorPicker
            color={currentHeaderHexValue ?? getValues().header_hex}
            onChange={(val) => setValue("header_hex", val)}
          />
          <Typography
            variant="caption"
            component="p"
            sx={{ marginTop: "12px", textTransform: "uppercase" }}
          >
            {currentHeaderHexValue}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            component="p"
            sx={{ marginBottom: "12px" }}
          >
            {"Message Color:"}
          </Typography>
          <HexColorPicker
            color={currentMessageHexValue ?? getValues().message_hex}
            onChange={(val) => setValue("message_hex", val)}
          />
          <Typography
            variant="caption"
            component="p"
            sx={{ marginTop: "12px", textTransform: "uppercase" }}
          >
            {currentMessageHexValue}
          </Typography>
        </Grid>
        <LogoUpload
          existingLogoId={currentLogoValue ?? getValues().logo_image_id}
          onUploadLogoFromParent={(logoID: number) =>
            setValue("logo_image_id", logoID)
          }
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: "48px" }}>
        {updateInProgress ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleSubmit(handleUpdate)}
            fullWidth
          >
            Update
          </Button>
        )}
      </Grid>
    </Box>
  );
}

export function CompanyTab() {
  const [editing, setEditing] = useState<boolean>(false);
  const { appUser } = useAppUser();
  const { organization, inProgress, updateOrganization } = useOrganization(
    appUser?.organization?.id,
  );
  const isDesktop = useMediaQuery("(min-width:600px)");

  async function transformAndUpdateOrg(data: UpdateOrgFormInput) {
    let payload: { [k: string]: any } = {};
    payload.name = data.name;
    payload.branding_hex = data.branding_hex.slice(1); // cut off pound sign
    payload.header_hex = data.header_hex.slice(1); // cut off pound sign
    payload.message_hex = data.message_hex.slice(1); // cut off pound sign
    payload.allow_domain_join =
      data.allow_domain_join === "true" ? true : false;
    payload.use_custom_branding =
      data.use_custom_branding === "true" ? true : false;
    if (!!data.email_domain) {
      payload.email_domain = data.email_domain;
    }
    if (!!data.logo_image_id) {
      payload.logo_image_id = data.logo_image_id;
    }
    await updateOrganization(payload);
    setEditing(false);
  }

  if (editing && organization) {
    return (
      <UpdateOrganizationForm
        updateInProgress={inProgress}
        organization={organization}
        onUpdate={transformAndUpdateOrg}
        onClose={() => setEditing(false)}
      />
    );
  }

  return (
    <Box sx={{ marginBottom: "24px", width: !isDesktop ? "100%" : "50%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingRight: "24px",
        }}
      >
        <Typography variant="h5" component="h5" sx={{ marginBottom: "24px" }}>
          {"Settings"}
        </Typography>
        <Button
          variant="text"
          size="large"
          startIcon={<EditIcon />}
          onClick={() => setEditing(true)}
        >
          {"Edit"}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Name:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="p">
              {organization?.name ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Email Domain:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="p">
              {organization?.email_domain ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Account Access:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="p">
              {!!organization?.allow_domain_join
                ? "All users that share my email domain can join my account"
                : "Only users I manually add can join my account"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Custom Branding:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="p">
              {!!organization?.use_custom_branding
                ? "Use custom branding"
                : "Use default branding"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Primary UI Color:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ textTransform: "uppercase" }}
            >
              {`#${organization?.branding_hex}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Header Color:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ textTransform: "uppercase" }}
            >
              {`#${organization?.header_hex}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Message Color:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ textTransform: "uppercase" }}
            >
              {`#${organization?.message_hex}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Logo:"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {organization?.logo_image?.image_url && (
              <Thumbnail
                url={organization?.logo_image?.image_url}
                size="large"
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
