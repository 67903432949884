// vendor
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import { Controller, FieldError } from "react-hook-form";

type FormSwitchProps = {
  name: string;
  control: any;
  onLabel: string;
  offLabel: string;
  helperText?: string;
  styleOverrides?: any;
  error?: FieldError;
  size?: "small" | "medium";
};

// reference https://blog.logrocket.com/using-material-ui-with-react-hook-form/

export function FormSwitch({
  name,
  control,
  onLabel,
  offLabel,
  helperText = " ",
  styleOverrides = {},
  error,
  size = "small",
}: FormSwitchProps) {
  return (
    <FormControl size={size} variant={"outlined"} sx={styleOverrides}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label={value === "true" ? onLabel : offLabel}
            sx={!!error ? { color: "error.main" } : undefined}
            control={
              <Switch
                checked={value === "true"}
                onChange={() => onChange(value === "true" ? "false" : "true")}
                size={size}
              />
            }
          />
        )}
      />
      <FormHelperText
        error={!!error}
        sx={{
          "&:first-letter": { textTransform: "capitalize" },
        }}
      >
        {error ? error.message : helperText}
      </FormHelperText>
    </FormControl>
  );
}
