// vendor
import { reduce as _reduce } from "lodash";

// internal
import { WidgetFormInput, WidgetCreationPayload } from "../../types";

export const removeUndefinedFromObject = (data: any): any => {
  return _reduce(
    data,
    (accum: { [k: string]: any }, value, key) => {
      if (value !== undefined && value !== null && value !== "") {
        accum[key] = value;
      }
      return accum;
    },
    {},
  );
};

export const transformWidgetCreationPayload = (
  payload: WidgetFormInput,
): WidgetCreationPayload => {
  const apiReadyResult = removeUndefinedFromObject({
    widget_type: "Sales",
    widget_title: payload.title,
    widget_description: payload.description,
    widget_puid: payload.puid,
    is_ai_enabled: payload.is_ai_enabled === "true" ? true : false,
    is_public: payload.is_public === "true" ? true : false,
    is_age_restricted: payload.is_age_restricted === "true" ? true : false,
    is_indexing_enabled: payload.is_indexing_enabled === "true" ? true : false,
    widget_locations: payload.locations
      ? payload.locations.map((locId) => parseInt(locId))
      : [],
    widget_training_files: payload.files ? payload.files : [],
    widget_images: payload.images ? payload.images : [],
    widget_category_id: payload.widget_category_id,
  });

  const widgetDetails = removeUndefinedFromObject({
    widget_text_data: payload.plain_training_text,
    widget_offer: payload.offer,
    widget_offer_code: payload.offer_code,
    widget_price: payload.price ? parseFloat(payload.price) : undefined,
    start_date: payload.start_date
      ? new Date(payload.start_date).toISOString()
      : undefined,
    end_date: payload.end_date
      ? new Date(payload.end_date).toISOString()
      : undefined,
    widget_cta_text: payload.cta_text,
    widget_cta_url: payload.cta_url,
    widget_greeting: payload.greeting,
    widget_agent_name: payload.agent_name,
  });

  apiReadyResult.widget_details = widgetDetails;

  return apiReadyResult;
};
