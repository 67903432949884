import { createTheme } from "@mui/material/styles";

type ThemeType = "widgetgpt" | "leafwidget";

// widgetgpt or leafwidget
const REACT_APP_THEME: ThemeType =
  (process.env.REACT_APP_THEME as ThemeType) ?? "widgetgpt";

const widgetGptLogos = {
  LOGO_SVG_URL:
    "https://storage.googleapis.com/widgetgpt-app-assets/logos/widget_ai_logo.svg",
  LOGO_SLIM_SVG_URL:
    "https://storage.googleapis.com/widgetgpt-app-assets/logos/widget_ai_logo.svg",
  LOGO_ALT: "widget-gpt-logo",
};

const leafWidgetLogos = {
  LOGO_SVG_URL:
    "https://storage.googleapis.com/leafwidget-app-assets/logos/leaf_widget_logo_main.svg",
  LOGO_SLIM_SVG_URL:
    "https://storage.googleapis.com/leafwidget-app-assets/logos/leaf_widget_logo_main.svg",
  LOGO_ALT: "leaf-widget-logo",
};

export const LOGOS =
  REACT_APP_THEME === "widgetgpt" ? widgetGptLogos : leafWidgetLogos;

const widgetGptTheme = {
  palette: {
    primary: {
      main: "#7030a0",
    },
    secondary: {
      main: "#7A41E1",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
      },
    },
  },
};

const leafWidgetTheme = {
  palette: {
    primary: {
      main: "#3c9339",
    },
    secondary: {
      main: "#56d051",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
      },
    },
  },
};

// A custom theme for this app
const theme = createTheme(
  REACT_APP_THEME === "widgetgpt" ? widgetGptTheme : leafWidgetTheme,
);

export default theme;
