// vendor
import axios from "axios";

// internal
import { getHeaders, getFormDataHeaders, API_BASE } from "../shared";

// types
import type {
  TrainingFileSearchResponse,
  TrainingFile,
  TrainingFileAllResponse,
  TrainingFileStats,
} from "../../types";

export const uploadFileFromUser = async (file: File): Promise<TrainingFile> => {
  let result = null;
  try {
    const formData = new FormData();
    formData.append("file", file);

    const headers = await getFormDataHeaders();
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/training-files/upload`,
      headers,
      data: formData,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to upload image", e);
    throw e;
  }
  return result;
};

export const getFilesFromList = async (
  list: number[],
): Promise<TrainingFileSearchResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/training-files/list`,
      headers,
      data: { file_id_list: list },
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch file list", e);
    throw e;
  }
  return result;
};

export const getAllOrgTrainingFiles = async (
  page: number,
  limit: number,
  already_in_use: boolean,
): Promise<TrainingFileAllResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/training-files/all?page=${page}&limit=${limit}&already_in_use=${already_in_use}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch training files", e);
    throw e;
  }
  return result;
};

export const getAllTrainingFileStats = async (): Promise<TrainingFileStats> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/training-files/stats`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch stats", e);
    throw e;
  }
  return result;
};
