export enum SearchPageEnum {
  PAGE_HEADER = "Share Collection",
  HEADER_TOOLTIP = "You can share a direct link to all of your widgets that have been marked publicly available when you created them. Your customers will be able to browse every widget and interact with them at their convenience. Post your QR Code at your place of business or place your search page link in the bio of your social profile.",
  SOCIAL_SUB_HEADER = "Social Media",
  QR_CODE_HEADER = "QR Code",
}

export enum LinkNamesEnum {
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  PINTEREST = "pinterest",
  LINKEDIN = "linkedin",
  LINK = "link",
}
