import { useState, useEffect } from "react";
import {
  getAllOrgUsers,
  postUserToOrg,
  AddUserPayload,
  deleteUserFromOrg,
} from "../../handlers";
import { WidgetUser } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  userListData: { users: WidgetUser[]; total: number };
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  userListData: { users: WidgetUser[]; total: number };
  getUserList: (page: number, limit: number) => Promise<void>;
  addUser: (payload: AddUserPayload) => Promise<void>;
  deleteUser: (userID: number) => Promise<void>;
};

export function useAllOrgUsers(
  page: number = 0,
  limit: number = 10,
): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    userListData: { users: [], total: 0 },
  });

  const getUserList = async (parentPage: number, parentLimit: number) => {
    setRequestState({
      inProgress: true,
      error: false,
      userListData: { users: [], total: 0 },
    });

    return getAllOrgUsers(parentPage, parentLimit)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          userListData: resp,
        });
      })
      .catch(() => {
        setRequestState({
          inProgress: false,
          error: true,
          userListData: { users: [], total: 0 },
        });
      });
  };

  const addUser = async (payload: AddUserPayload) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });

    return postUserToOrg(payload)
      .then(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
          };
        });
        getUserList(page, limit);
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  const deleteUser = async (userID: number) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return deleteUserFromOrg(userID)
      .then(() => {
        setRequestState({
          userListData: { users: [], total: 0 },
          inProgress: false,
          error: false,
        });
        getUserList(page, limit);
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  useEffect(() => {
    getUserList(page, limit);
  }, [page, limit]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    userListData: requestState.userListData,
    addUser,
    getUserList,
    deleteUser,
  };
}
