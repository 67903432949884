import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getRemoteConfig,
  RemoteConfig,
  getAll,
  fetchAndActivate,
} from "firebase/remote-config";

let app: FirebaseApp;
let config: RemoteConfig;

export function initFirebase() {
  if (!app) {
    const serviceKey: string = process.env.REACT_APP_FIREBASE_SERVICE_KEY ?? "";
    const firebaseConfig = JSON.parse(atob(serviceKey));
    app = initializeApp(firebaseConfig);
  }
  return app;
}

export const defaultConfig = {
  _widgetgpt_prod_signup_access: false,
};

export const getFBFlagValues = () => {
  return getAll(config);
};

export const initFirebaseConfig = async () => {
  if (!app) {
    initFirebase();
  }
  if (!config) {
    config = getRemoteConfig(app);
    // Refetch flag values after 5 seconds for dev
    // config.settings.minimumFetchIntervalMillis = 5000;
    config.defaultConfig = defaultConfig;
    return fetchAndActivate(config);
  }
  return config;
};

export const getFBAuth = () => {
  if (!app) {
    initFirebase();
  }
  return getAuth(app);
};
export const getFBDb = () => {
  if (!app) {
    initFirebase();
  }
  return getFirestore(app);
};
