// vendor
import { useState } from "react";
import { useRecoilState } from "recoil";

// material ui
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

// internal
import { useAuth } from "../../providers";
import { NAV_DRAWER_WIDTH } from "../../constants";
import { Logo } from "../../components";
import { globalLoggedInAtom } from "../../recoil";
import { useAppUser } from "../../hooks";

type HeaderProps = {
  open?: boolean;
  onMenuClick?: () => void;
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: NAV_DRAWER_WIDTH,
    width: `calc(100% - ${NAV_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export function Header({ onMenuClick = () => {}, open = false }: HeaderProps) {
  const { signout } = useAuth();
  const [userIsLoggedIn, setUserIsLoggedIn] =
    useRecoilState(globalLoggedInAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { appUser } = useAppUser();

  function handleSignout() {
    signout()
      .then(() => setUserIsLoggedIn(false))
      .catch(() => {
        // show error banner
      });
  }

  function handleMenuClick() {
    onMenuClick();
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!userIsLoggedIn) {
    return null;
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="open drawer"
              onClick={handleMenuClick}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Logo type="slim" parentStyles={{ maxHeight: "24px" }} />
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="primary"
            >
              <PersonIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <ListItemText>
                  <Typography variant="overline" sx={{ fontWeight: "bold" }}>
                    Logged in as:
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Typography variant="body2">{`${appUser?.first_name} ${appUser?.last_name}`}</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Typography variant="body2">{appUser?.email}</Typography>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleSignout}>Sign Out</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}
