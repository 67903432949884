import { useState, useEffect } from "react";

// internal
import { getWidgetAnalyticsData } from "../../handlers";

// types
import type { WidgetAnalyticsResponse, MonthRange } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  analyticsResponse: WidgetAnalyticsResponse | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  analyticsResponse: WidgetAnalyticsResponse | null;
};

export function useWidgetAnalytics(
  widget_uuid: string,
  image_uuid: string,
  month_range: MonthRange,
): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    analyticsResponse: null,
  });

  const getWidgetAnalytics = async (data: {
    widget_uuid: string;
    image_uuid: string;
    month_range: MonthRange;
  }) => {
    setRequestState({
      analyticsResponse: null,
      inProgress: true,
      error: false,
    });
    return getWidgetAnalyticsData(
      data.widget_uuid,
      data.image_uuid,
      data.month_range,
    )
      .then((resp) => {
        setRequestState({
          analyticsResponse: resp,
          inProgress: false,
          error: false,
        });
      })
      .catch(() => {
        setRequestState({
          analyticsResponse: null,
          inProgress: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    if (widget_uuid && image_uuid && month_range) {
      getWidgetAnalytics({
        widget_uuid,
        image_uuid,
        month_range,
      });
    }
  }, [widget_uuid, image_uuid, month_range]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    analyticsResponse: requestState.analyticsResponse,
  };
}
