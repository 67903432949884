import { useState, useEffect } from "react";

// internal
import {
  getTargetWidgetByUuid,
  updateWidgetFromUser,
  createWidgetFromUser,
  updateWidgetStatus,
} from "../../handlers";
import { useNotifications } from "../../providers";

// types
import type { Widget, WidgetStatus, WidgetCreationPayload } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  widget: Widget | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  widget: Widget | null;
  updateWidget: (payload: WidgetCreationPayload) => Promise<void | Widget>;
  createWidget: (payload: WidgetCreationPayload) => Promise<void | Widget>;
  updateMutlipleWidgetStatus: (status: WidgetStatus) => Promise<void | Widget>;
};

export function useWidget(widgetUUID?: string): HookState {
  const { showNotificaton } = useNotifications();
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    widget: null,
  });

  const getWidget = async (uuid: string): Promise<void> => {
    setRequestState({
      inProgress: true,
      error: false,
      widget: null,
    });
    return getTargetWidgetByUuid(uuid)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          widget: resp,
        });
      })
      .catch(() => {
        setRequestState({
          inProgress: false,
          error: true,
          widget: null,
        });
      });
  };

  const updateWidget = async (
    payload: WidgetCreationPayload,
  ): Promise<void | Widget> => {
    if (requestState?.widget?.id) {
      setRequestState((oldState) => {
        return {
          ...oldState,
          inProgress: true,
          error: false,
        };
      });
      return updateWidgetFromUser(requestState?.widget?.id, payload)
        .then((resp) => {
          setRequestState({
            inProgress: false,
            error: false,
            widget: resp,
          });
        })
        .catch(() => {
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: true,
            };
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  const updateMutlipleWidgetStatus = async (
    status: WidgetStatus,
  ): Promise<void | Widget> => {
    if (requestState?.widget?.id) {
      setRequestState((oldState) => {
        return {
          ...oldState,
          inProgress: true,
          error: false,
        };
      });
      return updateWidgetStatus(requestState?.widget?.id, status)
        .then((resp) => {
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: false,
            };
          });

          if (requestState?.widget?.uuid) {
            return getWidget(requestState?.widget?.uuid);
          }
        })
        .catch(() => {
          showNotificaton(
            "Could not be activated. Check images and product data.",
            4000,
          );
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: true,
            };
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  const createWidget = async (
    payload: WidgetCreationPayload,
  ): Promise<void | Widget> => {
    setRequestState({
      widget: null,
      inProgress: true,
      error: false,
    });
    return createWidgetFromUser(payload)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          widget: resp,
        });
      })
      .catch(() => {
        setRequestState({
          widget: null,
          inProgress: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    if (widgetUUID) {
      getWidget(widgetUUID);
    }
  }, [widgetUUID]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    widget: requestState.widget,
    updateWidget,
    createWidget,
    updateMutlipleWidgetStatus,
  };
}
