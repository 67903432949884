// vendor
import { atom } from "recoil";

// internal
import type { UserState } from "../../types/auth";

export const firebaseUserAtom = atom({
  key: "firebaseUser",
  default: null as UserState,
});
