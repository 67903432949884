// vendor
import axios from "axios";

// internal
import { getHeaders, API_BASE } from "../shared";

// types
import type {
  WidgetSearchResponse,
  WidgetCreationPayload,
  Widget,
  WidgetCategorySearchResponse,
  WidgetStats,
  WidgetStatus,
} from "../../types";

export type GetAllWidgetsFilters = {
  page: number;
  limit: number;
  type?: WidgetStatus | "all";
  puid?: string;
};

export const getAllUserWidgets = async (
  payload: GetAllWidgetsFilters,
): Promise<WidgetSearchResponse | null> => {
  let result = null;
  let urlBase = `${API_BASE}/api/widgets/all?page=${payload.page}&limit=${payload.limit}`;

  if (!!payload.type && payload.type !== "all") {
    urlBase = `${urlBase}&widget_status=${payload.type}`;
  }

  if (!!payload.puid) {
    urlBase = `${urlBase}&widget_puid=${payload.puid}`;
  }

  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: urlBase,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch widgets", e);
    throw e;
  }
  return result;
};

export const getTargetWidgetByUuid = async (
  widgetUuid: string,
): Promise<Widget> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/widgets/uuid/${widgetUuid}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch target customer", e);
    throw e;
  }
  return result;
};

export const createWidgetFromUser = async (
  widgetData: WidgetCreationPayload,
): Promise<Widget> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/widgets`,
      headers,
      data: widgetData,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to create widget", e);
    throw e;
  }
  return result;
};

export const updateWidgetFromUser = async (
  widgetID: number,
  widgetData: WidgetCreationPayload,
): Promise<Widget> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "PUT",
      url: `${API_BASE}/api/widgets/${widgetID}`,
      headers,
      data: widgetData,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to update widget", e);
    throw e;
  }
  return result;
};

export const updateWidgetStatus = async (
  widgetID: number,
  status: WidgetStatus,
): Promise<Widget> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "PUT",
      url: `${API_BASE}/api/widgets/status/${widgetID}?widget_status=${status}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to update widget status", e);
    throw e;
  }
  return result;
};

export const getAllWidgetCategories = async (
  page: number,
  limit: number,
): Promise<WidgetCategorySearchResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/widgets/categories?page=${page}&limit=${limit}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch widget categories", e);
  }
  return result;
};

export const getAllWidgetStats = async (): Promise<WidgetStats> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/widgets/stats`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch stats", e);
    throw e;
  }
  return result;
};
