// vendor
import { useState, useEffect, ReactNode } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { Controller, UseFormSetValue, FieldError } from "react-hook-form";

type FormCheckboxProps = {
  name: string;
  control: any;
  size?: "small" | "medium";
  formLabel?: string;
  label: ReactNode;
  value: string;
  styleOverrides?: any;
  setValue: UseFormSetValue<any>;
  error?: FieldError;
};

// reference https://blog.logrocket.com/using-material-ui-with-react-hook-form/

export function FormCheckbox({
  name,
  control,
  size = "small",
  label,
  value,
  setValue,
  styleOverrides = {},
  error,
}: FormCheckboxProps) {
  const [selectedValue, setSelectedValue] = useState<string>("");

  useEffect(() => {
    setValue(name, selectedValue);
  }, [name, selectedValue, setValue]);

  return (
    <FormControl size={size} variant={"outlined"} sx={styleOverrides}>
      <div>
        <FormControlLabel
          sx={!!error ? { color: "error.main" } : undefined}
          control={
            <Controller
              name={name}
              render={() => {
                return (
                  <Checkbox
                    value={selectedValue}
                    sx={!!error ? { borderColor: "red" } : undefined}
                    checked={selectedValue === "true"}
                    onChange={() => setSelectedValue("true")}
                  />
                );
              }}
              control={control}
            />
          }
          label={label}
          key={value}
        />
      </div>
      <FormHelperText
        error={!!error}
        sx={{
          "&:first-letter": { textTransform: "capitalize" },
        }}
      >
        {error?.message ?? " "}
      </FormHelperText>
    </FormControl>
  );
}
