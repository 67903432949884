export enum LoggedOutRoutesEnum {
  LOGGED_OUT_PREFIX = "/user",
  LOGIN_PAGE = "/user/login",
  PASSWORD_LOGIN_PAGE = "/user/login-pw",
  PASSWORDLESS_VERIFY = "/user/verification",
  SIGNUP_PAGE = "/user/signup",
  FORGOT_PASS_PAGE = "/user/forgot-password",
  TERMS_PAGE = "/terms",
}

export enum LoggedInRoutesEnum {
  LOGGED_IN_PREFIX = "/app",
  DASHBOARD_PAGE = "/app/dashboard",
  CONVERSATIONS_PAGE = "/app/conversations",
  CREATE_PAGE = "/app/create",
  EDIT_PAGE = "/app/edit/:widget_uuid",
  CUSTOMERS_PAGE = "/app/customers",
  LIBRARY_PAGE = "/app/library",
  WIDGET_VIEW_PAGE = "/app/widget/:widget_uuid",
  WIDGET_VIEW_PREFIX = "/app/widget",
  EDIT_WIDGET_PREFIX = "/app/edit",
  CHAT_VIEW_PAGE = "/app/conversation/:conversation_uid",
  CHAT_VIEW_PREFIX = "/app/conversation",
  WIDGET_SEARCH_PAGE = "/app/search/configure",
  SETTINGS_PAGE = "/app/settings",
  IMAGES_PAGE = "/app/images",
  TRAINING_FILES_PAGE = "/app/training-files",
}
