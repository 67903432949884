// vendor
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

// internal
import { useNotifications } from "../../providers";
import { FormTextInput, FormCheckbox, Logo } from "../../components";
import { PASSWORDLESS_SIGNUP_SCHEMA } from "../../utils";
import { createPasswordlessUser } from "../../handlers";
import { LoggedOutRoutesEnum } from "../../constants";
import { TermsOfUseLabel } from "../Shared/TermsOfUseLabel";

interface IFormInput {
  company: string;
  first_name: string;
  last_name: string;
  email: string;
  terms: "true";
}

export function PasswordlessSignUp() {
  const navigate = useNavigate();
  const { showNotificaton } = useNotifications();
  const [viewState, setViewState] = useState<{
    showError: boolean;
    inProgress: boolean;
  }>({
    showError: false,
    inProgress: false,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(PASSWORDLESS_SIGNUP_SCHEMA),
  });

  async function handleSignUp(data: IFormInput) {
    setViewState({
      showError: false,
      inProgress: true,
    });
    return createPasswordlessUser({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email.toLowerCase(),
      company: data.company,
    })
      .then(() => {
        setViewState({
          showError: false,
          inProgress: false,
        });
        navigate(`${LoggedOutRoutesEnum.LOGIN_PAGE}?after_signup=true`);
      })
      .catch(() => {
        setViewState({
          showError: true,
          inProgress: false,
        });
        showNotificaton("Something went wrong!", 4000);
      });
  }

  return (
    <>
      <Logo parentStyles={{ marginBottom: "4rem" }} />
      {viewState.showError && (
        <Alert severity="error" sx={{ marginBottom: "12px" }}>
          There was an error signing up. Please try again.
        </Alert>
      )}
      <FormTextInput
        name="company"
        control={control}
        label="Company Name"
        size="small"
      />
      <FormTextInput
        name="first_name"
        control={control}
        label="First Name"
        size="small"
      />
      <FormTextInput
        name="last_name"
        control={control}
        label="Last Name"
        size="small"
      />
      <FormTextInput
        name="email"
        control={control}
        label="Email"
        size="small"
      />
      <FormCheckbox
        label={<TermsOfUseLabel />}
        value="true"
        size="small"
        control={control}
        name="terms"
        setValue={setValue}
        error={errors.terms}
      />
      {viewState.inProgress ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            variant="contained"
            onClick={handleSubmit(handleSignUp)}
            fullWidth
            sx={{ mb: 2 }}
          >
            SignUp
          </Button>
          <Button
            variant="text"
            onClick={() => navigate(LoggedOutRoutesEnum.LOGIN_PAGE)}
          >
            Login
          </Button>
        </>
      )}
    </>
  );
}
