// vendor
import * as React from "react";

// material ui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// types
import type { Image } from "../../types";

interface TabPanelProps {
  index: number;
  value: number;
  image: Image;
  onClick?: () => void;
}

function CustomTabPanel(props: TabPanelProps) {
  const { image, value, index, onClick = () => {} } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      onClick={onClick}
    >
      {value === index && (
        <img
          style={{ width: "100%", borderRadius: "5px" }}
          src={image.image_url}
          alt={image.file_name}
          loading="lazy"
        />
      )}
    </div>
  );
}

interface CarouselProps {
  images: Image[];
  width: string;
  onClick?: (idx: number) => void;
  activeImg: number;
}

export default function Carousel({
  images = [],
  width,
  activeImg,
  onClick = () => {},
}: CarouselProps) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (activeImg) {
      setValue(activeImg);
    }
  }, []);

  return (
    <Box sx={{ width: width }}>
      {images &&
        images.map((image, i) => {
          return (
            <CustomTabPanel
              image={image}
              value={value}
              index={i}
              key={image.file_name}
              onClick={() => onClick(i)}
            />
          );
        })}
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {images.length > 1 &&
            images.map((image, i) => {
              return (
                <Tab
                  sx={{
                    maxHeight: "45px",
                    padding: "4px",
                    borderRadius: "5px",
                  }}
                  key={image.file_name}
                  id={`simple-tab-${i}`}
                  aria-controls={`simple-tabpanel-${i}`}
                  icon={
                    <img
                      style={{ width: "100px", borderRadius: "5px" }}
                      src={image.image_url}
                      alt={image.file_name}
                      loading="lazy"
                    />
                  }
                />
              );
            })}
        </Tabs>
      </Box>
    </Box>
  );
}
