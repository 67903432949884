import { useState, useEffect } from "react";

// internal
import { getAllWidgetShareLinks, createWidgetShareLink } from "../../handlers";

// types
import { Share, SearchShareResponse, SharePlatform } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  linksData: SearchShareResponse | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  linksData: SearchShareResponse | null;
  createShare: (
    platform: SharePlatform,
    widget_id: number,
    image_id: number,
  ) => Promise<Share | void>;
};

export function useWidgetShareLinks(
  widget_id: number,
  image_id: number,
): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    linksData: null,
  });

  const getAllSearchPageLinks = async (
    parentWidgetID: number,
    parentImageID: number,
  ) => {
    setRequestState({
      linksData: null,
      inProgress: true,
      error: false,
    });
    return getAllWidgetShareLinks(parentWidgetID, parentImageID)
      .then((resp) => {
        setRequestState({
          linksData: resp,
          inProgress: false,
          error: false,
        });
      })
      .catch(() => {
        setRequestState({
          linksData: null,
          inProgress: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    getAllSearchPageLinks(widget_id, image_id);
  }, [widget_id, image_id]);

  const createShare = (
    platform: SharePlatform,
    widget_id: number,
    image_id: number,
  ) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });

    return createWidgetShareLink(platform, widget_id, image_id)
      .then((resp) => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
          };
        });
        getAllSearchPageLinks(widget_id, image_id);
        return resp;
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    linksData: requestState.linksData,
    createShare,
  };
}
