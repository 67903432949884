export const NAV_DRAWER_WIDTH = 240;

export enum NavBarEnum {
  DASHBOARD = "Dashboard",
  CREATE = "Create Widget",
  LIBRARY = "Library",
  CONVERSATIONS = "Conversations",
  CUSTOMERS = "Customers",
  SEARCH = "Share Collection",
  IMAGES = "Images",
  TRAINING_FILES = "Product Data",
  SETTINGS = "Settings",
}
