// vendor
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

// internal
import {
  getAppUserSelector,
  firebaseUserAtom,
  globalLoggedInAtom,
} from "../../recoil";
import { getUserByUid, updateUserHandler } from "../../handlers";

// types
import type { WidgetUser, UpdateUserPayload } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  appUser: WidgetUser | null;
  fetchUser: () => Promise<void>;
  updateUser: (payload: Partial<UpdateUserPayload>) => Promise<void>;
};

export function useAppUser(): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
  });
  const [appUser, setUser] = useRecoilState(getAppUserSelector);
  const isLoggedIn = useRecoilValue(globalLoggedInAtom);
  const firebaseUser = useRecoilValue(firebaseUserAtom);

  const fetchUser = async () => {
    if (!!isLoggedIn && firebaseUser?.uid) {
      setRequestState({
        inProgress: true,
        error: false,
      });
      return getUserByUid(firebaseUser.uid)
        .then((response) => {
          setUser(response);
          setRequestState({
            inProgress: false,
            error: false,
          });
        })
        .catch(() => {
          setRequestState({
            inProgress: false,
            error: true,
          });
        });
    }
  };

  const updateUser = async (payload: Partial<UpdateUserPayload>) => {
    if (!!isLoggedIn && firebaseUser?.uid && appUser?.id) {
      setRequestState({
        inProgress: true,
        error: false,
      });
      return updateUserHandler(appUser.id, payload)
        .then(() => {
          setRequestState({
            inProgress: false,
            error: false,
          });
          return fetchUser();
        })
        .catch(() => {
          setRequestState({
            inProgress: false,
            error: true,
          });
        });
    }
  };

  useEffect(() => {
    if (!appUser) {
      fetchUser();
    }
  }, []);

  return {
    appUser,
    inProgress: requestState.inProgress,
    error: requestState.error,
    fetchUser,
    updateUser,
  };
}
