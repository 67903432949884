// vendor
import {
  useEffect,
  useCallback,
  createContext,
  useContext,
  PropsWithChildren,
} from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useSetRecoilState } from "recoil";
import { cloneDeep as _cloneDeep } from "lodash";

// internal
import { initFirebase, getFBAuth, getFBDb } from "../../services";
import { firebaseUserAtom, globalLoggedInAtom } from "../../recoil";
import { LoggedOutRoutesEnum } from "../../constants";
import { useLocalStorage, LocalStorageItems } from "../../hooks";
import { generateLoginLinkFromEmail } from "../../handlers";

// types
import type { AuthProviderState } from "./types";
import type { UserState } from "../../types";

const APP_HOST = process.env.REACT_APP_API_HOST ?? "";

// Reference: https://github.com/firebase/quickstart-js/blob/master/auth/email-password.ts

const defaultState = {
  signin: () => Promise.resolve(),
  signout: () => Promise.resolve(),
  signup: () => Promise.resolve(null),
  resetPassword: () => Promise.resolve(),
  generateLoginLink: () => Promise.resolve(),
  verifyPasswordlessLink: (location: string) => Promise.resolve(false),
  signInWithPasswordLessLink: (email: string, location: string) =>
    Promise.resolve(),
};

export const AuthContext = createContext<AuthProviderState>(defaultState);

export const useAuth = () => {
  return useContext(AuthContext);
};

export function useFirebaseAuth() {
  const setFirebaseUser = useSetRecoilState(firebaseUserAtom);
  const setGlobalLoggedIn = useSetRecoilState(globalLoggedInAtom);
  const { setLocalStorageItem, removeLocalStorageItem } = useLocalStorage();

  const signout = useCallback(() => {
    const auth = getFBAuth();

    return signOut(auth)
      .then(() => {
        setFirebaseUser(null);
        setGlobalLoggedIn(false);
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
  }, [setFirebaseUser, setGlobalLoggedIn]);

  const handleUser = useCallback(
    async (userArgs: UserState) => {
      const auth = getFBAuth();
      // here userArgs should be type UserImpl
      if (userArgs) {
        const existingUser = await auth?.currentUser?.getIdTokenResult();
        if (!!existingUser && !existingUser?.claims.corporate) {
          signout();
        } else {
          setFirebaseUser(_cloneDeep(userArgs));
          setGlobalLoggedIn(true);
        }
      } else {
        setFirebaseUser(null);
        setGlobalLoggedIn(false);
      }
    },
    [setFirebaseUser, setGlobalLoggedIn, signout],
  );

  useEffect(() => {
    initFirebase();

    const auth = getFBAuth();
    const unsubscribe = onAuthStateChanged(auth, handleUser);
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordLastLogin = async (uid: string, email: string) => {
    const db = getFBDb();
    const loginCollectionRef = collection(db, "logins");
    await addDoc(loginCollectionRef, {
      user_uid: uid,
      email,
      last_login: serverTimestamp(),
    });
  };

  const signin = async (email: string, password: string) => {
    const auth = getFBAuth();
    const existingUser = await auth?.currentUser?.getIdTokenResult();

    if (!!existingUser && !existingUser?.claims?.corporate) {
      signout();
    }

    return setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .then((userCredential) => {
        // on signin userArgs are type UserCredentialImpl
        setFirebaseUser(_cloneDeep(userCredential.user));
        setGlobalLoggedIn(true);
        return recordLastLogin(userCredential.user.uid, email);
      })
      .then(() => {
        // force refresh of token with custom claims
        return auth?.currentUser?.getIdToken(true);
      })
      .catch(function (error) {
        // Handle Errors here and log error report
        console.error(error);
        throw error;
      });
  };

  const signup = (email: string, password: string) => {
    const auth = getFBAuth();

    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // on signup userArgs are type UserCredentialImpl
        setFirebaseUser(_cloneDeep(userCredential.user));
        return userCredential.user;
      })
      .catch(function (error) {
        // Handle Errors here and log error report
        console.log(error);
        throw error;
      });
  };

  const generateLoginLink = async (email: string) => {
    return generateLoginLinkFromEmail(email)
      .then(() => {
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        setLocalStorageItem(LocalStorageItems.SIGNIN_EMAIL, email);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  const verifyPasswordlessLink = async (location: string) => {
    const auth = getFBAuth();
    return isSignInWithEmailLink(auth, location);
  };

  const signInWithPasswordLessLink = async (
    email: string,
    location: string,
  ) => {
    const auth = getFBAuth();

    const existingUser = await auth?.currentUser?.getIdTokenResult();

    if (!!existingUser && !existingUser?.claims?.corporate) {
      signout();
    }

    return setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailLink(auth, email, location);
      })
      .then((userCredential) => {
        // Clear email from storage.
        removeLocalStorageItem(LocalStorageItems.SIGNIN_EMAIL);
        setFirebaseUser(_cloneDeep(userCredential.user));
        setGlobalLoggedIn(true);
        return recordLastLogin(userCredential.user.uid, email);
      })
      .then(() => {
        // force refresh of token with custom claims
        return auth?.currentUser?.getIdToken(true);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  const resetPassword = (email: string) => {
    const auth = getFBAuth();

    return sendPasswordResetEmail(auth, email, {
      url: `${APP_HOST}${LoggedOutRoutesEnum.LOGIN_PAGE}`,
    })
      .then(() => {
        setFirebaseUser(null);
      })
      .catch(function (error) {
        // Handle Errors here and log error report
        console.log(error);
        throw error;
      });
  };

  return {
    signin,
    signout,
    signup,
    resetPassword,
    generateLoginLink,
    verifyPasswordlessLink,
    signInWithPasswordLessLink,
  };
}

export function AuthProvider({ children }: PropsWithChildren) {
  const auth = useFirebaseAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
