import { useState, useEffect } from "react";

// internal
import { getAllOrgShareLinks, createOrgShareLink } from "../../handlers";

// types
import { Share, SearchShareResponse, SharePlatform } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  linksData: SearchShareResponse | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  linksData: SearchShareResponse | null;
  createShare: (platform: SharePlatform) => Promise<Share | void>;
};

export function useSearchPageLinks(): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    linksData: null,
  });

  const getAllSearchPageLinks = async () => {
    setRequestState({
      linksData: null,
      inProgress: true,
      error: false,
    });
    return getAllOrgShareLinks()
      .then((resp) => {
        setRequestState({
          linksData: resp,
          inProgress: false,
          error: false,
        });
      })
      .catch(() => {
        setRequestState({
          linksData: null,
          inProgress: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    getAllSearchPageLinks();
  }, []);

  const createShare = (platform: SharePlatform) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });

    return createOrgShareLink(platform)
      .then((resp) => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
          };
        });
        getAllSearchPageLinks();
        return resp;
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    linksData: requestState.linksData,
    createShare,
  };
}
