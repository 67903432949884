import { useState, useEffect } from "react";

// internal
import { useCustomer } from "../useCustomer";
import { getAllCustomerNotes, postCustomerNote } from "../../handlers";

// types
import type { CustomerNotesResponse } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  customerNotes: CustomerNotesResponse;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  customerNotes: CustomerNotesResponse;
  postNote: (text: string) => Promise<void>;
  getNotes: (
    customerUUID: string,
    page: number,
    limit: number,
  ) => Promise<void>;
};

export function useCustomerNotes(
  customerUUID: string,
  page: number,
  limit: number,
): HookState {
  const { customer } = useCustomer(customerUUID);
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    customerNotes: { notes: [], total: 0 },
  });

  const getNotes = async (
    parentCustomerUUID: string,
    parentPage: number,
    parentLimit: number,
  ) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return getAllCustomerNotes(parentCustomerUUID, parentPage, parentLimit)
      .then((resp) => {
        const sortedNotes = resp.notes.sort(
          (a, b) => Date.parse(a.created_date) - Date.parse(b.created_date),
        );
        setRequestState({
          inProgress: false,
          error: false,
          customerNotes: { notes: sortedNotes, total: resp.total },
        });
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  const postNote = async (text: string) => {
    if (customer && customer?.id) {
      setRequestState((oldState) => {
        return {
          ...oldState,
          inProgress: true,
          error: false,
        };
      });
      return postCustomerNote({ customer_id: customer.id, text })
        .then(() => {
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: false,
            };
          });
          return getNotes(customerUUID, page, limit);
        })
        .catch(() => {
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: true,
            };
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (customerUUID) {
      getNotes(customerUUID, page, limit);
    }
  }, [customerUUID, page, limit]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    customerNotes: requestState.customerNotes,
    postNote,
    getNotes,
  };
}
