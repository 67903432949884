// vendor
import { Outlet } from "react-router-dom";

// material ui
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// internal
import { useNotifications } from "../../providers";

const contentBoxStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 2rem",
  "@media (min-width: 600px)": {
    padding: "4rem 8rem",
    justifyContent: "flex-start",
  },
};

const contentWrapperStyles = {
  height: "100%",
  minHeight: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "@media (min-width: 600px)": {
    justifyContent: "center",
  },
};

export function AuthLayout() {
  const { notification, handleClose } = useNotifications();
  return (
    <Container maxWidth="md" sx={contentWrapperStyles} disableGutters>
      <Box sx={contentBoxStyles}>
        <Outlet />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={notification.open}
        autoHideDuration={notification.timeout}
        onClose={handleClose}
        message={notification.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
}
