// vendor
import { useNavigate } from "react-router-dom";

// material ui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

// internal
import { useUserStats } from "../../providers";
import { LoggedInRoutesEnum } from "../../constants";

export function NotificationsCard() {
  const { userStats } = useUserStats();
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        title="Notifications"
        subheader="Items that need immediate attention"
      />
      <List sx={{ borderTop: "1px solid lightgray" }}>
        {userStats.conversations_requiring_support < 1 &&
          userStats.unread_conversations < 1 && (
            <ListItem>
              <ListItemText>None at this time</ListItemText>
            </ListItem>
          )}
        {userStats.conversations_requiring_support > 0 && (
          <ListItem
            secondaryAction={
              <IconButton
                onClick={() => navigate(LoggedInRoutesEnum.CONVERSATIONS_PAGE)}
              >
                <ArrowForwardIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <NotificationImportantIcon sx={{ color: "#d32f2f" }} />
            </ListItemIcon>
            <ListItemText>Support Requested</ListItemText>
          </ListItem>
        )}
        {userStats.unread_conversations > 0 && (
          <ListItem
            secondaryAction={
              <IconButton
                onClick={() => navigate(LoggedInRoutesEnum.CONVERSATIONS_PAGE)}
              >
                <ArrowForwardIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <NotificationImportantIcon sx={{ color: "#d32f2f" }} />
            </ListItemIcon>
            <ListItemText>Unread Messages</ListItemText>
          </ListItem>
        )}
      </List>
    </Card>
  );
}
