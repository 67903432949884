// vendor
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export function ProgressContainer() {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center,",
      }}
    >
      <CircularProgress color="primary" sx={{ height: "150px" }} />
    </Box>
  );
}
