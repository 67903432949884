// vendor
import axios from "axios";

// internal
import { getHeaders, getFormDataHeaders, API_BASE } from "../shared";

// types
import type { ImageSearchResponse, ImageStats } from "../../types";

export const uploadImageFromUser = async (file: File): Promise<any> => {
  let result = null;
  try {
    const formData = new FormData();
    formData.append("file", file);

    const headers = await getFormDataHeaders();
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/images/upload`,
      headers,
      data: formData,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to upload image", e);
    throw e;
  }
  return result;
};

export const uploadLogoFromUser = async (file: File): Promise<any> => {
  let result = null;
  try {
    const formData = new FormData();
    formData.append("file", file);

    const headers = await getFormDataHeaders();
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/images/logo`,
      headers,
      data: formData,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to upload logo", e);
    throw e;
  }
  return result;
};

export const getImagesFromList = async (
  list: number[],
): Promise<ImageSearchResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/images/list`,
      headers,
      data: { image_id_list: list },
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch image list", e);
    throw e;
  }
  return result;
};

export const getAllOrgImages = async (
  page: number,
  limit: number,
  already_in_use: boolean,
): Promise<ImageSearchResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/images/all?page=${page}&limit=${limit}&already_in_use=${already_in_use}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch images", e);
    throw e;
  }
  return result;
};

export const getAllImageStats = async (): Promise<ImageStats> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/images/stats`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch stats", e);
    throw e;
  }
  return result;
};
