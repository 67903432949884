import { useState } from "react";
import { truncate as _truncate, debounce as _debounce } from "lodash";

// internal
import { getAllUserWidgets, GetAllWidgetsFilters } from "../../handlers";
import { useDeepCompareEffect } from "../../hooks";

// types
import type { WidgetSearchResponse } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  widgetListData: WidgetSearchResponse;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  widgetListData: WidgetSearchResponse;
};

export function useAllWidgets(filters: GetAllWidgetsFilters): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    widgetListData: { widgets: [], total: 0 },
  });

  const getAllWidgets = async (payload: GetAllWidgetsFilters) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return getAllUserWidgets(payload)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          widgetListData: resp ?? { widgets: [], total: 0 },
        });
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  useDeepCompareEffect(() => {
    getAllWidgets(filters);
  }, [filters]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    widgetListData: requestState.widgetListData,
  };
}
