// vendor
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller } from "react-hook-form";

type FormCurrencyProps = {
  name: string;
  label: string;
  helperText?: string;
  control: any;
  type?: string;
  size?: "small" | "medium";
  styleOverrides?: any;
};

// reference https://blog.logrocket.com/using-material-ui-with-react-hook-form/

export function FormCurrencyInput({
  name,
  control,
  label,
  helperText = " ",
  type,
  size = "small",
  styleOverrides = {},
}: FormCurrencyProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : helperText}
          size={size}
          error={!!error}
          onChange={onChange}
          value={value ?? ""}
          fullWidth
          label={label}
          variant="outlined"
          type={type}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={styleOverrides}
          FormHelperTextProps={{
            sx: { "&:first-letter": { textTransform: "capitalize" } },
          }}
        />
      )}
    />
  );
}
