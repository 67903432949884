// vendor
import { useState, useMemo, useEffect } from "react";

// material ui
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

// internal
import { useAllLocations } from "../../hooks";
import { CreationViewEnum } from "../../constants";

// types
import type { Location } from "../../types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function LocationsSelector({
  onSetPublic,
  onSetLocations,
  existingLocations = [],
  existingIsPublic = "false",
}: {
  onSetPublic: (val: "true" | "false") => void;
  onSetLocations: (val: string[]) => void;
  existingLocations?: string[];
  existingIsPublic?: "true" | "false";
}) {
  const { locationsData } = useAllLocations(0, 100);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [isPublic, setIsPublic] = useState<"true" | "false">("true");

  const locationsMap = useMemo(() => {
    return locationsData.locations.reduce(
      (accum: { [k: string]: Location }, curr: Location) => {
        accum[String(curr.id)] = curr;
        return accum;
      },
      {},
    );
  }, [locationsData.locations]);

  const handleChange = (event: SelectChangeEvent<typeof selectedLocations>) => {
    const {
      target: { value },
    } = event;
    if (Array.isArray(value)) {
      setSelectedLocations(value);
      onSetLocations(value);
    } else {
      setSelectedLocations([value]);
      onSetLocations([value]);
    }
  };

  const handlePublicChange = () => {
    if (isPublic === "true") {
      setIsPublic("false");
      onSetPublic("false");
    } else {
      setIsPublic("true");
      onSetPublic("true");
    }
  };

  useEffect(() => {
    if (existingLocations && existingLocations.length > 0) {
      setSelectedLocations(existingLocations);
    }
  }, [existingLocations]);

  useEffect(() => {
    setIsPublic(existingIsPublic);
  }, [existingIsPublic]);

  return (
    <Grid container spacing={2} sx={{ marginBottom: "24px" }}>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: "100%" }}>
          <FormControlLabel
            control={
              <Checkbox value={isPublic} checked={isPublic === "true"} />
            }
            onChange={handlePublicChange}
            label="Online Search"
          />
          <FormHelperText>
            {CreationViewEnum.ONLINE_CHECK_HELPER_TEXT}
          </FormHelperText>
        </FormControl>
      </Grid>
      {locationsData?.locations?.length > 0 && (
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel shrink id="demo-multiple-checkbox-label">
              Store Locations
            </InputLabel>
            <Select
              notched
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedLocations}
              onChange={handleChange}
              input={<OutlinedInput label="Store Locations" />}
              renderValue={(selected) => {
                return selected
                  .map((id) => locationsMap[String(id)]?.name)
                  .join(", ");
              }}
              MenuProps={MenuProps}
            >
              {locationsData.locations.map((loc) => (
                <MenuItem key={loc.id} value={String(loc.id)}>
                  <Checkbox
                    checked={selectedLocations.indexOf(String(loc.id)) > -1}
                  />
                  <ListItemText primary={loc.name} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {CreationViewEnum.LOCATIONS_SELECT_HELPER_TEXT}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
}
