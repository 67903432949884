// vendor
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en";

type FormDateProps = {
  name: string;
  label: string;
  helperText?: string;
  control: any;
  size?: "small" | "medium";
  styleOverrides?: any;
};

// reference https://blog.logrocket.com/using-material-ui-with-react-hook-form/

export function FormDateInput({
  name,
  control,
  label,
  helperText = " ",
  size = "small",
  styleOverrides = {},
}: FormDateProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DatePicker
            value={dayjs(value)}
            onChange={onChange}
            label={label}
            sx={styleOverrides}
            openTo="day"
            views={["year", "month", "day"]}
            slotProps={{
              textField: {
                helperText: error ? error.message : helperText,
                InputLabelProps: { shrink: true },
                size,
                variant: "outlined",
                fullWidth: true,
                error: !!error,
              },
              field: { clearable: true },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
