// vendor
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

// material ui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ListItemIcon from "@mui/material/ListItemIcon";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";

// internal
import {
  useWalkThrough,
  useUserStats,
  WalkThroughContentEnum,
} from "../../providers";
import { LoggedInRoutesEnum } from "../../constants";
import { useLocalStorage, LocalStorageItems } from "../../hooks";

export function SetupGuide() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { startCreationWalkthrough, startSharingWalkthrough } =
    useWalkThrough();
  const { userStats } = useUserStats();
  const { getLocalStorageItem } = useLocalStorage();
  const [displayNoWidgetsMessage, setDisplayNoWidgetsMessage] =
    useState<boolean>(false);

  const createWalkThroughStatus = useMemo(
    () => getLocalStorageItem(LocalStorageItems.CREATION_WALKTHROUGH_COMPLETED),
    [],
  );
  const sharingWalkThroughStatus = useMemo(
    () => getLocalStorageItem(LocalStorageItems.SHARING_WALKTHROUGH_COMPLETED),
    [],
  );

  function handleCreateButtonClick() {
    startCreationWalkthrough();
    navigate(LoggedInRoutesEnum.CREATE_PAGE);
  }
  function handleGoCreateYourButtonClick() {
    navigate(LoggedInRoutesEnum.CREATE_PAGE);
  }

  function handleShareButtonClick() {
    if (userStats.org_total_widgets > 0) {
      startSharingWalkthrough();
      navigate(LoggedInRoutesEnum.LIBRARY_PAGE);
    } else {
      setDisplayNoWidgetsMessage(true);
    }
  }

  const handleOnClose = () => setDisplayNoWidgetsMessage(false);

  return (
    <>
      <Card>
        <CardHeader
          title={WalkThroughContentEnum.WALKTHROUGH_TITLE}
          subheader={WalkThroughContentEnum.WALKTHROUGH_SUBHEADING}
        />
        <List sx={{ borderTop: "1px solid lightgray" }}>
          <ListItem
            secondaryAction={
              createWalkThroughStatus === "complete" ||
              userStats.widgets_created > 0 ? null : (
                <IconButton onClick={handleCreateButtonClick}>
                  <ArrowForwardIcon />
                </IconButton>
              )
            }
          >
            <ListItemIcon>
              {createWalkThroughStatus === "complete" ||
              userStats.widgets_created > 0 ? (
                <CheckCircleIcon sx={{ color: theme.palette.primary.main }} />
              ) : (
                <RemoveCircleIcon />
              )}
            </ListItemIcon>
            <ListItemText
              id="checkbox-list-label-0"
              primary={WalkThroughContentEnum.WALK_THROUGH_CREATE}
            />
          </ListItem>
          <ListItem
            secondaryAction={
              userStats.widgets_created > 0 ? null : (
                <IconButton onClick={handleGoCreateYourButtonClick}>
                  <ArrowForwardIcon />
                </IconButton>
              )
            }
          >
            <ListItemIcon>
              {userStats.widgets_created > 0 ? (
                <CheckCircleIcon sx={{ color: theme.palette.primary.main }} />
              ) : (
                <RemoveCircleIcon />
              )}
            </ListItemIcon>
            <ListItemText
              id="checkbox-list-label-1"
              primary={WalkThroughContentEnum.WALK_THROUGH_CREATE_ONE}
            />
          </ListItem>
          <ListItem
            secondaryAction={
              sharingWalkThroughStatus === "complete" ? null : (
                <IconButton onClick={handleShareButtonClick}>
                  <ArrowForwardIcon />
                </IconButton>
              )
            }
          >
            <ListItemIcon>
              {sharingWalkThroughStatus === "complete" ? (
                <CheckCircleIcon sx={{ color: theme.palette.primary.main }} />
              ) : (
                <RemoveCircleIcon />
              )}
            </ListItemIcon>
            <ListItemText
              id="checkbox-list-label-1"
              primary={WalkThroughContentEnum.WALK_THROUGH_SHARE}
            />
          </ListItem>
          <ListItem
            secondaryAction={
              sharingWalkThroughStatus === "complete" ||
              userStats.org_total_conversations > 0 ? null : (
                <IconButton
                  onClick={() => navigate(LoggedInRoutesEnum.LIBRARY_PAGE)}
                >
                  <ArrowForwardIcon />
                </IconButton>
              )
            }
          >
            <ListItemIcon>
              {userStats.org_total_conversations > 0 ? (
                <CheckCircleIcon sx={{ color: theme.palette.primary.main }} />
              ) : (
                <RemoveCircleIcon />
              )}
            </ListItemIcon>
            <ListItemText
              id="checkbox-list-label-1"
              primary={WalkThroughContentEnum.WALK_THROUGH_SHARE_ONE}
            />
          </ListItem>
        </List>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={displayNoWidgetsMessage}
        autoHideDuration={3000}
        onClose={handleOnClose}
        message={"You need Widgets before you can do this part"}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleOnClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
