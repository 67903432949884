// vendor
import { useState, useEffect, useCallback } from "react";

// material ui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

// internal
import { ImageUpload, Thumbnail } from "../../components";
import { CreationViewEnum } from "../../constants";
import { useImageService } from "../../hooks";

export function ImageUploadRow({
  onSetImagesFromParent,
  existingImages = [],
  errorFromParentForm,
  onClearError = () => {},
}: {
  onSetImagesFromParent: (valList: number[]) => void;
  existingImages?: number[];
  errorFromParentForm?: string;
  onClearError?: () => void;
}) {
  const [uploadedImages, setUploadedImages] =
    useState<number[]>(existingImages);
  const { inProgress, postImage, imageListData, errorMessage } =
    useImageService(uploadedImages);

  async function onSelectImage(file: File) {
    const response = await postImage(file);
    if (response?.id) {
      setUploadedImages((existing) => {
        return [...existing, response.id];
      });
      onClearError();
    }
  }

  const setParentImages = useCallback(() => {
    onSetImagesFromParent(uploadedImages);
  }, [uploadedImages, onSetImagesFromParent]);

  useEffect(() => {
    setParentImages();
  }, [setParentImages]);

  const removeImage = (removeId: number) => {
    setUploadedImages((existing) => existing.filter((id) => id !== removeId));
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ marginBottom: "24px" }}
    >
      <Grid item xs={12} md={6}>
        {!inProgress ? (
          <ImageUpload
            label={CreationViewEnum.IMAGE_UPLOAD_LABEL}
            size="medium"
            errorFromParent={errorMessage || errorFromParentForm}
            onSelectImageFromParent={onSelectImage}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <Grid container item spacing={2} xs={12} md={6}>
        {imageListData.images.map((img) => (
          <Box
            key={img.uuid}
            p={1}
            m={1}
            sx={{ cursor: "pointer", ":hover svg": { display: "flex" } }}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                display: "none",
                position: "absolute",
                transform: "translate(-10px, -10px)",
                backgroundColor: "#d2d2d2",
                borderRadius: "50%",
              }}
              onClick={() => {
                removeImage(img.id);
              }}
            />
            <Thumbnail url={img.image_url} size="medium" />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
