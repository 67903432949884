export enum WalkThroughContentEnum {
  WALKTHROUGH_TITLE = "Setup Guide",
  WALKTHROUGH_SUBHEADING = "Follow the steps in this guide to get started with your account",
  WALK_THROUGH_CREATE = "Learn how to create your first Widget",
  BUTTON_LABEL_CREATE = "Take Walkthrough",
  WALK_THROUGH_SHARE = "Learn how to share the widgets you created",
  WALK_THROUGH_SHARE_ONE = "Share the widgets you created to find new customers",
  BUTTON_LABEL_SHARE = "Take Walkthrough",
  WALK_THROUGH_CREATE_ONE = "Create a new Widget for own products",
  BUTTON_LABEL_CREATE_ONE = "Create Widget",
}

export enum WalkThroughStepsEnum {
  NAV_CREATE_WIDGET_BTN = "nav-create-widget-btn",
  CREATE_WIDGET_ADD_IMAGES = "create-widget-add-images",
  CREATE_WIDGET_ADD_DETAILS = "create-widget-add-details",
  CREATE_WIIDGET_ADD_CATEGORY = "create-widget-add-category",
  CREATE_WIDGET_AI_ENABLED = "create-widget-ai-enabled",
  CREATE_WIDGET_AGE_RESTRICTED = "create-widget-age-restricted",
  CREATE_WIDGET_PRODUCT_OFFER = "create-widget-product-offer",
  CREATE_WIDGET_PRODUCT_OFFER_CODE = "create-widget-product-offer-code",
  CREATE_WIDGET_TRAINING_FILES = "create-widget-training-files",
  CREATE_WIDGET_TRAINING_INPUT_FIELD = "create-widget-training-input-field",
  CREATE_WIDGET_ONLINE_SEARCH = "create-widget-online-search",
  CREATE_WIDGET_CREATE_NOW = "create-widget-create-now",
  WIDGET_LIBRARY = "widget-library",
  WIDGET_LIBRARY_VIEW_BTN = "widget-library-view-btn",
  WIDGET_LIBRARY_DISPLAY_PAGE = "widget-library-display-page",
  WIDGET_LIBRARY_DISPLAY_PAGE_EDIT = "widget-library-display-page-edit",
  WIDGET_LIBRARY_DISPLAY_PAGE_ACTIVE = "widget-library-display-page-active",
  WIDGET_LIBRARY_SHARING_PAGE_TAB = "widget-library-sharing-page-tab",
  WIDGET_LIBRARY_SHARING_IMAGES = "widget-library-sharing-images",
  WIDGET_LIBRARY_ANALYTICS_PAGE_TAB = "widget-library-analytics-page-tab",
}
