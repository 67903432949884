// vendor
import { useEffect, useState, PropsWithChildren } from "react";

// material ui
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// internal
import {
  useWalkThrough,
  WalkThroughStepsEnum,
  WALK_THROUGH_PANEL_AND_TABS,
} from "../../providers";

export { WalkThroughStepsEnum } from "../../providers";

export function WalkThroughPanel({
  onClickNext,
  onClickClose,
}: {
  onClickNext: () => void;
  onClickClose: () => void;
}) {
  const { activeStep, activeWalkthrough } = useWalkThrough();

  return (
    <Box sx={{ width: "100%" }}>
      {activeWalkthrough.map((id) => {
        return (
          <div
            role="tabpanel"
            hidden={activeStep !== id}
            id={`simple-tabpanel-${id}`}
            aria-labelledby={`simple-tab-${id}`}
            key={id}
          >
            {activeStep === id && (
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  textAlign: "right",
                  width: "100%",
                  p: 1,
                }}
              >
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={onClickClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                <Typography align="left" variant="h4">
                  {WALK_THROUGH_PANEL_AND_TABS[id].title}
                </Typography>
                <Typography align="left" variant="body1">
                  {WALK_THROUGH_PANEL_AND_TABS[id].description}
                </Typography>
                <Box>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#ffffff",
                      borderColor: "#ffffff",
                      margin: 1,
                    }}
                    onClick={onClickNext}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            )}
          </div>
        );
      })}
    </Box>
  );
}

type TooltipPlacement = "top" | "left" | "right";

type WalkThroughProps = PropsWithChildren & {
  placement?: TooltipPlacement;
  locationID: WalkThroughStepsEnum;
  mimicAction?: () => void;
};

export function WalkThrough({
  placement,
  locationID,
  mimicAction = () => {},
  children,
}: WalkThroughProps) {
  const [displayWalkThroughStep, setDisplayWalkThroughStep] =
    useState<boolean>(false);

  const { walkThroughCompleted, activeStep, closeWalkthrough, nextStep } =
    useWalkThrough();

  useEffect(() => {
    if (locationID !== activeStep) {
      setDisplayWalkThroughStep(false);
    }

    if (locationID === activeStep) {
      setDisplayWalkThroughStep(true);
    }
  }, [activeStep, locationID]);

  if (walkThroughCompleted || !activeStep) {
    return <>{children}</>;
  }

  function onClickNext() {
    nextStep();
    mimicAction();
  }

  return (
    <Box>
      <Tooltip
        title={
          <WalkThroughPanel
            onClickNext={onClickNext}
            onClickClose={() => closeWalkthrough()}
          />
        }
        arrow
        placement={placement}
        open={displayWalkThroughStep}
        disableHoverListener
      >
        <Box>
          <>{children}</>
        </Box>
      </Tooltip>
    </Box>
  );
}
