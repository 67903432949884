// vendor
import { atom } from "recoil";

// internal
import { Conversation } from "../../types";

export const firebaseActiveConversationsAtom = atom({
  key: "firebaseActiveConversations",
  default: [] as Conversation[],
});

export const firebaseRepConversationsAtom = atom({
  key: "firebaseRepConversations",
  default: [] as Conversation[],
});
