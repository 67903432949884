// vendor
import axios from "axios";

// internal
import { getHeaders, API_BASE } from "../shared";
import { SharePlatform, Share, SearchShareResponse } from "../../types";

export const getAllOrgShareLinks = async (): Promise<SearchShareResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/share/search`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch share links", e);
  }
  return result;
};

export const createOrgShareLink = async (
  platform: SharePlatform,
): Promise<Share> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/share/search/${platform}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to create share link", e);
  }
  return result;
};

export const getAllWidgetShareLinks = async (
  widget_id: number,
  image_id: number,
): Promise<SearchShareResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/share/image?widget_id=${widget_id}&image_id=${image_id}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch widget share links", e);
  }
  return result;
};

export const createWidgetShareLink = async (
  platform: SharePlatform,
  widget_id: number,
  image_id: number,
): Promise<Share> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/share/image`,
      headers,
      data: JSON.stringify({ platform, widget_id, image_id }),
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to create share link", e);
  }
  return result;
};
