// vendor
import { useState, useEffect, useCallback } from "react";

// material ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

// internal
import { FileUpload, FileThumbnail } from "../../components";
import { CreationViewEnum } from "../../constants";
import { useTrainingFileService } from "../../hooks";

export function FileUploadRow({
  onSetFilesFromParent,
  existingFiles = [],
  errorFromParentForm,
  onClearError = () => {},
}: {
  onSetFilesFromParent: (valList: number[]) => void;
  existingFiles?: number[];
  errorFromParentForm?: string;
  onClearError?: () => void;
}) {
  const [uploadedFiles, setUploadedFiles] = useState<number[]>(existingFiles);
  const { inProgress, postFile, fileListData, errorMessage } =
    useTrainingFileService(uploadedFiles);

  async function onSelectFile(file: File) {
    const response = await postFile(file);
    if (response?.id) {
      setUploadedFiles((existing) => {
        return [...existing, response.id];
      });
      onClearError();
    }
  }

  const setParentFiles = useCallback(() => {
    onSetFilesFromParent(uploadedFiles);
  }, [uploadedFiles, onSetFilesFromParent]);

  useEffect(() => {
    setParentFiles();
  }, [setParentFiles]);

  const removeFile = (removeId: number) => {
    setUploadedFiles((existing) => existing.filter((id) => id !== removeId));
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ marginBottom: "24px" }}
    >
      <Grid item xs={12} md={6}>
        {!inProgress ? (
          <FileUpload
            label={CreationViewEnum.FILE_UPLOAD_LABEL}
            size="medium"
            errorFromParent={errorMessage || errorFromParentForm}
            onSelectFileFromParent={onSelectFile}
            helperText={CreationViewEnum.FILE_UPLOAD_HELPER_TEXT}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <Grid container item xs={12} md={6}>
        {fileListData.files.map((file) => (
          <Box
            key={file.uuid}
            p={1}
            m={1}
            sx={{ cursor: "pointer", ":hover svg": { display: "flex" } }}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                display: "none",
                position: "absolute",
                transform: "translate(-10px, -10px)",
                backgroundColor: "#d2d2d2",
                borderRadius: "50%",
              }}
              onClick={() => {
                removeFile(file.id);
              }}
            />
            <FileThumbnail fileName={file.file_name} size="medium" />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
