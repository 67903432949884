// vendor
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

// internal
import { useAuth, useNotifications } from "../../providers";
import { FormTextInput, Logo } from "../../components";
import { PASSWORDLESS_LOGIN_SCHEMA } from "../../utils";
import { LoggedOutRoutesEnum } from "../../constants";

interface IFormInput {
  email: string;
}

export function PasswordlessLogin() {
  const { generateLoginLink } = useAuth();
  const navigate = useNavigate();
  const { showNotificaton } = useNotifications();
  const [searchParams] = useSearchParams();
  const [viewState, setViewState] = useState<{
    selectedAlert: "none" | "error" | "message" | "confirmation";
    inProgress: boolean;
  }>({
    selectedAlert: "none",
    inProgress: false,
  });

  useEffect(() => {
    const afterSignup = searchParams.get("after_signup");
    if (afterSignup === "true") {
      setViewState({
        selectedAlert: "confirmation",
        inProgress: false,
      });
    }
  }, [searchParams]);

  const { control, handleSubmit } = useForm<IFormInput>({
    resolver: yupResolver(PASSWORDLESS_LOGIN_SCHEMA),
  });

  async function handleEmailSubmission(data: IFormInput) {
    setViewState({
      selectedAlert: "none",
      inProgress: true,
    });

    const correctedEmail = data.email.toLowerCase();

    return generateLoginLink(correctedEmail)
      .then(() => {
        setViewState({
          selectedAlert: "message",
          inProgress: false,
        });
      })
      .catch(() => {
        setViewState({
          selectedAlert: "error",
          inProgress: false,
        });
        showNotificaton("Something went wrong!", 4000);
      });
  }

  return (
    <>
      <Logo parentStyles={{ marginBottom: "2rem" }} />
      {viewState.selectedAlert === "error" && (
        <Alert severity="error" sx={{ marginBottom: "1rem" }}>
          {
            "We experienced an error logging in with the email address provided. If you have not created an account yet, please click signup below."
          }
        </Alert>
      )}
      {viewState.selectedAlert === "message" && (
        <Alert severity="success" sx={{ marginBottom: "1rem" }}>
          Check your email inbox for your login link
        </Alert>
      )}
      {viewState.selectedAlert === "confirmation" && (
        <Alert severity="info" sx={{ marginBottom: "1rem" }}>
          Your account has been created! Enter your email below to receive a
          magic login link
        </Alert>
      )}
      <Box sx={{ marginTop: "2rem", width: "100%" }}>
        <FormTextInput
          name="email"
          control={control}
          label="Email"
          size="medium"
        />
      </Box>
      <Box
        sx={{
          mt: -2,
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => navigate(LoggedOutRoutesEnum.PASSWORD_LOGIN_PAGE)}
        >
          Login With Password
        </Button>
      </Box>
      {viewState.inProgress ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            size="large"
            variant="contained"
            onClick={handleSubmit(handleEmailSubmission)}
            sx={{ mt: 2, mb: 2 }}
            fullWidth
          >
            Send Link
          </Button>
          <Button
            variant="text"
            onClick={() => navigate(LoggedOutRoutesEnum.SIGNUP_PAGE)}
          >
            Sign Up
          </Button>
        </>
      )}
    </>
  );
}
