// vendor
import axios from "axios";

// internal
import { getHeaders, API_BASE } from "../shared";

// types
import type {
  WidgetUser,
  CreateUserPayload,
  UpdateUserPayload,
  UserStats,
  CreatePasswordlessUserPayload,
} from "../../types";

export type ApiHeaders = {
  authorization: string;
  "content-type"?: string;
  xid: string;
};

export const getUserByUid = async (
  userUid: string,
): Promise<WidgetUser | null> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/users/uid/${userUid}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch user", e);
    throw e;
  }
  return result;
};

export const createUser = async (
  payload: CreateUserPayload,
): Promise<WidgetUser | null> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/users`,
      headers,
      data: payload,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to upsert user", e);
    throw e;
  }
  return result;
};

export const generateLoginLinkFromEmail = async (
  email: string,
): Promise<void> => {
  try {
    const headers = await getHeaders(true);
    await axios({
      method: "POST",
      url: `${API_BASE}/api/auth/link`,
      headers,
      data: { email },
    });
  } catch (e: unknown) {
    console.error("Failed to generate login link", e);
    throw e;
  }
};

export const createPasswordlessUser = async (
  payload: CreatePasswordlessUserPayload,
): Promise<WidgetUser | null> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/users/create`,
      headers,
      data: payload,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to upsert user", e);
    throw e;
  }
  return result;
};

export const updateUserHandler = async (
  id: number,
  payload: Partial<UpdateUserPayload>,
): Promise<WidgetUser | null> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "PUT",
      url: `${API_BASE}/api/users/${id}`,
      headers,
      data: payload,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to update user", e);
  }
  return result;
};

export const getAllUserStats = async (): Promise<UserStats> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/users/stats`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch stats", e);
    throw e;
  }
  return result;
};
