// material ui
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// types
import { Image } from "../../types";

export type ImageSelectProps = {
  images: Image[];
  value?: number;
  onChangeFromParent: (image: Image) => void;
};

export function ImageSelect({
  images,
  value,
  onChangeFromParent,
}: ImageSelectProps) {
  const handleImageChange = (event: SelectChangeEvent) => {
    const newID = Number(event.target.value); // comes in as a string
    const newState = images.find((img) => img.id === newID);
    if (newState) {
      onChangeFromParent(newState);
    }
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="select-image-label">Select Image</InputLabel>
      <Select
        labelId="select-image-label"
        id="select-image"
        value={value ? String(value) : ""}
        label="Select image"
        onChange={handleImageChange}
      >
        {images.map((img) => (
          <MenuItem key={img.uuid} value={img.id}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: "50px",
                  paddingRight: "18px",
                }}
                src={img.image_url}
                alt={img.file_name}
                loading="lazy"
              />
              <Typography variant="body1" component="p">
                {img.file_name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
