import * as yup from "yup";

export const EMAIL_VALIDATION = yup.string().email().lowercase().required();

const PHONE_VALIDATION = yup
  .string()
  .matches(
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
    {
      message: "Please enter a valid phone number",
    },
  )
  .required();

const PASSWORD_VALIDATION = yup.string().min(8).max(255).required();

const COMPANY_VALIDATION = yup.string().min(1).max(50).required();

const NAME_VALIDATION = yup.string().min(1).max(50).required();

export const NAME_SCHEMA = yup.object().shape({
  name: NAME_VALIDATION,
});

// FORMS
export const EMAIL_INPUT_SCHEMA = yup.object().shape({
  email: EMAIL_VALIDATION,
});

export const PHONE_INPUT_SCHEMA = yup.object().shape({
  phone: PHONE_VALIDATION,
});

export const LOGIN_SCHEMA = yup.object().shape({
  email: EMAIL_VALIDATION,
  password: PASSWORD_VALIDATION,
});

export const PASSWORDLESS_LOGIN_SCHEMA = yup.object().shape({
  email: EMAIL_VALIDATION,
});

export const SIGNUP_SCHEMA = yup.object().shape({
  company: COMPANY_VALIDATION,
  first_name: NAME_VALIDATION,
  last_name: NAME_VALIDATION,
  email: EMAIL_VALIDATION,
  password: PASSWORD_VALIDATION,
  password_confirm: yup
    .string()
    .oneOf(
      [yup.ref("password"), ""],
      "Password confirmation must match password",
    )
    .required("Password confirmation must match password"),
  terms: yup.string().oneOf(["true"], "Must agree to Terms of Use").required(),
});

export const PASSWORDLESS_SIGNUP_SCHEMA = yup.object().shape({
  company: COMPANY_VALIDATION,
  first_name: NAME_VALIDATION,
  last_name: NAME_VALIDATION,
  email: EMAIL_VALIDATION,
  terms: yup.string().oneOf(["true"], "Must agree to Terms of Use").required(),
});

export const FORGOT_PASSWORD_SCHEMA = yup.object().shape({
  email: EMAIL_VALIDATION,
});

export const WIDGET_CREATION_SCHEMA = yup.object().shape({
  is_ai_enabled: yup.string().oneOf(["true", "false"]).required(),
  is_public: yup.string().oneOf(["true", "false"]).required(),
  is_age_restricted: yup.string().oneOf(["true", "false"]).required(),
  is_indexing_enabled: yup.string().oneOf(["true", "false"]).required(),
  title: yup.string().min(2).max(150).required(),
  description: yup.string().min(2).max(500).required(),
  puid: yup
    .string()
    .max(255)
    .transform((curr, orig) =>
      orig === "" || orig === null ? undefined : curr,
    ),
  cta_text: yup
    .string()
    .max(50)
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .min(4),
  cta_url: yup
    .string()
    .url()
    .max(1500)
    .when("cta_text", {
      is: (val: string) => !!val,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    })
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .min(4),
  offer: yup.string().min(2).max(100),
  offer_code: yup
    .string()
    .min(4)
    .max(15)
    .matches(/^[A-Z0-9]*$/, {
      message:
        "Only capital letters and numbers can be used, example: 'OFFER24'",
    }),
  price: yup.string().matches(/^\$?[0-9]+(\.[0-9][0-9])?$/, {
    message: "Price must be in $00.00 format",
  }),
  start_date: yup.string(),
  end_date: yup.string(),
  plain_training_text: yup
    .string()
    .max(3000)
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .min(10),
  widget_category_id: yup.number().integer(),
  locations: yup.array().of(yup.string().required()),
  agent_name: yup
    .string()
    .max(50)
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .min(2),
  greeting: yup
    .string()
    .max(500)
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .min(5),
  files: yup
    .array()
    .of(yup.number().integer().required())
    .when("is_ai_enabled", {
      is: "true",
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.min(0),
    }),
  images: yup.array().min(1).of(yup.number().integer().required()),
});

export const CUSTOMER_UPDATE_SCHEMA = yup.object().shape({
  first_name: yup.string().max(50),
  last_name: yup.string().max(50),
  email: yup.string().email().lowercase(),
  phone: yup.string().max(15),
  street_address: yup.string().max(50),
  city: yup.string().max(50),
  state: yup.string().max(15),
  zip_code: yup.string().max(10),
});

export const ADD_USER_SCHEMA = yup.object().shape({
  first_name: yup.string().max(50).required(),
  last_name: yup.string().max(50).required(),
  email: yup.string().email().lowercase().required(),
});

export const UPDATE_ORG_SCHEMA = yup.object().shape({
  name: yup.string().min(2).max(50).required(),
  branding_hex: yup.string().length(7).required(),
  header_hex: yup.string().length(7).required(),
  message_hex: yup.string().length(7).required(),
  use_custom_branding: yup.string().oneOf(["true", "false"]).required(),
  allow_domain_join: yup.string().oneOf(["true", "false"]).required(),
  logo_image_id: yup.number().integer(),
  email_domain: yup
    .string()
    .max(75)
    .when("allow_domain_join", {
      is: "true",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const ADD_LOCATION_SCHEMA = yup.object().shape({
  name: yup.string().min(2).max(75).required(),
  street_address: yup.string().min(2).max(75).required(),
  street_address_secondary: yup.string().max(75),
  city: yup.string().min(2).max(50).required(),
  state: yup.string().length(2).required(),
  zip_code: yup.string().length(5).required(),
  gmaps_lat: yup.number().required(),
  gmaps_long: yup.number().required(),
  gmaps_place_id: yup.string().min(2).max(50).required(),
});
