// vendor
import { useMemo, useState } from "react";

// material ui
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

// internal
import { useAllImages } from "../../hooks";
import { Thumbnail } from "../../components";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function AlreadyUsedGallery() {
  const [page, setPage] = useState<number>(0);
  const [limit] = useState<number>(50);
  const { imageData, inProgress } = useAllImages(page, limit, true);

  const count = useMemo(
    () => Math.floor(imageData.total / limit),
    [imageData.total, limit],
  );

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  if (inProgress) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Grid container spacing={1}>
        {imageData.images.map((img) => (
          <Grid item xs={6} md={4} lg={3} xl={2} key={img.uuid}>
            <Thumbnail url={img.image_url} alt={img.file_name} size="large" />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "24px",
        }}
      >
        <Pagination
          count={count}
          color="primary"
          onChange={onChangePage}
          page={page}
        />
      </Box>
    </Box>
  );
}

function NotUsedGallery() {
  const [page, setPage] = useState<number>(0);
  const [limit] = useState<number>(50);
  const { imageData, inProgress } = useAllImages(page, limit, false);

  const count = useMemo(
    () => Math.floor(imageData.total / limit),
    [imageData.total, limit],
  );

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  if (inProgress) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Grid container spacing={1}>
        {imageData.images.map((img) => (
          <Grid item xs={6} md={4} lg={3} xl={2} key={img.uuid}>
            <Thumbnail url={img.image_url} alt={img.file_name} size="large" />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "24px",
        }}
      >
        <Pagination
          count={count}
          color="primary"
          onChange={onChangePage}
          page={page}
        />
      </Box>
    </Box>
  );
}

export function ImagesPage() {
  const [activeTab, setActiveTab] = useState<number>(0);
  const isDesktop = useMediaQuery("(min-width:600px)");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: isDesktop ? "75px" : "75px 12px",
        height: "100vh",
        width: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box>
        <Box>
          <Tabs
            value={activeTab}
            variant="fullWidth"
            centered
            onChange={handleTabChange}
            aria-label="images-tabs"
            orientation="horizontal"
            sx={{
              width: "100%",
            }}
          >
            <Tab label="Already In Use" {...a11yProps(0)} />
            <Tab label="Not Used" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box sx={{ paddingTop: "24px" }}>
          <TabPanel value={activeTab} index={0}>
            <AlreadyUsedGallery />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <NotUsedGallery />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
}
