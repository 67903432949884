// vendor
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// material ui
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

// internal
import { LoggedInRoutesEnum } from "../../constants";
import { ActiveChatsTable } from "./ActiveChatsTable";
import { ChatsForRepsTable } from "./ChatsForRepsTable";
import { useUserStats } from "../../providers/UserStatsProvider";

export function Conversations() {
  const navigate = useNavigate();
  const { refetchStats } = useUserStats();

  function handleOnClickView(conversation_uid: string) {
    return navigate(
      `${LoggedInRoutesEnum.CHAT_VIEW_PREFIX}/${conversation_uid}`,
    );
  }

  useEffect(() => {
    refetchStats();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: "75px" }}>
        <ChatsForRepsTable handleOnClickView={handleOnClickView} />
        <ActiveChatsTable handleOnClickView={handleOnClickView} />
      </Box>
    </Container>
  );
}
