import { useState, useEffect } from "react";

// internal
import { getCustomerAnalyticsData } from "../../handlers";

// types
import type { CustomerAnalyticsSearchResponse } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  analyticsResponse: CustomerAnalyticsSearchResponse | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  analyticsResponse: CustomerAnalyticsSearchResponse | null;
};

export function useCustomerAnalytics(customer_uuid: string): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    analyticsResponse: null,
  });

  const getCustomerAnalytics = async (parentCustomerUUID: string) => {
    setRequestState({
      analyticsResponse: null,
      inProgress: true,
      error: false,
    });
    return getCustomerAnalyticsData(parentCustomerUUID)
      .then((resp) => {
        setRequestState({
          analyticsResponse: resp,
          inProgress: false,
          error: false,
        });
      })
      .catch(() => {
        setRequestState({
          analyticsResponse: null,
          inProgress: false,
          error: true,
        });
      });
  };

  useEffect(() => {
    if (customer_uuid) {
      getCustomerAnalytics(customer_uuid);
    }
  }, [customer_uuid]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    analyticsResponse: requestState.analyticsResponse,
  };
}
