// vendor
import { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

// internal
import { useAllLocations } from "../../hooks";
import type { Location, CreateLocationPayload } from "../../types";
import { FormTextInput } from "../../components";
import { ADD_LOCATION_SCHEMA, formatTimestampToLocalAlt } from "../../utils";
import { GoogleMapsAutoComplete, PlaceType } from "./AutoComplete";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GMAPS_KEY ?? "";

type GeocodeResultMap = {
  street_number: string;
  route: string;
  neighborhood: string;
  locality: string;
  administrative_area_level_2: string;
  administrative_area_level_1: string;
  country: string;
  postal_code: string;
  postal_code_suffix: string;
};

const parseGeocodeResponse = (
  resultComponents: {
    long_name: string;
    short_name: string;
    types: string[];
  }[],
): GeocodeResultMap => {
  return resultComponents.reduce((accum: any, curr: any) => {
    const key = curr?.types[0];
    if (key === "administrative_area_level_1") {
      accum[key] = curr.short_name;
    } else {
      accum[key] = curr.long_name;
    }
    return accum;
  }, {} as GeocodeResultMap);
};

export function AddLocationForm({
  onAddLocation,
  inProgress,
}: {
  onAddLocation: (data: CreateLocationPayload) => void;
  inProgress: boolean;
}) {
  const { control, handleSubmit, setValue } = useForm<CreateLocationPayload>({
    resolver: yupResolver(ADD_LOCATION_SCHEMA),
  });

  async function handleUpdate(data: CreateLocationPayload) {
    await onAddLocation(data);
  }

  function handleSearchChange(value: PlaceType) {
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${value.place_id}&key=${GOOGLE_MAPS_API_KEY}`,
    )
      .then((res) => res.json())
      .then((data) => {
        const resultMap = parseGeocodeResponse(
          data.results[0].address_components,
        );
        setValue("gmaps_place_id", data.results[0].place_id);
        setValue("gmaps_lat", data.results[0].geometry.location.lat);
        setValue("gmaps_long", data.results[0].geometry.location.lng);
        setValue(
          "street_address",
          resultMap.street_number + " " + resultMap.route,
        );
        setValue("city", resultMap.locality);
        setValue("state", resultMap.administrative_area_level_1);
        setValue("zip_code", resultMap.postal_code);
      })
      .catch((err) => {
        console.error("Unkown Maps error occured", err);
      });
  }

  return (
    <Box sx={{ marginBottom: "24px" }}>
      <Typography variant="h6" component="h6" sx={{ marginBottom: "24px" }}>
        {"Add a Location"}
      </Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ marginBottom: "24px" }}>
              <GoogleMapsAutoComplete onChangeFromParent={handleSearchChange} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              name="name"
              control={control}
              label="Name"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              name="street_address"
              control={control}
              label="Street Address"
              size="small"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextInput
              name="street_address_secondary"
              control={control}
              label="Street Address (secondary)"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextInput
              name="city"
              control={control}
              label="City"
              size="small"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextInput
              name="state"
              control={control}
              label="State"
              size="small"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextInput
              name="zip_code"
              control={control}
              label="Zip Code (5 digit)"
              size="small"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            {inProgress ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit(handleUpdate)}
                fullWidth
              >
                Add
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export function LocationsTable({
  page,
  limit,
  setPage,
  setLimit,
  locationsData,
  inProgress,
}: {
  page: number;
  limit: number;
  setPage: (event: unknown, newPage: number) => void;
  setLimit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  locationsData: { locations: Location[]; total: number };
  inProgress: boolean;
}) {
  const transformedLocationList = useMemo(() => {
    return locationsData.locations.map((loc, idx) => {
      return {
        ...loc,
        entry_number: idx + 1 + page * limit,
      };
    });
  }, [locationsData.locations, page, limit]);
  return (
    <Box sx={{ marginBottom: "24px" }}>
      <Typography variant="h5" component="h5" sx={{ marginBottom: "24px" }}>
        {"Locations"}
      </Typography>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {inProgress && <LinearProgress />}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  {""}
                </TableCell>
                <TableCell align="left" padding="normal">
                  {"Name"}
                </TableCell>
                <TableCell align="left" padding="normal">
                  {"Address"}
                </TableCell>
                <TableCell align="left" padding="normal">
                  {"Address-Secondary"}
                </TableCell>
                <TableCell align="left" padding="normal">
                  {"City"}
                </TableCell>
                <TableCell align="left" padding="normal">
                  {"State"}
                </TableCell>
                <TableCell align="left" padding="normal">
                  {"Zip Code"}
                </TableCell>
                <TableCell align="left" padding="normal">
                  {"Updated"}
                </TableCell>
                <TableCell align="left" padding="normal" />
              </TableRow>
            </TableHead>
            <TableBody>
              {transformedLocationList.map((row, idx) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.gmaps_place_id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell align="left">{row.entry_number}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.street_address}</TableCell>
                    <TableCell align="left">
                      {row.street_address_secondary ?? "-"}
                    </TableCell>
                    <TableCell align="left">{row.city}</TableCell>
                    <TableCell align="left">{row.state}</TableCell>
                    <TableCell align="left">{row.zip_code}</TableCell>
                    <TableCell align="left">
                      {row?.updated_date
                        ? formatTimestampToLocalAlt(row?.updated_date)
                        : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={locationsData.total}
          rowsPerPage={limit}
          page={page}
          onPageChange={setPage}
          onRowsPerPageChange={setLimit}
        />
      </Paper>
    </Box>
  );
}

export function LocationsTab() {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const { locationsData, addLocation, inProgress } = useAllLocations(
    page,
    limit,
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleAddLocation(data: CreateLocationPayload) {
    return addLocation(data);
  }

  if (locationsData.total < 1) {
    return (
      <AddLocationForm
        onAddLocation={handleAddLocation}
        inProgress={inProgress}
      />
    );
  }

  return (
    <LocationsTable
      page={page}
      limit={limit}
      setPage={handleChangePage}
      setLimit={handleChangeRowsPerPage}
      locationsData={locationsData}
      inProgress={inProgress}
    />
  );
}
