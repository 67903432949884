// vendor
import { useState, SetStateAction, useMemo, Dispatch } from "react";
import { createContext, PropsWithChildren, useContext } from "react";

// internal
import { useLocalStorage, LocalStorageItems } from "../../hooks";

// types
import { WalkThroughStepsEnum } from "./types";

export type WalkThroughProviderState = {
  walkThroughCompleted: boolean;
  activeWalkthrough: WalkThroughStepsEnum[];
  activeStep: WalkThroughStepsEnum | null;
  setActiveStep: Dispatch<SetStateAction<WalkThroughStepsEnum | null>>;
  nextStep: () => void;
  closeWalkthrough: () => void;
  startCreationWalkthrough: () => void;
  startSharingWalkthrough: () => void;
};

const defaultState: WalkThroughProviderState = {
  walkThroughCompleted: false,
  activeWalkthrough: [],
  activeStep: null,
  setActiveStep: () => {},
  nextStep: () => {},
  closeWalkthrough: () => {},
  startCreationWalkthrough: () => {},
  startSharingWalkthrough: () => {},
};

const WalkThroughContext =
  createContext<WalkThroughProviderState>(defaultState);

export const useWalkThrough = () => {
  return useContext(WalkThroughContext);
};

export const WALK_THROUGH_PANEL_AND_TABS: {
  [key in WalkThroughStepsEnum]: {
    title: string;
    description: string;
  };
} = {
  [WalkThroughStepsEnum.NAV_CREATE_WIDGET_BTN]: {
    title: "Create a Widget Tab",
    description: "When ever you need to create a new Widget",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_ADD_IMAGES]: {
    title: "Add a Product Image",
    description:
      "You can add up to 50 images, each image will have it's own Widget URL for sharing but the images are all still tied to this widget.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_ADD_DETAILS]: {
    title: "Add Product Details",
    description: "These are the details your customer will see.",
  },
  [WalkThroughStepsEnum.CREATE_WIIDGET_ADD_CATEGORY]: {
    title: "Pick A Category",
    description:
      "Add a category to your widget. When you add a category, you help organize your widgets. We also use this information so that we can replace the Widget incase your Widget has been removed or deactived, or out of stock.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_AI_ENABLED]: {
    title: "Activate AI",
    description:
      "Active AI or pass the conversation directly to you and your team.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_AGE_RESTRICTED]: {
    title: "Age Restriction Check",
    description:
      "Selling a product that has an age restriction, turn on an age check before your customers engage.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_PRODUCT_OFFER]: {
    title: "Product Offer",
    description:
      "Enter a product offer in the way you would verbally offer it to a customer.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_PRODUCT_OFFER_CODE]: {
    title: "Offer Code",
    description: "Create an offer code for customers to use in your store.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_TRAINING_FILES]: {
    title: "Add Product Data for the AI",
    description:
      "This is how we train the AI rep. Remember the more information you provide the better your agent can help support your customers.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_TRAINING_INPUT_FIELD]: {
    title: "Short AI Data",
    description:
      "Quickly add a short bit of data. Sometimes data is consistently changing or you only have a small amount, or you need to add a small bit quickly, so we provide you a short field that will quickly be added to the widgets context.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_ONLINE_SEARCH]: {
    title: "Make your Widget Available for Search",
    description:
      "Your Corporate Search Page is a location for your customers to search any and all of your Widgets that you wish to make public.",
  },
  [WalkThroughStepsEnum.CREATE_WIDGET_CREATE_NOW]: {
    title: "Now, Let's Go!",
    description: "Let's create this Widget and go share it with the world.",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY]: {
    title: "Welcome to the Widget Library",
    description:
      "Here is your Widget Library, you can find all of your created Widgts.",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY_VIEW_BTN]: {
    title: "Let's View One",
    description: "Go Ahead... Click View",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE]: {
    title: "This is your Widget Page",
    description:
      "Here is where you can find all of the information you have uploaded to this widget.",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE_EDIT]: {
    title: "Edit Here",
    description: "The next iteration will be your best one yet.",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE_ACTIVE]: {
    title: "Activate in Order to Share",
    description:
      "You must activate it before you share it. Think of it as being in draft mode. You may need to deactivate it before you can pull a link hosted on a third party site",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY_SHARING_PAGE_TAB]: {
    title: "Sharing With the World",
    description:
      "There are so many ways to share a Widget. You can embed it, share a QR code, place it on any platform. We look forward to see how you use it.",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY_SHARING_IMAGES]: {
    title: "Select an Image",
    description:
      "Each image is it's own Widget. When you select an image, all of the links will update to relfect the image selected as the main image of the chat. This will help tailor every communication with such precision.",
  },
  [WalkThroughStepsEnum.WIDGET_LIBRARY_ANALYTICS_PAGE_TAB]: {
    title: "And finally... the data!",
    description:
      "The data available will be updating frequently so be sure to check back and see what kind of new insights have come out of nerd station.",
  },
};

export const CREATE_WIDGET_WALKTHROUGH_JOURNEY = [
  WalkThroughStepsEnum.NAV_CREATE_WIDGET_BTN,
  WalkThroughStepsEnum.CREATE_WIDGET_ADD_IMAGES,
  WalkThroughStepsEnum.CREATE_WIDGET_ADD_DETAILS,
  WalkThroughStepsEnum.CREATE_WIIDGET_ADD_CATEGORY,
  WalkThroughStepsEnum.CREATE_WIDGET_AI_ENABLED,
  WalkThroughStepsEnum.CREATE_WIDGET_AGE_RESTRICTED,
  WalkThroughStepsEnum.CREATE_WIDGET_PRODUCT_OFFER,
  WalkThroughStepsEnum.CREATE_WIDGET_PRODUCT_OFFER_CODE,
  WalkThroughStepsEnum.CREATE_WIDGET_TRAINING_FILES,
  WalkThroughStepsEnum.CREATE_WIDGET_TRAINING_INPUT_FIELD,
  WalkThroughStepsEnum.CREATE_WIDGET_ONLINE_SEARCH,
  WalkThroughStepsEnum.CREATE_WIDGET_CREATE_NOW,
];

export const SHARE_WIDGET_WALKTHROUGH_JOURNEY = [
  WalkThroughStepsEnum.WIDGET_LIBRARY,
  WalkThroughStepsEnum.WIDGET_LIBRARY_VIEW_BTN,
  WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE,
  WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE_EDIT,
  WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE_ACTIVE,
  WalkThroughStepsEnum.WIDGET_LIBRARY_SHARING_PAGE_TAB,
  WalkThroughStepsEnum.WIDGET_LIBRARY_SHARING_IMAGES,
  WalkThroughStepsEnum.WIDGET_LIBRARY_ANALYTICS_PAGE_TAB,
];

const useWalkThroughState = () => {
  const [activeWalkthrough, setActiveWalkthrough] = useState<
    WalkThroughStepsEnum[]
  >([]);
  const [activeStep, setActiveStep] = useState<WalkThroughStepsEnum | null>(
    null,
  );

  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();

  const walkThroughCompleted = useMemo(() => {
    const creationWalkthroughComplete =
      getLocalStorageItem(LocalStorageItems.CREATION_WALKTHROUGH_COMPLETED) ===
      "complete";
    const sharingWalktrhoughComplete =
      getLocalStorageItem(LocalStorageItems.SHARING_WALKTHROUGH_COMPLETED) ===
      "complete";
    return creationWalkthroughComplete && sharingWalktrhoughComplete;
  }, [activeStep, activeWalkthrough]);

  function closeWalkthrough() {
    setActiveStep(null);
    setActiveWalkthrough([]);

    if (activeWalkthrough === CREATE_WIDGET_WALKTHROUGH_JOURNEY) {
      setLocalStorageItem(
        LocalStorageItems.CREATION_WALKTHROUGH_COMPLETED,
        "complete",
      );
    } else if (activeWalkthrough === SHARE_WIDGET_WALKTHROUGH_JOURNEY) {
      setLocalStorageItem(
        LocalStorageItems.SHARING_WALKTHROUGH_COMPLETED,
        "complete",
      );
    }
  }

  const nextStep = () => {
    if (activeStep && activeWalkthrough) {
      const currentIdx = activeWalkthrough.indexOf(activeStep);
      const nextIdx = currentIdx + 1;

      if (nextIdx >= activeWalkthrough.length) {
        closeWalkthrough();
      } else {
        setActiveStep(activeWalkthrough[nextIdx]);
      }
    }
  };

  function startCreationWalkthrough() {
    setActiveWalkthrough(CREATE_WIDGET_WALKTHROUGH_JOURNEY);
    setActiveStep(CREATE_WIDGET_WALKTHROUGH_JOURNEY[0]);
  }

  function startSharingWalkthrough() {
    setActiveWalkthrough(SHARE_WIDGET_WALKTHROUGH_JOURNEY);
    setActiveStep(SHARE_WIDGET_WALKTHROUGH_JOURNEY[0]);
  }

  return {
    walkThroughCompleted,
    activeWalkthrough,
    activeStep,
    setActiveStep,
    nextStep,
    closeWalkthrough,
    startCreationWalkthrough,
    startSharingWalkthrough,
  };
};

export function WalkThroughProvider({ children }: PropsWithChildren) {
  const state = useWalkThroughState();
  return (
    <WalkThroughContext.Provider value={state}>
      {children}
    </WalkThroughContext.Provider>
  );
}
