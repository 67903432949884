// vendor
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { truncate as _truncate, debounce as _debounce } from "lodash";

// material ui
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import LinearProgress from "@mui/material/LinearProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// internal
import { useAllWidgets } from "../../hooks";
import { formatTimestampToLocalDate } from "../../utils";
import {
  Thumbnail,
  MenuTableCell,
  WalkThrough,
  WalkThroughStepsEnum,
} from "../../components";
import { LibraryPageEnum, LoggedInRoutesEnum } from "../../constants";
import { WidgetStatusEnum } from "../../types";

// types
import type { WidgetStatus } from "../../types";

export function WidgetLibrary() {
  // const [page, setPage] = useState<number>(0);
  // const [limit, setLimit] = useState<number>(10);
  // const [type, setType] = useState<"active" | "inactive" | "all">("all");
  // const [puid, setPuid] = useState("");
  const [widgetFilters, setWidgetFilters] = useState<{
    page: number;
    limit: number;
    type: WidgetStatus | "all";
    puid: string;
  }>({
    page: 0,
    limit: 10,
    type: "all",
    puid: "",
  });
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const { widgetListData, inProgress } = useAllWidgets(widgetFilters);

  const rows = widgetListData.widgets ?? [];

  const handleChangePage = (event: unknown, newPage: number) => {
    setWidgetFilters((existingFilters) => {
      return { ...existingFilters, page: newPage };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWidgetFilters((existingFilters) => {
      return {
        ...existingFilters,
        page: 0,
        limit: parseInt(event.target.value, 10),
      };
    });
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    setWidgetFilters((existingFilters) => {
      return { ...existingFilters, type: event.target.value as WidgetStatus };
    });
  };

  const handleChangePuid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidgetFilters((existingFilters) => {
      return {
        ...existingFilters,
        puid: event.target.value,
        page: 0,
        limit: 10,
      };
    });
  };

  const clearPuid = () => {
    setWidgetFilters((existingFilters) => {
      return { ...existingFilters, puid: "", page: 0, limit: 10 };
    });
  };

  return (
    <Container maxWidth="xl" sx={{ paddingTop: "75px" }}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {inProgress && <LinearProgress />}
          <Toolbar
            sx={
              !isDesktop
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingTop: "18px",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    paddingTop: "18px",
                  }
            }
          >
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              sx={{ margin: "0 12px 12px 0", maxWidth: "350px" }}
            >
              {LibraryPageEnum.TABLE_HEADER}
            </Typography>
            <TextField
              id="puid-text-field"
              label="Unique Identifier"
              value={widgetFilters.puid}
              onChange={handleChangePuid}
              sx={{ margin: "0 12px 12px 0", maxWidth: "350px" }}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={
                !!widgetFilters.puid
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={clearPuid}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
              placeholder="VIN, SKU, Stock #...."
            />
            <FormControl
              sx={{ margin: "0 12px 12px 0", maxWidth: "350px" }}
              fullWidth
            >
              <InputLabel id="widget-status-select-label">Status</InputLabel>
              <Select
                labelId="widget-status-select-label"
                id="widget-status-select"
                value={widgetFilters.type}
                onChange={handleChangeType}
                label="Status"
                sx={{ textTransform: "capitalize" }}
              >
                <MenuItem value="all">All</MenuItem>
                {
                  <MenuItem
                    value={WidgetStatusEnum.ACTIVE}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {WidgetStatusEnum.ACTIVE}
                  </MenuItem>
                }
                {
                  <MenuItem
                    value={WidgetStatusEnum.INACTIVE}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {WidgetStatusEnum.INACTIVE}
                  </MenuItem>
                }
                {
                  <MenuItem
                    value={WidgetStatusEnum.BUILDING}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {WidgetStatusEnum.BUILDING}
                  </MenuItem>
                }
                {
                  <MenuItem
                    value={WidgetStatusEnum.FAILED}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {WidgetStatusEnum.FAILED}
                  </MenuItem>
                }
                {
                  <MenuItem
                    value={WidgetStatusEnum.QUEUED}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {WidgetStatusEnum.QUEUED}
                  </MenuItem>
                }
                {
                  <MenuItem
                    value={WidgetStatusEnum.NEW}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {WidgetStatusEnum.NEW}
                  </MenuItem>
                }
              </Select>
            </FormControl>
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    {LibraryPageEnum.THUMBNAIL_COLUMN_LABEL}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {LibraryPageEnum.DETAILS_COLUMN_LABEL}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {LibraryPageEnum.ACTIVE_COLUMN_LABEL}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {LibraryPageEnum.AI_COLUMN_LABEL}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {LibraryPageEnum.CREATED_DATE_COLUMN_LABEL}
                  </TableCell>
                  <TableCell align="left" padding="normal" />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        align="left"
                        onClick={() =>
                          navigate(
                            `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                          )
                        }
                      >
                        <Thumbnail
                          url={row?.images?.[0]?.image_url}
                          size="medium"
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() =>
                          navigate(
                            `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                          )
                        }
                      >
                        <Typography variant="h6">{row.widget_title}</Typography>
                        <Typography variant="body1">
                          {_truncate(row.widget_description, {
                            length: 75,
                            separator: " ",
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() =>
                          navigate(
                            `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                          )
                        }
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.widget_status}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() =>
                          navigate(
                            `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                          )
                        }
                      >
                        {row.is_ai_enabled ? "Yes" : "No"}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() =>
                          navigate(
                            `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                          )
                        }
                      >
                        {formatTimestampToLocalDate(row.created_date)}
                      </TableCell>
                      <TableCell>
                        {i === 0 ? (
                          <WalkThrough
                            locationID={
                              WalkThroughStepsEnum.WIDGET_LIBRARY_VIEW_BTN
                            }
                            mimicAction={() => {
                              navigate(
                                `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                              );
                            }}
                          >
                            <MenuTableCell
                              options={[
                                {
                                  key: LibraryPageEnum.MENU_VIEW_OPTION_LABEL,
                                  label: LibraryPageEnum.MENU_VIEW_OPTION_LABEL,
                                  onClickOption: () =>
                                    navigate(
                                      `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                                    ),
                                },
                                {
                                  key: LibraryPageEnum.MENU_EDIT_OPTION_LABEL,
                                  label: LibraryPageEnum.MENU_EDIT_OPTION_LABEL,
                                  onClickOption: () =>
                                    navigate(
                                      `${LoggedInRoutesEnum.EDIT_WIDGET_PREFIX}/${row.uuid}`,
                                    ),
                                },
                              ]}
                            />
                          </WalkThrough>
                        ) : (
                          <MenuTableCell
                            options={[
                              {
                                key: LibraryPageEnum.MENU_VIEW_OPTION_LABEL,
                                label: LibraryPageEnum.MENU_VIEW_OPTION_LABEL,
                                onClickOption: () =>
                                  navigate(
                                    `${LoggedInRoutesEnum.WIDGET_VIEW_PREFIX}/${row.uuid}`,
                                  ),
                              },
                              {
                                key: LibraryPageEnum.MENU_EDIT_OPTION_LABEL,
                                label: LibraryPageEnum.MENU_EDIT_OPTION_LABEL,
                                onClickOption: () =>
                                  navigate(
                                    `${LoggedInRoutesEnum.EDIT_WIDGET_PREFIX}/${row.uuid}`,
                                  ),
                              },
                            ]}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={widgetListData.total}
            rowsPerPage={widgetFilters.limit}
            page={widgetFilters.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  );
}
