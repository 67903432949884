export enum RepConversationTableEnum {
  TABLE_HEADER = "Live Chats",
  COLUMN_ONE = "Unread",
  COLUMN_TWO = "#-of-Participants",
  COLUMN_THREE = "Public",
  COLUMN_FOUR = "#-of-Msgs",
  COLUMN_FIVE = "Last-Message",
  COLUMN_SIX = "Created",
  MENU_LABEL_ONE = "View Conversation",
}

export enum ActiveConversationTableEnum {
  TABLE_HEADER = "Active AI Chats",
  COLUMN_ONE = "Needs-Support",
  COLUMN_TWO = "Active",
  COLUMN_THREE = "Public",
  COLUMN_FOUR = "#-of-Msgs",
  COLUMN_FIVE = "Last-Message",
  COLUMN_SIX = "Created",
  MENU_LABEL_ONE = "View Conversation",
}

export enum MessageInterfaceEnum {
  INPUT_PLACEHOLDER = "Type a message",
}

export enum ChatDetailsEnum {
  BACK_LINK_TEXT = "<< Back to conversations",
  STATUS_LABEL = "Status",
  STATUS_ONE = "AI is engaging the customer",
  STATUS_TWO = "You are engaging the customer",
  STATUS_THREE = "Another team member is engaging the customer",
  BUTTON_LABEL_ONE = "AI CHAT ACTIVE",
  BUTTON_LABEL_TWO = "HUMAN CHAT ACTIVE",
  BUTTON_LABEL_THREE = "REP ENGAGED",
  TAB_LABEL_ONE = "CUSTOMER",
  TAB_LABEL_TWO = "NOTES",
  TAB_LABEL_THREE = "WIDGET",
  NOTES_INPUT_PLACEHOLDER = "Add a note",
}
