// vendor
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
  useContext,
} from "react";
import {
  query,
  orderBy,
  doc,
  getDoc,
  limit,
  collection,
  DocumentData,
  addDoc,
  serverTimestamp,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { useRecoilValue } from "recoil";

// internal
import { useUserStats } from "../UserStatsProvider";
import { initFirebase, getFBDb } from "../../services";
import { firebaseUserAtom } from "../../recoil";
import { Conversation, Message } from "../../types";

import type { MessageProviderState } from "./types";

const defaultState: MessageProviderState = {
  targetConversation: null,
  targetConversationMessages: [],
  handleSetTargetConversation: () => Promise.resolve(),
  sendMessage: () => Promise.resolve(),
  toggleAiChatOnTarget: () => Promise.resolve(),
  addCurrentUserToConversation: () => Promise.resolve(),
  updateLastReadMessage: () => Promise.resolve(),
};

const MessagesContext = createContext<MessageProviderState>(defaultState);

export const useMessages = () => {
  return useContext(MessagesContext);
};

const useMessagesState = () => {
  const firebaseUser = useRecoilValue(firebaseUserAtom);
  const { refetchStats } = useUserStats();
  const [targetConversation, setTargetConversation] =
    useState<Conversation | null>(null);
  const [targetConversationMessages, setTargetConversationMessages] = useState<
    Message[]
  >([]);

  const handleSetTargetConversation = async (
    conversationUid: string | null,
  ) => {
    if (conversationUid) {
      const db = getFBDb();
      const convoRef = doc(db, "conversations", conversationUid);
      const docSnap = await getDoc(convoRef);
      if (docSnap.exists()) {
        const payload = {
          conversation_uid: docSnap.id,
          ...docSnap.data(),
        } as Conversation;
        setTargetConversation(payload);
      }
    } else {
      setTargetConversation(null);
    }
  };

  const sendMessage = async (msg: string) => {
    if (targetConversation?.conversation_uid && firebaseUser?.uid) {
      const db = getFBDb();
      const messageSubCollectionRef = collection(
        db,
        "conversations",
        targetConversation.conversation_uid,
        "messages",
      );
      const convoRef = doc(
        db,
        "conversations",
        targetConversation.conversation_uid,
      );
      const docRef = await addDoc(messageSubCollectionRef, {
        author_uid: firebaseUser.uid,
        conversation_uid: targetConversation.conversation_uid,
        text: msg,
        created_at: serverTimestamp(),
      });

      const docSnap = await getDoc(docRef);

      await updateDoc(convoRef, {
        message_count: targetConversation?.message_count
          ? targetConversation.message_count + 1
          : 1,
        last_message: { message_uid: docSnap.id, ...docSnap.data() },
      });
    }
  };

  const toggleAiChatOnTarget = async (enable: boolean) => {
    if (
      targetConversation?.conversation_uid &&
      !targetConversation?.is_ai_enabled
    ) {
      const db = getFBDb();
      const convoRef = doc(
        db,
        "conversations",
        targetConversation.conversation_uid,
      );
      await updateDoc(convoRef, {
        is_ai_enabled: enable,
      });
    }
  };

  const addCurrentUserToConversation = async () => {
    if (targetConversation?.conversation_uid && firebaseUser?.uid) {
      const db = getFBDb();
      const convoRef = doc(
        db,
        "conversations",
        targetConversation.conversation_uid,
      );
      let newMembers = [];

      if (
        targetConversation?.members &&
        targetConversation.members.includes(firebaseUser.uid)
      ) {
        newMembers = targetConversation.members;
      } else {
        newMembers = [...targetConversation?.members, firebaseUser.uid];
      }

      await updateDoc(convoRef, {
        is_ai_enabled: false,
        requires_support: false,
        members: newMembers,
      });
      await refetchStats();
    }
  };

  const updateLastReadMessage = async () => {
    if (targetConversation?.conversation_uid && firebaseUser?.uid) {
      const db = getFBDb();
      const convoRef = doc(
        db,
        "conversations",
        targetConversation.conversation_uid,
      );
      let lastReadPayload: { [k: string]: string } = {};

      const readId =
        targetConversationMessages[targetConversationMessages.length - 1]
          ?.message_uid;

      if (readId) {
        lastReadPayload[firebaseUser.uid] = readId;
      }

      if (targetConversation?.last_read) {
        // if previous message read data, make sure
        // to include it
        lastReadPayload = {
          ...targetConversation.last_read,
          ...lastReadPayload,
        };
      }

      await updateDoc(convoRef, {
        last_read: lastReadPayload,
        has_corporate_unread: false,
      });
      await refetchStats();
    }
  };

  useEffect(() => {
    initFirebase();
  }, []);

  useEffect(() => {
    let unsubscribe = () => {};

    if (targetConversation?.conversation_uid) {
      const db = getFBDb();
      const q = query(
        collection(
          db,
          "conversations",
          targetConversation.conversation_uid,
          "messages",
        ),
        orderBy("created_at", "desc"),
        limit(50),
      );

      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedMessages: Message[] = [];
        querySnapshot.forEach((doc: DocumentData) => {
          fetchedMessages.push({ ...doc.data(), message_uid: doc.id });
        });
        const sortedMessages = fetchedMessages.sort((a, b) => {
          if (a?.created_at?.seconds && b?.created_at?.seconds) {
            return a.created_at.seconds - b.created_at.seconds;
          } else {
            return -1;
          }
        });
        setTargetConversationMessages(sortedMessages);
      });
    }

    return () => {
      unsubscribe();
    };
  }, [targetConversation?.conversation_uid]);

  useEffect(() => {
    let unsubscribe = () => {};
    if (targetConversation?.conversation_uid) {
      const db = getFBDb();
      unsubscribe = onSnapshot(
        doc(db, "conversations", targetConversation.conversation_uid),
        (doc) => {
          if (doc.exists()) {
            const payload = {
              conversation_uid: doc.id,
              ...doc.data(),
            } as Conversation;
            setTargetConversation(payload);
          }
        },
      );
    }

    return () => {
      unsubscribe();
    };
  }, [targetConversation?.conversation_uid]);

  return {
    targetConversation,
    targetConversationMessages,
    handleSetTargetConversation,
    sendMessage,
    toggleAiChatOnTarget,
    addCurrentUserToConversation,
    updateLastReadMessage,
  };
};

export function MessagesProvider({ children }: PropsWithChildren) {
  const state = useMessagesState();
  return (
    <MessagesContext.Provider value={state}>
      {children}
    </MessagesContext.Provider>
  );
}
