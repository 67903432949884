// vendor
import { useMemo } from "react";

// material ui
import { LineChart as MuiLineChart } from "@mui/x-charts/LineChart";
import useMediaQuery from "@mui/material/useMediaQuery";

export function LineChart({
  intervals,
  series,
  label,
}: {
  label: string;
  intervals: string[];
  series: number[];
}) {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const sizes = useMemo(() => {
    if (isDesktop) {
      return { width: 575, height: 345 };
    } else {
      return { width: 400, height: 240 };
    }
  }, [isDesktop]);

  return (
    <MuiLineChart
      xAxis={[{ scaleType: "point", data: intervals }]}
      colors={["#7A41E1"]}
      series={[
        {
          label,
          data: series,
          showMark: false,
        },
      ]}
      width={sizes.width}
      height={sizes.height}
    />
  );
}
