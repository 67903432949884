import { useState, useEffect } from "react";

// internal
import {
  getOrganization,
  putOrganizationUpdate,
  OrgUpdatePayload,
} from "../../handlers";

// types
import type { Organization } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  organization: Organization | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  organization: Organization | null;
  updateOrganization: (payload: OrgUpdatePayload) => void;
};

export function useOrganization(orgID?: number): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    organization: null,
  });

  const getUserOrganization = async (parentOrgId: number) => {
    setRequestState({
      organization: null,
      inProgress: true,
      error: false,
    });
    return getOrganization(parentOrgId)
      .then((resp) => {
        setRequestState({
          organization: resp,
          inProgress: false,
          error: false,
        });
      })
      .catch(() => {
        setRequestState({
          organization: null,
          inProgress: false,
          error: true,
        });
      });
  };

  const updateOrganization = async (payload: OrgUpdatePayload) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return putOrganizationUpdate(payload)
      .then(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
          };
        });
        if (!!orgID) {
          getUserOrganization(orgID);
        }
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  useEffect(() => {
    if (!!orgID) {
      getUserOrganization(orgID);
    }
  }, [orgID]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    organization: requestState.organization,
    updateOrganization,
  };
}
