import { useEffect, useRef } from "react";
import { isEqual as _isEqual } from "lodash";

function deepCompareEquals(a: any, b: any) {
  return _isEqual(a, b);
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(
  callback: () => void,
  dependencies: any[],
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
