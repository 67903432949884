// material ui
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";

export function StatsCard({
  label,
  count,
  onClick,
  icon,
}: {
  label: string;
  count: number;
  onClick: () => void;
  icon: React.ReactNode;
}) {
  const theme = useTheme();
  return (
    <Card sx={{ paddingTop: "6px" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Box sx={{ margin: "6px 18px 12px 12px" }}>
          <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{icon}</Avatar>
        </Box>
        <Box>
          <Typography
            variant="overline"
            sx={{ color: "gray", fontWeight: "bold", lineHeight: "1.6" }}
          >
            {label}
          </Typography>
          <Typography variant="h6">{count}</Typography>
        </Box>
      </Box>
      <Divider />
      <CardActions>
        <Button
          variant="text"
          endIcon={<ArrowForwardIcon />}
          sx={{ color: "#000000" }}
          onClick={onClick}
        >
          {label}
        </Button>
      </CardActions>
    </Card>
  );
}
