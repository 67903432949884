// vendor
import axios from "axios";

// internal
import { getHeaders, API_BASE } from "../shared";

// types
import { CreateLocationPayload } from "../../types";

export type AddUserPayload = {
  first_name: string;
  last_name: string;
  email: string;
};

export type OrgUpdatePayload = {
  name?: string;
  branding_hex?: string;
  header_hex?: string;
  message_hex?: string;
  email_domain?: string;
  allow_domain_join?: boolean;
  use_custom_branding?: boolean;
};

export const getAllOrgLocations = async (page: number, limit: number) => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/locations/all?page=${page}&limit=${limit}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch location list", e);
  }
  return result;
};

export const postLocation = async (
  locationData: { organization_id: number } & CreateLocationPayload,
) => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/locations`,
      headers,
      data: JSON.stringify(locationData),
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to add location", e);
  }
  return result;
};

export const getAllOrgUsers = async (page: number, limit: number) => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/organizations/user/all?page=${page}&limit=${limit}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch user list", e);
  }
  return result;
};

export const postUserToOrg = async (payload: AddUserPayload) => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/organizations/user`,
      headers,
      data: JSON.stringify(payload),
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch location list", e);
  }
  return result;
};

export const getOrganization = async (id: number) => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/organizations/${id}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch organization", e);
  }
  return result;
};

export const putOrganizationUpdate = async (payload: OrgUpdatePayload) => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "PUT",
      url: `${API_BASE}/api/organizations`,
      headers,
      data: JSON.stringify(payload),
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to update organzation", e);
  }
  return result;
};

export const deleteUserFromOrg = async (userID: number) => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "DELETE",
      url: `${API_BASE}/api/organizations/user/${userID}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to delete user", e);
  }
  return result;
};
