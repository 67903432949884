export enum CreationViewEnum {
  VIEW_HEADER = "Create Widget",
  IMAGES_SUB_HEADER = "Images",
  DETAILS_SUB_HEADER = "Widget Details",
  DETAILS_TOOLTIP_TEXT = "Details will display above the image in the Widget chat. General product descriptions or brand information can be loaded as training data below",
  WIDGET_TITLE_HELPER_TEXT = "Specific titles work best, as granular as a vehicle VIN number or product model number",
  WIDGET_DESCRIPTION_HELPER_TEXT = "Include specific product or model information that is important for the AI chat to communicate to customers",
  IMAGE_UPLOAD_LABEL = "Upload Images",
  IMAGE_UPLOAD_HELPER_TEXT = "Upload between 1 and 50 product images. Each image will receive a widget URL so you can publish each image separately but all images can be viewed within every Widget Chat",
  WIDGET_CATEGORY_HELPER_TEXT = "Categories help your customers find similar products on your public Widget search page",
  OPTIONS_SUB_HEADER = "Chat Options",
  OPTIONS_TOOLTIP_TEXT = "Configure different behaviors for the Widget chat depending on how you would like to use it",
  AI_TOGGLE_ON_LABEL = "AI Agent Enabled",
  AI_TOGGLE_OFF_LABEL = "AI Agent Disabled",
  AI_TOGGLE_HELPER_TEXT = "Enable if you want customers to receive responses in the chat without manual intervention",
  AGE_TOGGLE_ON_LABEL = "Age Restriction On",
  AGE_TOGGLE_OFF_LABEL = "Age Restriction Off",
  AGE_TOGGLE_HELPER_TEXT = "Enforces a prompt for customers to confirm they are 21 years old or older",
  INDEXING_TOGGLE_ON_LABEL = "Google Indexing On",
  INDEXING_TOGGLE_OFF_LABEL = "Google Indexing Off",
  INDEXING_TOGGLE_HELPER_TEXT = "Enable if you would like your Widget images indexed by Google",
  CTA_TEXT_LABEL = "CTA Text",
  CTA_TEXT_HELPER_TEXT = "The text or Call-to-Action you would like displayed in the upper-right of your chat",
  CTA_URL_LABEL = "CTA URL",
  CTA_URL_HELPER_TEXT = "Link to the destination where you would like your user to be sent when clicking the CTA",
  TITLE_INPUT_LABEL = "Title",
  DESCRIPTION_INPUT_LABEL = "Description",
  PUID_INPUT_LABEL = "Unique Identifier",
  PUID_HELPER_TEXT = "The Unique Identifier is an ID for your product not shared by other products. Examples include SKUs, stock numbers or VIN numbers",
  DEAL_SUB_HEADER = "Product Offer",
  DEAL_TOOLTIP_TEXT = "The AI Agent will emphasize the offer, price and dates in its messages to your customers. All of this information will be displayed above the image in the Widget chat",
  OFFER_HELPER_TEXT = "Enter a product offer in the way you would verbally offer it to a customer",
  OFFER_INPUT_LABEL = "Product Offer",
  OFFER_CODE_HELPER_TEXT = "Create a code for the customer to redeem the offer, example: 'OFFER24'",
  OFFER_CODE_INPUT_LABEL = "Offer Code",
  PRICE_INPUT_LABEL = "Price",
  PRICE_HELPER_TEXT = "If you don't want to display a price, leave it blank",
  START_DATE_INPUT_LABEL = "Start Date",
  START_DATE_HELPER_TEXT = "The first day the product offer is valid",
  END_DATE_INPUT_LABEL = "End Date",
  END_DATE_HELPER_TEXT = "The last day the product offer is valid",
  TRAINING_SUB_HEADER = "AI Service Agent",
  TRAINING_TOOLTIP_TEXT = "Load product data used by your AI agent, including product details and support documentation. The more detail you add, the more personalized, educated, and effective you're AI agent will be. You can use the Training Text as an opportunity to inject last minute details or personality into the chat experience for your customers.",
  GREETING_LABEL = "Custom Greeting",
  GREETING_HELPER_TEXT = "Provide your own greeting for your customers to see in the chat",
  AGENT_NAME_LABEL = "Agent Name",
  AGENT_NAME_HELPER_TEXT = "Provide your own name for the AI agent",
  FILE_UPLOAD_LABEL = "Upload Product Data",
  FILE_UPLOAD_HELPER_TEXT = "Ex: Product Descriptions, COAs, Manuals, Informational Stickers, Reports. ",
  TRAINING_TEXT_INPUT_LABEL = "Product Description",
  TRAINING_TEXT_HELPER_TEXT = "You can enter plain text data in this field and it will be incorporated into the responses of the AI agent",
  AVAILABILITY_SUB_HEADER = "Availability",
  ONLINE_CHECK_HELPER_TEXT = "Enable to make this Widget publicly available on your Widget collection page",
  LOCATIONS_SELECT_LABEL = "Store Locations",
  LOCATIONS_TOOLTIP_TEXT = "You can use Search page links in your social media bio section and make Widgets available there for anyone to find. For physical locations, your nearest store location will be communicated to customers who have shared their location and are looking to purchase a product. New locations can be added in your Settings",
  LOCATIONS_SELECT_HELPER_TEXT = "Physical store locations where the product can be purchased",
  CREATE_CTA_LABEL = "Create Widget",
}

export enum EditingViewEnum {
  VIEW_HEADER = "Edit Widget",
  EDIT_CTA_LABEL = "Update Widget",
}

export enum WidgetShareEnum {
  INACTIVE_WARNING = "In order to start sharing, your widget must be activated and the 'Status' must be 'Active'. Return to the Widget tab.",
  SOCIAL_SUB_HEADER = "Social Media",
  QR_CODE_SUB_HEADER = "QR Code:",
  EMBED_SUB_HEADER = "Embed:",
  EMBED_CTA_TEXT = "Generate HTML",
  EMBED_MODAL_TITLE = "Embed Widget Into Webpage",
  EMBED_MODAL_WITH_NAV = "Embed Widget With Navbar",
  EMBED_MODAL_WITHOUTNAV = "Embed Widget Without the Navbar",
  EMBED_MODAL_SUBHEADER = "Copy the snippet below and paste into your website HTML to embed the live widget into your website. Your site's security settings may require modification. Contact our support line if you need assistance.",
}

export enum WidgetViewEnum {
  EDIT_CTA_LABEL = "Edit",
  ACTIVATION_CTA_LABEL = "Activate",
  DEACTIVATION_CTA_LABEL = "Deactivate",
  WIDGET_LIVE_LABEL = "Status:",
  AI_ACTIVE_LABEL = "AI Active:",
  AGE_RESTRICTED_LABEL = "Age Restricted:",
  INDEXING_ENABLED_LABEL = "Google Indexing:",
  DEAL_SUB_HEADER = "Deal",
  OFFER_LABEL = "Offer:",
  OFFER_CODE_LABEL = "Offer Code:",
  PRICE_LABEL = "Price:",
  START_LABEL = "Start:",
  END_LABEL = "End:",
  TRAINING_SUB_HEADER = "Product Data",
  AVAILABILITY_SUB_HEADER = "Availability",
  ONLINE_CHECKBOX_LABEL = "Online",
}

export enum WidgetAnalyticsEnum {
  INACTIVE_WARNING = "In order to start viewing analytics data on your widget, you must activate and share it first. Return to the Widget tab.",
  TIME_FRAME_SELECT_LABEL = "Time Frame",
  CHART_LABEL = "Unique Engagements",
  TOTAL_ENGAGE_SUB_HEADER = "Total Unique Engagements",
  LINK_VIEW_SUB_HEADER = "Widget Link Views",
  REFERER_SUB_HEADER = "Top 5 Referers",
}
