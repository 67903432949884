// vendor
import { atom } from "recoil";

export const globalLoggedInAtom = atom({
  key: "globalUser",
  default: false,
});

export const globalErrorsAtom = atom({
  key: "globalErrors",
  default: {} as any,
});

export const globalNetworkAtom = atom({
  key: "globalNetwork",
  default: {} as any,
});
