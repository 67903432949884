/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const ACCEPTED_FILE_EXTENSIONS = [
  ".pdf",
  ".txt",
  ".doc",
  ".docx",
  ".xls",
  ".xslx",
];

const ACCEPTED_FILE_MIME_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "text/plain",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const MAX_FILE_SIZE_MB: number = Number(
  process.env.REACT_APP_MAX_FILE_SIZE_MB ?? "5",
);

export type FileUploadError = {
  message: string;
  error: boolean;
};

type FileUploadProps = {
  label: string;
  helperText?: string;
  size?: "small" | "medium";
  errorFromParent?: string;
  onSelectFileFromParent?: (file: File) => void;
};

export function FileUpload({
  size = "small",
  label,
  helperText = "",
  errorFromParent,
  onSelectFileFromParent = () => {},
}: FileUploadProps) {
  const [error, setError] = useState<FileUploadError | null>(null);

  const accept = ACCEPTED_FILE_EXTENSIONS.join(",");

  useEffect(() => {
    if (!!errorFromParent) {
      setError({ message: errorFromParent, error: true });
    }
  }, [errorFromParent]);

  function onSelectFile(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const files = event.target.files;
    const targetFile = files?.[0];
    const fileType = targetFile?.type ?? "no-op";
    const fileNameParts = targetFile?.name.split(".") ?? [".no-op"];
    const fileExtension = `.${fileNameParts[fileNameParts.length - 1]}`;

    if (!files || !targetFile) {
      // file didn't get uploaded
      setError({ error: true, message: "Files are missing" });
    } else if (targetFile.size > MAX_FILE_SIZE_MB * 1000 * 1024) {
      // file is too big
      setError({
        error: true,
        message: `File exceeds max file size limit of ${MAX_FILE_SIZE_MB}MB`,
      });
    } else if (!ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
      // file has the wrong extension
      setError({
        error: true,
        message: `Incorrect file extension. Accepts: ${ACCEPTED_FILE_EXTENSIONS.join(
          ", ",
        )}`,
      });
    } else if (!ACCEPTED_FILE_MIME_TYPES.includes(fileType)) {
      // file is the right extension but wrong type
      setError({
        error: true,
        message: `Incorrect file type. Accepts: ${ACCEPTED_FILE_MIME_TYPES.join(
          ", ",
        )}`,
      });
    } else {
      onSelectFileFromParent(targetFile);
      setError(null);
    }
  }

  return (
    <FormControl sx={{ width: "100%" }}>
      <Button
        fullWidth
        component="label"
        size={size}
        color={!!error ? "error" : "primary"}
        variant="outlined"
        startIcon={<CloudUploadIcon />}
      >
        {label}
        <input
          type="file"
          accept={accept}
          css={css({
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: 1,
            overflow: "hidden",
            position: "absolute",
            bottom: 0,
            left: 0,
            whiteSpace: "nowrap",
            width: 1,
          })}
          onChange={onSelectFile}
        />
      </Button>
      <FormHelperText
        error={!!error?.message}
        sx={{
          "&:first-letter": { textTransform: "capitalize" },
          paddingTop: "6px",
        }}
      >
        {error?.message
          ? error.message
          : `${helperText}Maximum file size is ${MAX_FILE_SIZE_MB}MB. Accepts: ${ACCEPTED_FILE_EXTENSIONS.join(
              ", ",
            )}`}
      </FormHelperText>
    </FormControl>
  );
}
