const REACT_APP_THEME = process.env.REACT_APP_THEME ?? "";

export function TermsOfUseLabel() {
  if (REACT_APP_THEME === "widgetgpt") {
    return (
      <span>
        I agree with WidgetGPT's{" "}
        <a
          href="https://widgetgpt.co/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </span>
    );
  } else {
    return (
      <span>
        I agree with LeafWidget's{" "}
        <a
          href="https://leafwidget.co/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </span>
    );
  }
}
