// vendor
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// material ui
import Container from "@mui/material/Container";

// internal
import { LoggedInRoutesEnum } from "../../constants";
import { MessageInterface } from "./MessageInterface/MessageInterface";
import { MessagesProvider } from "../../providers";

export function ChatView() {
  const navigate = useNavigate();
  const { conversation_uid } = useParams();
  function handleOnClickExit() {
    return navigate(LoggedInRoutesEnum.CONVERSATIONS_PAGE);
  }

  return (
    <MessagesProvider>
      <Container
        maxWidth="xl"
        sx={{
          maxHeight: "100vh",
          paddingTop: "0px",
          background: "#ffffff",
          borderRadius: "5px",
        }}
      >
        <MessageInterface
          handleOnClickExit={handleOnClickExit}
          conversationUid={conversation_uid}
        />
      </Container>
    </MessagesProvider>
  );
}
