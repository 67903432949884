// vendor
import { useState } from "react";

// material ui
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";

// internal
import { useAppUser } from "../../hooks";
import { NAME_SCHEMA, EMAIL_INPUT_SCHEMA } from "../../utils";
import { EditableField } from "./EditableField";

export type EditingMap = {
  first_name: boolean;
  last_name: boolean;
  email: boolean;
};

export function ProfileTab() {
  const { appUser, updateUser } = useAppUser();

  const [editing, setEditing] = useState<EditingMap>({
    first_name: false,
    last_name: false,
    email: false,
  });

  function handleUpdate(
    key: "first_name" | "last_name" | "email",
    value: string,
  ) {
    updateUser({ [key]: value }).then(() =>
      setEditing((old) => ({ ...old, [key]: false })),
    );
  }

  return (
    <Box>
      <Typography variant="h5" component="h5" sx={{ marginBottom: "24px" }}>
        {"Settings"}
      </Typography>
      <Grid container spacing={2} sx={{ maxWidth: "50%" }}>
        <Grid container spacing={2} item xs={12}>
          {!editing.first_name ? (
            <>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontWeight: "bold", marginRight: "12px" }}
                >
                  {"First name:"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" component="p">
                  {appUser?.first_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  sx={{ marginLeft: "12px" }}
                  color="primary"
                  onClick={() =>
                    setEditing((old) => ({ ...old, first_name: true }))
                  }
                >
                  <EditIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              </Grid>
            </>
          ) : (
            <EditableField
              fieldName="name"
              defaultValue={appUser?.first_name ?? ""}
              fieldLabel="First Name"
              schema={NAME_SCHEMA}
              onUpdate={(data) => handleUpdate("first_name", data.name)}
              onCancel={() =>
                setEditing((old) => ({ ...old, first_name: false }))
              }
            />
          )}
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {!editing.last_name ? (
            <>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontWeight: "bold" }}
                >
                  {"Last name:"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" component="p">
                  {appUser?.last_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  sx={{ marginLeft: "12px" }}
                  color="primary"
                  onClick={() =>
                    setEditing((old) => ({ ...old, last_name: true }))
                  }
                >
                  <EditIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              </Grid>
            </>
          ) : (
            <EditableField
              fieldName="name"
              defaultValue={appUser?.last_name ?? ""}
              fieldLabel="Last Name"
              schema={NAME_SCHEMA}
              onUpdate={(data) => handleUpdate("last_name", data.name)}
              onCancel={() =>
                setEditing((old) => ({ ...old, last_name: false }))
              }
            />
          )}
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {!editing.email ? (
            <>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontWeight: "bold" }}
                >
                  {"Email:"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" component="p">
                  {appUser?.email}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  sx={{ marginLeft: "12px" }}
                  color="primary"
                  onClick={() => setEditing((old) => ({ ...old, email: true }))}
                >
                  <EditIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              </Grid>
            </>
          ) : (
            <EditableField
              fieldName="email"
              defaultValue={appUser?.email ?? ""}
              fieldLabel="Email"
              schema={EMAIL_INPUT_SCHEMA}
              onUpdate={(data) => handleUpdate("email", data.email)}
              onCancel={() => setEditing((old) => ({ ...old, email: false }))}
            />
          )}
        </Grid>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Company:"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" component="p">
              {appUser?.organization?.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <IconButton sx={{ marginLeft: "12px" }} disabled>
              <EditIcon
                sx={{ fontSize: "20px", "&:hover": { fill: "#5F24E0" } }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold" }}
            >
              {"Company Role:"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" component="p">
              {appUser?.role?.role_type}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <IconButton sx={{ marginLeft: "12px" }} disabled>
              <EditIcon
                sx={{ fontSize: "20px", "&:hover": { fill: "#5F24E0" } }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
