// vendor
import axios from "axios";

// internal
import { getHeaders, API_BASE } from "../shared";
import {
  ConversationsSearchResponse,
  ConversationsSearchFilters,
} from "../../types";

export const getAllConversations = async (
  filters: ConversationsSearchFilters,
): Promise<ConversationsSearchResponse | null> => {
  let output = null;
  try {
    const headers = await getHeaders(true);
    const response = await axios({
      method: "POST",
      url: `${API_BASE}/api/chat/corporate/conversations`,
      headers,
      data: JSON.stringify(filters),
    });
    output = {
      conversations: response.data.conversations,
      total: response.data.total,
    };
  } catch (e: unknown) {
    console.error("Failed to fetch conversations", e);
    throw e;
  }
  return output;
};

export const generateVerificationCode = async (
  conversation_uid: string,
): Promise<void> => {
  try {
    const headers = await getHeaders(true);
    await axios({
      method: "POST",
      url: `${API_BASE}/api/chat/verification`,
      headers,
      data: JSON.stringify({ conversation_uid }),
    });
  } catch (e: unknown) {
    console.error("Failed to fetch conversations", e);
    throw e;
  }
};
