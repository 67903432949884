// vendor
import { useMemo, useState, useEffect } from "react";
import { compareDesc } from "date-fns";

// material ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContentCopy from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CodeIcon from "@mui/icons-material/Code";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// internal
import {
  Thumbnail,
  ImageSelect,
  WalkThrough,
  WalkThroughStepsEnum,
} from "../../components";
import { useNotifications } from "../../providers";
import { Widget, Image } from "../../types";
import { useWidgetShareLinks } from "../../hooks";
import { LinkNamesEnum, WidgetShareEnum } from "../../constants";
import { WidgetStatusEnum } from "../../types";

// types
import type { Share, SharePlatform } from "../../types";

const REACT_APP_CHAT_FE_HOST = process.env.REACT_APP_CHAT_FE_HOST ?? "";

export type AlreadyCreated = {
  [key in SharePlatform]?: Share;
};

export function SharingBlock({
  widget,
  image,
}: {
  widget: Widget;
  image: Image;
}) {
  const { showNotificaton } = useNotifications();
  const { linksData, createShare } = useWidgetShareLinks(widget.id, image.id);
  const [loadingState, setLoadingState] = useState<{ [k: string]: boolean }>({
    [LinkNamesEnum.INSTAGRAM]: false,
    [LinkNamesEnum.FACEBOOK]: false,
    [LinkNamesEnum.TWITTER]: false,
    [LinkNamesEnum.PINTEREST]: false,
    [LinkNamesEnum.LINKEDIN]: false,
    [LinkNamesEnum.LINK]: false,
  });
  const [embedModalOpen, setEmbedModalOpen] = useState<boolean>(false);

  const alreadyCreatedMap = useMemo(() => {
    return linksData?.links.reduce((accum: AlreadyCreated, curr: Share) => {
      accum[curr.platform as SharePlatform] = curr;
      return accum;
    }, {});
  }, [linksData]);

  async function copyLink(link: string) {
    try {
      await navigator.clipboard.writeText(link);
      showNotificaton("Link copied", 1000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  function interpolateShareLink(platform: SharePlatform, shorturl: string) {
    switch (platform) {
      case LinkNamesEnum.INSTAGRAM:
        return `https://www.instagram.com`;
      case LinkNamesEnum.FACEBOOK:
        return `https://www.facebook.com/sharer/sharer.php?u=${shorturl}`;
      case LinkNamesEnum.TWITTER:
        return `https://twitter.com/intent/tweet?text=${shorturl}`;
      case LinkNamesEnum.PINTEREST:
        return `http://pinterest.com/pin/create/button/?url=${shorturl}`;
      case LinkNamesEnum.LINKEDIN:
        return `https://www.linkedin.com/sharing/share-offsite/?url=${shorturl}`;
      case LinkNamesEnum.LINK:
        return shorturl;
      default:
        return shorturl;
    }
  }

  async function processLink(platform: SharePlatform, shorturl: string) {
    const finalUrl = interpolateShareLink(platform, shorturl);
    if (platform === LinkNamesEnum.LINK) {
      return copyLink(finalUrl);
    } else {
      return window.open(finalUrl, "_blank");
    }
  }

  async function handleLinkClick(
    platform: SharePlatform,
    widget_id: number,
    image_id: number,
  ) {
    const entry =
      alreadyCreatedMap && alreadyCreatedMap[platform as SharePlatform];
    if (entry) {
      return processLink(platform, entry.shorturl);
    } else {
      setLoadingState((oldState) => {
        return {
          ...oldState,
          [platform]: true,
        };
      });
      // call api to create
      await createShare(platform, widget_id, image_id).then((resp) => {
        if (resp?.shorturl) {
          return processLink(platform, resp.shorturl);
        }
      });
      setLoadingState((oldState) => {
        return {
          ...oldState,
          [platform]: false,
        };
      });
    }
  }

  // Iframe Text & ClipBoard actions
  const iframeCodeWithNav = `<iframe src="${REACT_APP_CHAT_FE_HOST}/widget/${widget.uuid}/${image.uuid}" style="min-height: 800px;" />`;
  const iframeCodeWithOutNav = `<iframe src="${REACT_APP_CHAT_FE_HOST}/widget/${widget.uuid}/${image.uuid}?f=iframe" style="min-height: 800px;" />`;

  const handleClipBoardCopy = () => {
    navigator.clipboard.writeText(iframeCodeWithNav);
  };

  const handleNoNavClipBoardCopy = () => {
    navigator.clipboard.writeText(iframeCodeWithOutNav);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        sx={{ width: "100%", marginTop: "24px" }}
      >
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h6" component="h6" sx={{ margin: "12px 0" }}>
              {WidgetShareEnum.SOCIAL_SUB_HEADER}
            </Typography>
          </Box>
          <Box>
            {!!loadingState.facebook ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() =>
                  handleLinkClick(LinkNamesEnum.FACEBOOK, widget.id, image.id)
                }
                sx={{ marginRight: "6px" }}
              >
                <FacebookIcon
                  sx={{
                    color: alreadyCreatedMap?.facebook ? "#000000" : "gray",
                    fontSize: "40px",
                  }}
                />
              </IconButton>
            )}
            {!!loadingState.twitter ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() =>
                  handleLinkClick(LinkNamesEnum.TWITTER, widget.id, image.id)
                }
                sx={{ marginRight: "6px" }}
              >
                <TwitterIcon
                  sx={{
                    color: alreadyCreatedMap?.twitter ? "#000000" : "gray",
                    fontSize: "40px",
                  }}
                />
              </IconButton>
            )}
            {!!loadingState.linkedin ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() =>
                  handleLinkClick(LinkNamesEnum.LINKEDIN, widget.id, image.id)
                }
                sx={{ marginRight: "6px" }}
              >
                <LinkedInIcon
                  sx={{
                    color: alreadyCreatedMap?.linkedin ? "#000000" : "gray",
                    fontSize: "40px",
                  }}
                />
              </IconButton>
            )}
          </Box>
          <Box>
            {!!loadingState.pinterest ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() =>
                  handleLinkClick(LinkNamesEnum.PINTEREST, widget.id, image.id)
                }
                sx={{ marginRight: "6px" }}
              >
                <PinterestIcon
                  sx={{
                    color: alreadyCreatedMap?.pinterest ? "#000000" : "gray",
                    fontSize: "40px",
                  }}
                />
              </IconButton>
            )}
            {!!loadingState.link ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() =>
                  handleLinkClick(LinkNamesEnum.LINK, widget.id, image.id)
                }
                sx={{ marginRight: "6px" }}
              >
                <LinkIcon
                  sx={{
                    color: alreadyCreatedMap?.link ? "#000000" : "gray",
                    fontSize: "40px",
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h6" component="h6" sx={{ margin: "12px 0" }}>
              {WidgetShareEnum.EMBED_SUB_HEADER}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={() => setEmbedModalOpen(true)}
              endIcon={<CodeIcon />}
            >
              {WidgetShareEnum.EMBED_CTA_TEXT}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h6" component="h6" sx={{ margin: "12px 0" }}>
              {WidgetShareEnum.QR_CODE_SUB_HEADER}
            </Typography>
          </Box>
          <Box>
            {linksData?.qr_code?.qr_code && (
              <Thumbnail
                url={linksData?.qr_code?.qr_code}
                size="xlarge"
                alt="share-widget-qr-code"
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setEmbedModalOpen(false)}
        open={embedModalOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{WidgetShareEnum.EMBED_MODAL_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "24px" }}>
            {WidgetShareEnum.EMBED_MODAL_SUBHEADER}
          </DialogContentText>
          <DialogTitle>{WidgetShareEnum.EMBED_MODAL_WITHOUTNAV}</DialogTitle>
          <Box
            sx={{
              borderRadius: "5px",
              padding: "12px",
              backgroundColor: "lightgray",
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="open drawer"
              onClick={handleNoNavClipBoardCopy}
              sx={{ mr: 2 }}
            >
              <ContentCopy />
            </IconButton>
            <pre>{iframeCodeWithOutNav}</pre>
          </Box>
          <DialogTitle>{WidgetShareEnum.EMBED_MODAL_WITH_NAV}</DialogTitle>
          <Box
            sx={{
              borderRadius: "5px",
              padding: "12px",
              backgroundColor: "lightgray",
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="open drawer"
              onClick={handleClipBoardCopy}
              sx={{ mr: 2 }}
            >
              <ContentCopy />
            </IconButton>
            <pre>{iframeCodeWithNav}</pre>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function WidgetSharing({ widgetData }: { widgetData: Widget }) {
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  useEffect(() => {
    setSelectedImage(widgetData.images[0]);
  }, [widgetData]);

  const isWidgetShareable = useMemo(() => {
    return widgetData.widget_status === WidgetStatusEnum.ACTIVE;
  }, [widgetData]);

  if (!isWidgetShareable) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Alert severity="warning" sx={{ marginBottom: "24px" }}>
          {WidgetShareEnum.INACTIVE_WARNING}
        </Alert>
      </Box>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ flexDirection: "row", justifyContent: "flex-start" }}
    >
      <WalkThrough
        locationID={WalkThroughStepsEnum.WIDGET_LIBRARY_SHARING_IMAGES}
      >
        <ImageSelect
          images={widgetData.images}
          value={selectedImage?.id}
          onChangeFromParent={(img: Image) => setSelectedImage(img)}
        />
      </WalkThrough>
      {!!selectedImage && (
        <SharingBlock widget={widgetData} image={selectedImage} />
      )}
    </Grid>
  );
}

// {!!loadingState.instagram ? (
//   <CircularProgress />
// ) : (
//   <IconButton
//     onClick={() =>
//       handleLinkClick(LinkNamesEnum.INSTAGRAM, widget.id, image.id)
//     }
//     sx={{ marginRight: "6px" }}
//   >
//     <InstagramIcon
//       sx={{
//         color: alreadyCreatedMap?.instagram ? "#000000" : "gray",
//         fontSize: "40px",
//       }}
//     />
//   </IconButton>
// )}
