// vendor
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { MessageInterfaceEnum } from "../../../constants";

export const MessageInput = ({
  onSendMessage,
}: {
  onSendMessage: (msg: string) => void;
}) => {
  const [message, setMessage] = useState("");

  const sendMessage = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    onSendMessage(message);
    setMessage("");
  };

  return (
    <Box sx={{ width: "100%", height: "75px", marginTop: "12px" }}>
      <form onSubmit={(event) => sendMessage(event)}>
        <FormControl sx={{ width: "100%", height: "100%" }}>
          <TextField
            id="outlined-basic-email"
            label={MessageInterfaceEnum.INPUT_PLACEHOLDER}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" type="submit">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </form>
    </Box>
  );
};
