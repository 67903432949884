import type { Image } from "../image";
import type { TrainingFile } from "../training_files";

export type WidgetCategory = {
  id: number;
  uuid: string;
  widget_group: string;
  widget_category: string;
  is_global: boolean;
  created_date: string;
  updated_date: string;
};

export type WidgetCategorySearchResponse = {
  categories: WidgetCategory[];
  total: number;
};

type Location = {
  id: number;
  name: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  created_date: string;
  updated_date: string;
};

export enum WidgetStatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
  BUILDING = "building",
  FAILED = "failed",
  QUEUED = "queued",
  NEW = "new",
}

export type WidgetStatus = `${WidgetStatusEnum}`;

export type Widget = {
  id: number;
  uuid: string;
  widget_status: WidgetStatus;
  is_ai_enabled: boolean;
  is_public: boolean;
  is_age_restricted: boolean;
  is_indexing_enabled: boolean;
  widget_puid?: string;
  widget_title: string;
  widget_description: string;
  widget_details: {
    widget_offer?: string;
    widget_offer_code?: string;
    widget_price?: number;
    widget_text_data?: string;
    start_date?: string;
    end_date?: string;
    widget_agent_name?: string;
    widget_greeting?: string;
    widget_cta_text?: string;
    widget_cta_url?: string;
  };
  images: Image[];
  training_files: TrainingFile[];
  locations: Location[];
  created_date: string;
  updated_date: string;
  widget_category?: WidgetCategory;
  last_triggered_build: string | null;
  last_completed_build: string | null;
};

export type WidgetSearchResponse = {
  widgets: Widget[];
  total: number;
};

export type WidgetDetailsCreationPayload = {
  widget_text_data?: string;
  widget_offer?: string;
  widget_offer_code?: string;
  widget_price?: number;
  start_date?: string;
  end_date?: string;
  widget_agent_name?: string;
  widget_greeting?: string;
  widget_cta_text?: string;
  widget_cta_url?: string;
};

export type WidgetCoreCreationPayload = {
  widget_type: "Sales";
  widget_title: string;
  widget_puid?: string;
  widget_description: string;
  is_ai_enabled: boolean;
  is_public: boolean;
  is_age_restricted: boolean;
  is_indexing_enabled: boolean;
  widget_locations: number[];
  widget_training_files: number[];
  widget_images: number[];
  widget_category_id?: number;
};

export type WidgetCreationPayload = WidgetCoreCreationPayload & {
  widget_details: WidgetDetailsCreationPayload;
};

export type WidgetUploadPayload = Partial<WidgetCreationPayload>;

export type WidgetFormInput = {
  is_ai_enabled: "true" | "false";
  is_public: "true" | "false";
  is_age_restricted: "true" | "false";
  is_indexing_enabled: "true" | "false";
  title: string;
  puid?: string;
  description: string;
  offer?: string;
  offer_code?: string;
  price?: string;
  start_date?: string;
  end_date?: string;
  plain_training_text?: string;
  locations?: string[];
  files?: number[];
  images?: number[];
  widget_category_id?: number;
  cta_text?: string;
  cta_url?: string;
  greeting?: string;
  agent_name?: string;
};

export type WidgetStats = {
  widgets_assigned: number;
  widgets_created: number;
  org_total_widgets: number;
};
