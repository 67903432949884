// vendor
import { useState, useEffect } from "react";

// internal
import { getAllWidgetCategories } from "../../handlers";

// types
import type { WidgetCategorySearchResponse } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  widget_categories: WidgetCategorySearchResponse;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  widget_categories: WidgetCategorySearchResponse;
};

export function useWidgetCategories(page: number, limit: number): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    widget_categories: { categories: [], total: 0 },
  });

  const getCategories = async (parentPage: number, parentLimit: number) => {
    setRequestState({
      widget_categories: { categories: [], total: 0 },
      inProgress: true,
      error: false,
    });
    return getAllWidgetCategories(parentPage, parentLimit)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          widget_categories: resp,
        });
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  useEffect(() => {
    getCategories(page, limit);
  }, [page, limit]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    widget_categories: requestState.widget_categories,
  };
}
