// vendor
import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useEffect,
} from "react";
import { useRecoilValue } from "recoil";

// internal
import {
  getAllUserStats,
  getAllWidgetStats,
  getAllImageStats,
  getAllTrainingFileStats,
} from "../../handlers";
import { firebaseUserAtom } from "../../recoil";

// types
import type {
  UserStats,
  WidgetStats,
  ImageStats,
  TrainingFileStats,
} from "../../types";

export type ProviderStats = UserStats &
  WidgetStats &
  ImageStats &
  TrainingFileStats;

type UserStatsProviderState = {
  userStats: ProviderStats;
  refetchStats: () => Promise<void>;
};

const defaultState = {
  userStats: {
    member_conversations_count: 0,
    conversations_requiring_support: 0,
    unread_conversations: 0,
    org_total_conversations: 0,
    widgets_assigned: 0,
    widgets_created: 0,
    org_total_widgets: 0,
    org_total_images: 0,
    images_in_use: 0,
    org_total_training_files: 0,
    training_files_in_use: 0,
  },
  refetchStats: () => Promise.resolve(),
};

const UserStatsContext = createContext<UserStatsProviderState>(defaultState);

export const useUserStats = () => {
  return useContext(UserStatsContext);
};

const useUserStatsState = () => {
  const firebaseUser = useRecoilValue(firebaseUserAtom);
  const [userStats, setUserStats] = useState<ProviderStats>(
    defaultState.userStats,
  );

  const fetchStats = async () => {
    return Promise.all([
      getAllUserStats(),
      getAllWidgetStats(),
      getAllImageStats(),
      getAllTrainingFileStats(),
    ])
      .then((res) => {
        const [user_stats, widget_stats, image_stats, file_stats] = res;

        const payload = {
          ...user_stats,
          ...widget_stats,
          ...image_stats,
          ...file_stats,
        };

        setUserStats(payload);
      })
      .catch((e) => {
        console.error("Failed to fetch stats", e);
        setUserStats(defaultState.userStats);
      });
  };

  useEffect(() => {
    if (firebaseUser && firebaseUser?.uid) {
      fetchStats();
    }
  }, [firebaseUser]);

  const refetchStats = async () => {
    if (firebaseUser && firebaseUser?.uid) {
      fetchStats();
    }
  };

  return {
    userStats,
    refetchStats,
  };
};

export function UserStatsProvider({ children }: PropsWithChildren) {
  const state = useUserStatsState();
  return (
    <UserStatsContext.Provider value={state}>
      {children}
    </UserStatsContext.Provider>
  );
}
