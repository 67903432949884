// vendor
import { useState } from "react";

// material ui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

// types
import { WidgetCategory } from "../../types";
import { useWidgetCategories } from "../../hooks";

export type CategorySelectProps = {
  value?: number;
  onChangeFromParent: (category_id: number) => void;
  helperText?: string;
};

export function CategorySelect({
  value,
  onChangeFromParent,
  helperText = " ",
}: CategorySelectProps) {
  const [limit, setLimit] = useState<number>(100);
  const { widget_categories = { categories: [], total: 0 } } =
    useWidgetCategories(0, limit);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    const newID = Number(event.target.value); // comes in as a string
    onChangeFromParent(newID);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="select-category-label">Select Category</InputLabel>
      <Select
        labelId="select-category-label"
        id="select-category"
        value={value ? String(value) : ""}
        label="Select Category"
        onChange={handleCategoryChange}
      >
        {widget_categories?.categories.map((cat: WidgetCategory) => (
          <MenuItem key={cat.uuid} value={cat.id}>
            <Typography variant="body1" component="p">
              {`${cat.widget_group}: ${cat.widget_category}`}
            </Typography>
          </MenuItem>
        ))}
        <MenuItem value={undefined}>
          <Typography variant="body1" component="p">
            {"Clear"}
          </Typography>
        </MenuItem>
        {limit < widget_categories?.total && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="text"
              onClick={() => setLimit((oldLimit) => oldLimit + 50)}
            >
              Load more
            </Button>
          </Box>
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
