// vendor
import { createContext, PropsWithChildren, useState, useContext } from "react";

type NotificationProviderState = {
  showNotificaton: (message: string, timeout?: number) => void;
  handleClose: () => void;
  notification: { message: string; timeout: number; open: boolean };
};

const defaultState = {
  showNotificaton: () => {},
  handleClose: () => {},
  notification: { message: "", timeout: 3000, open: false },
};

const NotificationContext =
  createContext<NotificationProviderState>(defaultState);

export const useNotifications = () => {
  return useContext(NotificationContext);
};

const useNotificationState = () => {
  const [notification, setNotification] = useState<{
    message: string;
    timeout: number;
    open: boolean;
  }>({
    message: "",
    timeout: 3000,
    open: false,
  });

  const showNotificaton = (message: string, timeout?: number) => {
    setNotification({ message, timeout: timeout ?? 3000, open: true });
  };

  const handleClose = () => {
    setNotification({ message: "", timeout: 3000, open: false });
  };

  return {
    showNotificaton,
    handleClose,
    notification,
  };
};

export function NotificationProvider({ children }: PropsWithChildren) {
  const state = useNotificationState();
  return (
    <NotificationContext.Provider value={state}>
      {children}
    </NotificationContext.Provider>
  );
}
