export enum LocalStorageItems {
  SIGNIN_EMAIL = "emailForSignIn",
  CREATION_WALKTHROUGH_COMPLETED = "creationWalkthroughCompleted",
  SHARING_WALKTHROUGH_COMPLETED = "sharingWalkthroughCompleted",
}

export const useLocalStorage = () => {
  const getLocalStorageItem = (key: LocalStorageItems): any => {
    let result: any = null;
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        result = item;
      }
    } catch (e) {
      console.error("Unable to read local storage", e);
    }
    return result;
  };

  const setLocalStorageItem = (key: LocalStorageItems, value: any): void => {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      console.error("Unable to set value in local storage", e);
    }
  };

  const removeLocalStorageItem = (key: LocalStorageItems): void => {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      console.error("Unable to remove value from local storage", e);
    }
  };
  return {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
  };
};
