// vendor
import { useMemo, useState } from "react";

// material ui
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkIcon from "@mui/icons-material/Link";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

// internal
import { useSearchPageLinks } from "../../hooks";
import { Thumbnail } from "../../components";
import { useNotifications } from "../../providers";
import { SearchPageEnum, LinkNamesEnum } from "../../constants";

// types
import type { Share, SharePlatform } from "../../types";

export type AlreadyCreated = {
  [key in SharePlatform]?: Share;
};

export function WidgetSearchPage() {
  const isDesktop = useMediaQuery("(min-width:600px)");
  const { linksData, createShare } = useSearchPageLinks();
  const { showNotificaton } = useNotifications();
  const [loadingState, setLoadingState] = useState<{ [k: string]: boolean }>({
    [LinkNamesEnum.INSTAGRAM]: false,
    [LinkNamesEnum.FACEBOOK]: false,
    [LinkNamesEnum.TWITTER]: false,
    [LinkNamesEnum.PINTEREST]: false,
    [LinkNamesEnum.LINKEDIN]: false,
    [LinkNamesEnum.LINK]: false,
  });

  const alreadyCreatedMap = useMemo(() => {
    return linksData?.links.reduce((accum: AlreadyCreated, curr: Share) => {
      accum[curr.platform as SharePlatform] = curr;
      return accum;
    }, {});
  }, [linksData]);

  async function copyLink(link: string) {
    try {
      await navigator.clipboard.writeText(link);
      showNotificaton("Link copied", 1000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  async function handleLinkClick(platform: SharePlatform) {
    const entry =
      alreadyCreatedMap && alreadyCreatedMap[platform as SharePlatform];
    if (entry) {
      return copyLink(entry.shorturl);
    } else {
      setLoadingState((oldState) => {
        return {
          ...oldState,
          [platform]: true,
        };
      });
      // call api to create
      await createShare(platform).then((resp) => {
        if (resp?.shorturl) {
          return copyLink(resp.shorturl);
        }
      });
      setLoadingState((oldState) => {
        return {
          ...oldState,
          [platform]: false,
        };
      });
    }
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: isDesktop ? "75px" : "75px 12px",
        height: "100vh",
        width: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" component="h4" sx={{ marginBottom: "12px" }}>
            {SearchPageEnum.PAGE_HEADER}
            <Tooltip title={SearchPageEnum.HEADER_TOOLTIP}>
              <IconButton sx={{ marginLeft: "0", verticalAlign: "text-top" }}>
                <InfoIcon sx={{ fontSize: "15px", color: "black" }} />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{ marginBottom: "12px" }}
            >
              {SearchPageEnum.SOCIAL_SUB_HEADER}
            </Typography>
          </Box>
          <Box>
            {!!loadingState.facebook ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() => handleLinkClick(LinkNamesEnum.FACEBOOK)}
                sx={{ marginRight: "12px" }}
              >
                <FacebookIcon
                  sx={{
                    color: alreadyCreatedMap?.facebook ? "#000000" : "gray",
                    fontSize: "50px",
                  }}
                />
              </IconButton>
            )}
            {!!loadingState.twitter ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() => handleLinkClick(LinkNamesEnum.TWITTER)}
                sx={{ marginRight: "12px" }}
              >
                <TwitterIcon
                  sx={{
                    color: alreadyCreatedMap?.twitter ? "#000000" : "gray",
                    fontSize: "50px",
                  }}
                />
              </IconButton>
            )}
            {!!loadingState.linkedin ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() => handleLinkClick(LinkNamesEnum.LINKEDIN)}
                sx={{ marginRight: "12px" }}
              >
                <LinkedInIcon
                  sx={{
                    color: alreadyCreatedMap?.linkedin ? "#000000" : "gray",
                    fontSize: "50px",
                  }}
                />
              </IconButton>
            )}
          </Box>
          <Box>
            {!!loadingState.pinterest ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() => handleLinkClick(LinkNamesEnum.PINTEREST)}
                sx={{ marginRight: "12px" }}
              >
                <PinterestIcon
                  sx={{
                    color: alreadyCreatedMap?.pinterest ? "#000000" : "gray",
                    fontSize: "50px",
                  }}
                />
              </IconButton>
            )}
            {!!loadingState.link ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() => handleLinkClick(LinkNamesEnum.LINK)}
                sx={{ marginRight: "12px" }}
              >
                <LinkIcon
                  sx={{
                    color: alreadyCreatedMap?.link ? "#000000" : "gray",
                    fontSize: "50px",
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6" sx={{ marginBottom: "12px" }}>
            {SearchPageEnum.QR_CODE_HEADER}
          </Typography>
          {linksData?.qr_code?.qr_code && (
            <Thumbnail
              url={linksData?.qr_code?.qr_code}
              size="xlarge"
              alt="search-page-qr-code"
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

// {!!loadingState.instagram ? (
//   <CircularProgress />
// ) : (
//   <IconButton
//     onClick={() => handleLinkClick(LinkNamesEnum.INSTAGRAM)}
//     sx={{ marginRight: "12px" }}
//   >
//     <InstagramIcon
//       sx={{
//         fontSize: "50px",
//         color: alreadyCreatedMap?.instagram ? "#000000" : "gray",
//       }}
//     />
//   </IconButton>
// )}
