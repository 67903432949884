// vendor
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty as _isEmpty } from "lodash";

// material ui
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

// internal
import {
  FormTextInput,
  FormMultiLineInput,
  FormDateInput,
  FormCurrencyInput,
  FormSwitch,
  CategorySelect,
} from "../../components";
import { LocationsSelector } from "../Shared/LocationsSelector";
import { ImageUploadRow } from "../Shared/ImageUploadRow";
import { FileUploadRow } from "../Shared/FileUploadRow";
import { WidgetFormInput } from "../../types";
import {
  transformWidgetCreationPayload,
  WIDGET_CREATION_SCHEMA,
} from "../../utils";
import {
  CreationViewEnum,
  LoggedInRoutesEnum,
  EditingViewEnum,
} from "../../constants";
import { useWidget } from "../../hooks";
import { Widget, WidgetCreationPayload } from "../../types";

function EditWidgetForm({
  widget,
  updateWidget,
}: {
  widget: Widget;
  updateWidget: (payload: WidgetCreationPayload) => void;
}) {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState,
    clearErrors,
  } = useForm<WidgetFormInput>({
    resolver: yupResolver(WIDGET_CREATION_SCHEMA),
    defaultValues: {
      // handle name inconsistencies and undefined values
      is_ai_enabled: widget?.is_ai_enabled ? "true" : "false",
      is_public: widget?.is_public ? "true" : "false",
      is_age_restricted: widget?.is_age_restricted ? "true" : "false",
      is_indexing_enabled: widget?.is_indexing_enabled ? "true" : "false",
      title: widget?.widget_title ? widget?.widget_title : "",
      description: widget?.widget_description ? widget?.widget_description : "",
      puid: widget?.widget_puid,
      cta_text: widget?.widget_details?.widget_cta_text,
      cta_url: widget?.widget_details?.widget_cta_url,
      offer: widget?.widget_details?.widget_offer,
      offer_code: widget?.widget_details?.widget_offer_code,
      price: widget?.widget_details?.widget_price
        ? String(widget?.widget_details?.widget_price)
        : undefined,
      start_date: widget?.widget_details?.start_date
        ? widget?.widget_details?.start_date
        : "",
      end_date: widget?.widget_details?.end_date
        ? widget?.widget_details?.end_date
        : "",
      plain_training_text: widget?.widget_details?.widget_text_data,
      greeting: widget?.widget_details?.widget_greeting,
      agent_name: widget?.widget_details?.widget_agent_name,
      images: widget?.images ? widget?.images.map((img) => img.id) : [],
      files: widget?.training_files
        ? widget?.training_files.map((file) => file.id)
        : [],
      locations: widget?.locations
        ? widget?.locations.map((loc) => String(loc.id))
        : [],
      widget_category_id: widget?.widget_category?.id,
    },
  });

  async function handleUpdate(data: WidgetFormInput) {
    const payload = transformWidgetCreationPayload(data);
    await updateWidget(payload);
    navigate(LoggedInRoutesEnum.LIBRARY_PAGE);
  }

  const onSetImagesFromParent = useCallback(
    (valList: number[]) => setValue("images", valList),
    [setValue],
  );

  const onSetFilesFromParent = useCallback(
    (valList: number[]) => setValue("files", valList),
    [setValue],
  );

  const onSetCategoryId = useCallback(
    (category_id: number) => {
      if (!category_id) {
        setValue("widget_category_id", undefined);
      } else {
        setValue("widget_category_id", category_id);
      }
    },
    [setValue],
  );

  const showTraining = watch("is_ai_enabled");
  const currentWidgetCategory = watch("widget_category_id");

  return (
    <Container
      maxWidth="xl"
      sx={{ paddingTop: "75px", backgroundColor: "#FFFFFF" }}
    >
      <Typography variant="h2" component="h2">
        {EditingViewEnum.VIEW_HEADER}
      </Typography>
      <Container maxWidth="lg" sx={{ padding: "36px 0" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              {CreationViewEnum.IMAGES_SUB_HEADER}
              <Tooltip title={CreationViewEnum.IMAGE_UPLOAD_HELPER_TEXT}>
                <IconButton sx={{ marginLeft: "0", verticalAlign: "super" }}>
                  <InfoIcon sx={{ fontSize: "15px", color: "black" }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImageUploadRow
              onSetImagesFromParent={onSetImagesFromParent}
              existingImages={getValues().images}
              errorFromParentForm={
                !!formState.errors.images ? formState.errors.images.message : ""
              }
              onClearError={() => {
                clearErrors("images");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              {CreationViewEnum.DETAILS_SUB_HEADER}
              <Tooltip title={CreationViewEnum.DETAILS_TOOLTIP_TEXT}>
                <IconButton sx={{ marginLeft: "0", verticalAlign: "super" }}>
                  <InfoIcon sx={{ fontSize: "15px", color: "black" }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              name="title"
              control={control}
              label={CreationViewEnum.TITLE_INPUT_LABEL}
              helperText={CreationViewEnum.WIDGET_TITLE_HELPER_TEXT}
              size="medium"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormMultiLineInput
              name="description"
              control={control}
              label={CreationViewEnum.DESCRIPTION_INPUT_LABEL}
              helperText={CreationViewEnum.WIDGET_DESCRIPTION_HELPER_TEXT}
              size="medium"
              required
              currentCount={watch("description")?.length}
              limit={500}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextInput
              name="puid"
              control={control}
              label={CreationViewEnum.PUID_INPUT_LABEL}
              helperText={CreationViewEnum.PUID_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CategorySelect
              value={currentWidgetCategory ?? getValues().widget_category_id}
              onChangeFromParent={onSetCategoryId}
              helperText={CreationViewEnum.WIDGET_CATEGORY_HELPER_TEXT}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              {CreationViewEnum.OPTIONS_SUB_HEADER}
              <Tooltip title={CreationViewEnum.OPTIONS_TOOLTIP_TEXT}>
                <IconButton sx={{ marginLeft: "0", verticalAlign: "super" }}>
                  <InfoIcon sx={{ fontSize: "15px", color: "black" }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormSwitch
              name="is_ai_enabled"
              control={control}
              onLabel={CreationViewEnum.AI_TOGGLE_ON_LABEL}
              offLabel={CreationViewEnum.AI_TOGGLE_OFF_LABEL}
              helperText={CreationViewEnum.AI_TOGGLE_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={6}>
            <FormSwitch
              name="is_age_restricted"
              control={control}
              onLabel={CreationViewEnum.AGE_TOGGLE_ON_LABEL}
              offLabel={CreationViewEnum.AGE_TOGGLE_OFF_LABEL}
              helperText={CreationViewEnum.AGE_TOGGLE_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={6}>
            <FormSwitch
              name="is_indexing_enabled"
              control={control}
              onLabel={CreationViewEnum.INDEXING_TOGGLE_ON_LABEL}
              offLabel={CreationViewEnum.INDEXING_TOGGLE_OFF_LABEL}
              helperText={CreationViewEnum.INDEXING_TOGGLE_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12} md={6}>
            <FormTextInput
              name="cta_text"
              control={control}
              label={CreationViewEnum.CTA_TEXT_LABEL}
              helperText={CreationViewEnum.CTA_TEXT_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextInput
              name="cta_url"
              control={control}
              label={CreationViewEnum.CTA_URL_LABEL}
              helperText={CreationViewEnum.CTA_URL_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              {CreationViewEnum.DEAL_SUB_HEADER}
              <Tooltip title={CreationViewEnum.DEAL_TOOLTIP_TEXT}>
                <IconButton sx={{ marginLeft: "0", verticalAlign: "super" }}>
                  <InfoIcon sx={{ fontSize: "15px", color: "black" }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              name="offer"
              control={control}
              label={CreationViewEnum.OFFER_INPUT_LABEL}
              helperText={CreationViewEnum.OFFER_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              name="offer_code"
              control={control}
              label={CreationViewEnum.OFFER_CODE_INPUT_LABEL}
              helperText={CreationViewEnum.OFFER_CODE_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={12}>
            <FormCurrencyInput
              name="price"
              control={control}
              label={CreationViewEnum.PRICE_INPUT_LABEL}
              helperText={CreationViewEnum.PRICE_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDateInput
              name="start_date"
              control={control}
              label={CreationViewEnum.START_DATE_INPUT_LABEL}
              helperText={CreationViewEnum.START_DATE_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDateInput
              name="end_date"
              control={control}
              label={CreationViewEnum.END_DATE_INPUT_LABEL}
              helperText={CreationViewEnum.END_DATE_HELPER_TEXT}
              size="medium"
            />
          </Grid>
          {showTraining === "true" && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  {CreationViewEnum.TRAINING_SUB_HEADER}
                  <Tooltip title={CreationViewEnum.TRAINING_TOOLTIP_TEXT}>
                    <IconButton
                      sx={{ marginLeft: "0", verticalAlign: "super" }}
                    >
                      <InfoIcon sx={{ fontSize: "15px", color: "black" }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormTextInput
                  name="agent_name"
                  control={control}
                  label={CreationViewEnum.AGENT_NAME_LABEL}
                  helperText={CreationViewEnum.AGENT_NAME_HELPER_TEXT}
                  size="medium"
                />
              </Grid>
              <Grid item xs={12}>
                <FormMultiLineInput
                  name="greeting"
                  control={control}
                  label={CreationViewEnum.GREETING_LABEL}
                  helperText={CreationViewEnum.GREETING_HELPER_TEXT}
                  size="medium"
                  currentCount={watch("greeting")?.length}
                  limit={500}
                />
              </Grid>
              <Grid item xs={12}>
                <FileUploadRow
                  onSetFilesFromParent={onSetFilesFromParent}
                  existingFiles={getValues().files}
                  errorFromParentForm={
                    !!formState.errors.files
                      ? formState.errors.files.message
                      : ""
                  }
                  onClearError={() => {
                    clearErrors("files");
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormMultiLineInput
                  name="plain_training_text"
                  control={control}
                  label={CreationViewEnum.TRAINING_TEXT_INPUT_LABEL}
                  helperText={CreationViewEnum.TRAINING_TEXT_HELPER_TEXT}
                  size="medium"
                  currentCount={watch("plain_training_text")?.length}
                  limit={3000}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              {CreationViewEnum.AVAILABILITY_SUB_HEADER}
              <Tooltip title={CreationViewEnum.LOCATIONS_TOOLTIP_TEXT}>
                <IconButton sx={{ marginLeft: "0", verticalAlign: "super" }}>
                  <InfoIcon sx={{ fontSize: "15px", color: "black" }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LocationsSelector
              onSetPublic={(val) => setValue("is_public", val)}
              onSetLocations={(val) => setValue("locations", val)}
              existingLocations={getValues().locations}
              existingIsPublic={getValues().is_public}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit(handleUpdate)}
            fullWidth
            color={!_isEmpty(formState.errors) ? "error" : "primary"}
          >
            {EditingViewEnum.EDIT_CTA_LABEL}
          </Button>
        </Grid>
      </Container>
    </Container>
  );
}

export function EditWidget() {
  const { widget_uuid } = useParams();
  const { widget, updateWidget } = useWidget(widget_uuid);

  if (!widget) {
    return null;
  }

  return <EditWidgetForm widget={widget} updateWidget={updateWidget} />;
}
