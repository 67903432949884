// vendor
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// material ui
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

// internal
import { NotificationsCard } from "./NotificationsCard";
import { SetupGuide } from "./SetupGuide";
import { useUserStats } from "../../providers";
import { LoggedInRoutesEnum } from "../../constants";
import { StatsCard } from "./StatsCard";

export function Dashboard() {
  const isDesktop = useMediaQuery("(min-width:600px)");
  const { userStats, refetchStats } = useUserStats();
  const navigate = useNavigate();

  useEffect(() => {
    refetchStats();
  }, []);

  return (
    <Container
      sx={{
        padding: isDesktop ? "100px" : "75px 12px",
        height: "100vh",
        width: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={7}>
          <SetupGuide />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <NotificationsCard />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatsCard
            label="Widgets"
            count={userStats.org_total_widgets}
            onClick={() => navigate(LoggedInRoutesEnum.LIBRARY_PAGE)}
            icon={<PhotoLibraryIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatsCard
            label="Conversations"
            count={userStats.org_total_conversations}
            onClick={() => navigate(LoggedInRoutesEnum.CONVERSATIONS_PAGE)}
            icon={<QuestionAnswerIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatsCard
            label="Images"
            count={userStats.org_total_images}
            onClick={() => navigate(LoggedInRoutesEnum.IMAGES_PAGE)}
            icon={<PermMediaIcon />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatsCard
            label="Product Data"
            count={userStats.org_total_training_files}
            onClick={() => navigate(LoggedInRoutesEnum.TRAINING_FILES_PAGE)}
            icon={<FolderCopyIcon />}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
