// vendor
import { useState, useEffect } from "react";

// internal
import { getAllOrgLocations, postLocation } from "../../handlers";
import { useAppUser } from "../../hooks";

// types
import type { LocationGetResponse, CreateLocationPayload } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  locationsData: LocationGetResponse;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  locationsData: LocationGetResponse;
  addLocation: (locationData: CreateLocationPayload) => void;
};

export function useAllLocations(page: number, limit: number): HookState {
  const { appUser } = useAppUser();
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    locationsData: { locations: [], total: 0 },
  });

  const getAllLocations = async (parentPage: number, parentLimit: number) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return getAllOrgLocations(parentPage, parentLimit)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          locationsData: resp ?? { locations: [], total: 0 },
        });
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  const addLocation = async (locationData: CreateLocationPayload) => {
    if (appUser?.organization.id) {
      setRequestState((oldState) => {
        return {
          ...oldState,
          inProgress: true,
          error: false,
        };
      });
      return postLocation({
        ...locationData,
        organization_id: appUser?.organization.id,
      })
        .then(() => {
          setRequestState({
            inProgress: false,
            error: false,
            locationsData: { locations: [], total: 0 },
          });
          getAllLocations(page, limit);
        })
        .catch(() => {
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: true,
            };
          });
        });
    }
  };

  useEffect(() => {
    getAllLocations(page, limit);
  }, [page, limit]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    locationsData: requestState.locationsData,
    addLocation,
  };
}
