// vendor
import { useState, Suspense } from "react";
import { useParams } from "react-router-dom";

// material ui
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

// internal
import {
  ProgressContainer,
  WalkThrough,
  WalkThroughStepsEnum,
} from "../../components";
import { useWidget } from "../../hooks";
import { WidgetPanel } from "./WidgetPanel";
import { WidgetSharing } from "./WidgetSharing";
import { WidgetAnalytics } from "./WidgetAnalytics";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function WidgetView(props: any) {
  const { widget_uuid } = useParams();
  const { widget, inProgress, updateMutlipleWidgetStatus } =
    useWidget(widget_uuid);
  const [activeTab, setActiveTab] = useState<number>(0);
  const isDesktop = useMediaQuery("(min-width:600px)");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (!widget) {
    return null;
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: isDesktop ? "25px" : "25px 12px",
        paddingBottom: 0,
        paddingTop: isDesktop ? "140px" : "70px",
        height: "100vh",
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderTop: "1px solid #d2d2d2",
      }}
    >
      <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <Box>
          <Typography
            variant="h3"
            align="right"
            sx={{
              width: "150px",
              textAlign: "left",
              pb: "30px",
              marginLeft: isDesktop ? "50px" : "0",
            }}
          >
            <WalkThrough
              locationID={WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE}
            >
              Widget
            </WalkThrough>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
          }}
        >
          <Box
            sx={{
              minWidth: isDesktop ? "230px" : "100%",
              paddingBottom: isDesktop ? "0" : "12px",
            }}
          >
            <Tabs
              value={activeTab}
              variant="fullWidth"
              centered
              onChange={handleTabChange}
              aria-label="customer-details-tabs"
              orientation={isDesktop ? "vertical" : "horizontal"}
              sx={{
                width: "100%",
              }}
            >
              <Tab label="Widget" {...a11yProps(0)} />
              <Tab
                icon={
                  <WalkThrough
                    locationID={
                      WalkThroughStepsEnum.WIDGET_LIBRARY_SHARING_PAGE_TAB
                    }
                  >
                    <></>
                  </WalkThrough>
                }
                label="Sharing"
                {...a11yProps(1)}
              />
              <Tab
                icon={
                  <WalkThrough
                    locationID={
                      WalkThroughStepsEnum.WIDGET_LIBRARY_ANALYTICS_PAGE_TAB
                    }
                  >
                    <></>
                  </WalkThrough>
                }
                label="Analytics"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <Box>
            <Suspense fallback={<ProgressContainer />}>
              <TabPanel value={activeTab} index={0}>
                {!!widget && (
                  <WidgetPanel
                    widgetData={widget}
                    onActivate={updateMutlipleWidgetStatus}
                    activationInProgress={inProgress}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                {!!widget && <WidgetSharing widgetData={widget} />}
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                {!!widget && <WidgetAnalytics widgetData={widget} />}
              </TabPanel>
            </Suspense>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
