import { useState, useEffect } from "react";

// internal
import { getTargetCustomerByUuid, updateCustomer } from "../../handlers";

// types
import type { Customer } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  customer: Customer | null;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  customer: Customer | null;
  updateTargetCustomer: (payload: Partial<Customer>) => Promise<void>;
};

export function useCustomer(customerUUID: string): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    customer: null,
  });

  const getTargetCustomer = async (parentCustomerUUID: string) => {
    setRequestState({
      customer: null,
      inProgress: true,
      error: false,
    });
    return getTargetCustomerByUuid(parentCustomerUUID)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          customer: resp,
        });
      })
      .catch(() => {
        setRequestState({
          customer: null,
          inProgress: false,
          error: true,
        });
      });
  };

  const updateTargetCustomer = async (
    payload: Partial<Customer>,
  ): Promise<void> => {
    if (requestState?.customer?.id) {
      setRequestState((oldState) => {
        return {
          ...oldState,
          inProgress: true,
          error: false,
        };
      });
      return updateCustomer(requestState?.customer?.id, payload)
        .then(() => {
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: false,
            };
          });
          getTargetCustomer(customerUUID);
        })
        .catch(() => {
          setRequestState((oldState) => {
            return {
              ...oldState,
              inProgress: false,
              error: true,
            };
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (!!customerUUID) {
      getTargetCustomer(customerUUID);
    }
  }, [customerUUID]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    customer: requestState.customer,
    updateTargetCustomer,
  };
}
