// vendor
import {
  useState,
  useEffect,
  createContext,
  useContext,
  PropsWithChildren,
} from "react";

// internal
import {
  initFirebaseConfig,
  getFBFlagValues,
  defaultConfig,
} from "../../services";

// must match defautl config in firebase service
export enum FeatureFlagEnum {
  SIGNUP_PAGE_ACCESS = "_widgetgpt_prod_signup_access",
}

type FeatureFlagProviderState = { [key in FeatureFlagEnum]: boolean };

const defaultState: FeatureFlagProviderState = defaultConfig;

export const FeatureFlagContext =
  createContext<FeatureFlagProviderState>(defaultState);

export const useFeatureFlags = () => {
  return useContext(FeatureFlagContext);
};

export function useFirebaseFeatureFlags() {
  const [featureFlags, setFeatureFlags] =
    useState<FeatureFlagProviderState>(defaultState);

  useEffect(() => {
    initFirebaseConfig().then(() => {
      const flags = getFBFlagValues();
      const newFlags = {} as unknown as FeatureFlagProviderState;

      for (const [key, config] of Object.entries(flags)) {
        newFlags[key as FeatureFlagEnum] = config.asBoolean();
      }
      setFeatureFlags(newFlags);
    });
  }, []);

  return featureFlags;
}

export function FeatureFlagProvider({ children }: PropsWithChildren) {
  const flags = useFirebaseFeatureFlags();
  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
