// vendor
import { atom, selector } from "recoil";

// types
import type { WidgetUser } from "../../types";

type User = WidgetUser | null;

export const appUserAtom = atom({
  key: "appUserAtom",
  default: null as User,
});

export const getAppUserSelector = selector({
  key: "appUserSelector",
  get: ({ get }) => get(appUserAtom),
  set: ({ set }, newValue) => set(appUserAtom, newValue),
});
