// vendor
import { useState } from "react";

// material ui
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import Alert from "@mui/material/Alert";

// internal
import { useAppUser } from "../../hooks";
import { ProfileTab } from "./ProfileTab";
import { CompanyTab } from "./CompanyTab";
import { LocationsTab } from "./LocationsTab";
import { TeamTab } from "./TeamTab";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function Settings() {
  const { appUser } = useAppUser();
  const [activeTab, setActiveTab] = useState<number>(0);
  const isDesktop = useMediaQuery("(min-width:600px)");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: isDesktop ? "75px" : "75px 12px",
        height: "100vh",
        width: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="customer-details-tabs"
        >
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Company" {...a11yProps(1)} />
          <Tab label="Team" {...a11yProps(2)} />
          <Tab label="Location" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <ProfileTab />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {appUser?.role.role_type === "Owner" ? (
          <CompanyTab />
        ) : (
          <Alert severity="warning">
            {"Only organization Owners can access Company settings."}
          </Alert>
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        {appUser?.role.role_type === "Owner" ? (
          <TeamTab />
        ) : (
          <Alert severity="warning">
            {"Only organization Owners can access Team settings."}
          </Alert>
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        {appUser?.role.role_type === "Owner" ? (
          <LocationsTab />
        ) : (
          <Alert severity="warning">
            {"Only organization Owners can access Location settings."}
          </Alert>
        )}
      </TabPanel>
    </Container>
  );
}
