/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
// vendor
import { Controller } from "react-hook-form";

// material ui
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

type FormMultilineProps = {
  name: string;
  label: string;
  helperText?: string;
  control: any;
  type?: string;
  size?: "small" | "medium";
  styleOverrides?: any;
  required?: boolean;
  limit?: number;
  currentCount?: number;
};

// reference https://blog.logrocket.com/using-material-ui-with-react-hook-form/

export function FormMultiLineInput({
  name,
  control,
  label,
  helperText = " ",
  type,
  size = "small",
  styleOverrides = {},
  required = false,
  limit,
  currentCount = 0,
}: FormMultilineProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          size={size}
          error={!!error}
          onChange={onChange}
          value={value ?? ""}
          fullWidth
          label={label}
          variant="outlined"
          type={type}
          multiline
          rows={4}
          InputLabelProps={{
            shrink: true,
            required,
          }}
          sx={styleOverrides}
          FormHelperTextProps={{
            sx: { "&:first-letter": { textTransform: "capitalize" } },
          }}
          helperText={
            <Box
              component="span"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <span css={css({ paddingRight: "6px" })}>
                {error ? error.message : helperText}
              </span>
              {!!currentCount && !!limit && (
                <span
                  css={css({ color: "#5F24E0" })}
                >{`${currentCount}/${limit}`}</span>
              )}
            </Box>
          }
        />
      )}
    />
  );
}
