// vendor
import { useState } from "react";

// internal
import { getAllAssignedCustomers } from "../../handlers";

// types
import type {
  CustomerSearchResponse,
  CustomerSearchFilters,
} from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  customersList: CustomerSearchResponse;
  searchFilters: CustomerSearchFilters;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  customersList: CustomerSearchResponse;
  getAllCustomers: (newFilters: CustomerSearchFilters) => void;
  searchFilters: CustomerSearchFilters;
};

export function useAllCustomers(): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    customersList: { customers: [], total: 0 },
    searchFilters: {
      page: 0,
      limit: 50,
      sort: {
        order_by: "updated_date",
        direction: "desc",
      },
      filters: {},
    },
  });

  const getAllCustomers = async (newFilters: CustomerSearchFilters) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return getAllAssignedCustomers(newFilters)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          customersList: resp ?? { customers: [], total: 0 },
          searchFilters: newFilters,
        });
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    customersList: requestState.customersList,
    getAllCustomers,
    searchFilters: requestState.searchFilters,
  };
}
