// material ui
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";

// internal
import Carousel from "../../../components/Carousel";
import { useWidget } from "../../../hooks";
import { formatCurrency } from "../../../utils";

export function WidgetPanel({ widgetUuid }: { widgetUuid: string }) {
  const { widget, error, inProgress } = useWidget(widgetUuid);

  const [open, setOpen] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [activeImgVal, setActiveImgVal] = useState(0);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (widget?.images) {
      setImages(widget.images);
    }
  }, [widget]);

  if (!!inProgress) {
    return <CircularProgress color="primary" sx={{ height: "150px" }} />;
  }

  if (!!error) {
    return (
      <Alert severity="error">There was an error loading widget data.</Alert>
    );
  }
  return (
    <Box>
      <Box sx={{ marginBottom: "12px" }}>
        <Box>
          <Carousel
            width="300px"
            images={images}
            onClick={(i: number) => {
              setActiveImgVal(i);
              setOpen(true);
            }}
            activeImg={activeImgVal}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "612px",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                padding: "6px",
              }}
            >
              <Carousel
                width="600px"
                images={images}
                activeImg={activeImgVal}
              />
            </Box>
          </Modal>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "12px" }}>
        <Typography variant="h4" component="h4">
          {widget?.widget_title}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "12px" }}>
        <Typography variant="body1" component="p">
          {widget?.widget_description}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "6px" }}>
        <Typography variant="body1" component="p">
          <strong>Price: </strong>
          {`$${formatCurrency(widget?.widget_details?.widget_price)}`}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "6px" }}>
        <Typography variant="body1" component="p">
          <strong>Offer: </strong>
          {widget?.widget_details?.widget_offer}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "6px" }}>
        <Typography variant="body1" component="p">
          <strong>Offer Code: </strong>
          {widget?.widget_details?.widget_offer_code}
        </Typography>
      </Box>
    </Box>
  );
}
