// vendor
import { useState } from "react";

// internal
import { getAllConversations } from "../../handlers";

// types
import type { Conversation, ConversationsSearchFilters } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  conversationsData: { conversations: Conversation[]; total: number };
  searchFilters: ConversationsSearchFilters;
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  conversationsData: { conversations: Conversation[]; total: number };
  getConversations: (
    parentFilters: ConversationsSearchFilters,
  ) => Promise<void>;
  searchFilters: ConversationsSearchFilters;
};

export function useConversations(): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    conversationsData: { conversations: [], total: 0 },
    searchFilters: {
      page: 0,
      limit: 10,
      sort: {
        order_by: "created_at",
        direction: "desc",
      },
      filters: {},
    },
  });

  const getConversations = async (newFilters: ConversationsSearchFilters) => {
    setRequestState((oldState) => {
      return {
        ...oldState,
        inProgress: true,
        error: false,
      };
    });
    return getAllConversations(newFilters)
      .then((resp) => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: false,
            conversationsData: resp ?? { conversations: [], total: 0 },
            searchFilters: newFilters,
          };
        });
      })
      .catch(() => {
        setRequestState((oldState) => {
          return {
            ...oldState,
            inProgress: false,
            error: true,
          };
        });
      });
  };

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    conversationsData: requestState.conversationsData,
    searchFilters: requestState.searchFilters,
    getConversations,
  };
}
