/** @jsxRuntime classic */
/** @jsx jsx */
// vendor
import { jsx, css } from "@emotion/react";
import { useState, useEffect } from "react";

type HeightXwidth = {
  display: "none" | "inline-block";
  height: string | number;
  width: string | number;
};

const SIZE_MAP = {
  max: "100%",
  xxxlarge: "350px",
  xxlarge: "250px",
  xlarge: "200px",
  large: "150px",
  medium: "100px",
  small: "50px",
};

export function Thumbnail({
  url,
  alt,
  size = "small",
}: {
  url: string;
  alt?: string;
  size?:
    | "small"
    | "medium"
    | "large"
    | "xlarge"
    | "xxlarge"
    | "xxxlarge"
    | "max";
}) {
  const [heightXwidth, setHeightXwidth] = useState<HeightXwidth>({
    display: "none", // reduce shift by hiding image until adjusted
    height: "inherit",
    width: "inherit",
  });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (img.height > img.width) {
        setHeightXwidth({
          display: "inline-block",
          height: SIZE_MAP[size],
          width: "auto",
        });
      } else {
        setHeightXwidth({
          display: "inline-block",
          height: "auto",
          width: SIZE_MAP[size],
        });
      }
    };
    img.src = url;
  }, [url, size]);

  return (
    <div>
      <img src={url} alt={alt ?? ""} css={css(heightXwidth)} />
    </div>
  );
}
