/** @jsxRuntime classic */
/** @jsx jsx */
// vendor
import { jsx } from "@emotion/react";
import { useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format, isToday, isYesterday } from "date-fns";

// internal
import { Message } from "../../../types";

const bubbleRowLeftStyles = {
  display: "flex",
};

const bubbleRowRightStyles = {
  display: "flex",
  justifyContent: "flex-end",
};

const bubbleLeftStyles = {
  position: "relative",
  padding: "10px",
  color: "black",
  backgroundColor: "#c48af8",
  width: "fit-content",
  textAlign: "left",
  borderRadius: "10px",
};

const bubbleRightStyles = {
  position: "relative",
  padding: "10px",
  color: "black",
  backgroundColor: "#C9C9C9",
  width: "fit-content",
  textAlign: "left",
  borderRadius: "10px",
};

const bubbleWrapperRightStyles = {
  margin: "16px 16px 0 0",
  maxWidth: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
};

const bubbleWrapperLeftStyles = {
  margin: "16px 0 0 16px",
  maxWidth: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};

const messageContentStyles = {
  padding: 0,
  margin: 0,
};

export const LeftMessageBubble = ({ message }: { message: Message }) => {
  const timestamp = useMemo(() => {
    if (!message?.created_at?.toDate) {
      return "";
    }
    const date = message.created_at.toDate();

    if (isToday(date)) {
      return format(date, "h:mmaaa");
    } else if (isYesterday(date)) {
      return `Yesterday ${format(date, "h:mmaaa")}`;
    } else {
      return format(date, "ccc, MMM do, h:mmaaa");
    }
  }, [message.created_at]);

  return (
    <Box sx={bubbleRowLeftStyles}>
      <Box sx={bubbleWrapperLeftStyles}>
        <Box sx={bubbleLeftStyles}>
          {message?.media_url && (
            <Box>
              <img
                css={{
                  width: "100%",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
                src={message.media_url}
                alt={message.text}
                loading="lazy"
              />
            </Box>
          )}
          <Typography variant="caption" sx={messageContentStyles}>
            {message.text}
          </Typography>
        </Box>
        <span
          css={{
            fontSize: 10,
            color: "#000000",
            marginTop: "4px",
          }}
        >
          {timestamp}
        </span>
      </Box>
    </Box>
  );
};

export const RightMessageBubble = ({ message }: { message: Message }) => {
  const timestamp = useMemo(() => {
    if (!message?.created_at?.toDate) {
      return "";
    }
    const date = message.created_at.toDate();

    if (isToday(date)) {
      return format(date, "h:mmaaa");
    } else if (isYesterday(date)) {
      return `Yesterday ${format(date, "h:mmaaa")}`;
    } else {
      return format(date, "ccc, MMM do, h:mmaaa");
    }
  }, [message.created_at]);

  return (
    <Box sx={bubbleRowRightStyles}>
      <Box sx={bubbleWrapperRightStyles}>
        <Box sx={bubbleRightStyles}>
          {message?.media_url && (
            <Box>
              <img
                css={{
                  width: "100%",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
                src={message.media_url}
                alt={message.text}
                loading="lazy"
              />
            </Box>
          )}
          <Typography variant="caption" sx={messageContentStyles}>
            {message.text}
          </Typography>
        </Box>
        <span
          css={{
            fontSize: 10,
            color: "#000000",
            marginTop: "4px",
          }}
        >
          {timestamp}
        </span>
      </Box>
    </Box>
  );
};

export const MessageBubble = ({
  message,
  align,
}: {
  message: Message;
  align: "left" | "right";
}) => {
  if (align === "right") {
    return <RightMessageBubble message={message} />;
  } else {
    return <LeftMessageBubble message={message} />;
  }
};
