// vendor
import { v4 as uuidv4 } from "uuid";

// internal
import { getFBAuth } from "../../services";

export const API_BASE = process.env.REACT_APP_API_HOST ?? "";

export type ApiHeaders = {
  authorization?: string;
  "content-type"?: string;
  xid: string;
};

const getToken = async () => {
  const auth = getFBAuth();
  const token = auth?.currentUser?.getIdToken();
  return token ?? Promise.resolve(null);
};

export const getBaseHeaders = async (): Promise<ApiHeaders> => {
  const token = await getToken();
  return !!token
    ? {
        authorization: `Bearer ${token}`,
        xid: uuidv4(), // xid to trace requests
      }
    : {
        xid: uuidv4(), // xid to trace requests
      };
};

export const getHeaders = async (includePayload: boolean) => {
  const headers = await getBaseHeaders();
  if (includePayload) {
    headers["content-type"] = "application/json";
  }
  return headers;
};

export const getFormDataHeaders = async () => {
  const headers = await getBaseHeaders();
  headers["content-type"] = "multipart/form-data";
  return headers;
};
