/** @jsxRuntime classic */
/** @jsx jsx */
// vendor
import { jsx, css } from "@emotion/react";

// internal
import { LOGOS } from "../../app/theme";

export function Logo({
  parentStyles = {},
  type = "full",
}: {
  type?: "slim" | "full";
  parentStyles?: any;
}) {
  return (
    <img
      src={type === "slim" ? LOGOS.LOGO_SLIM_SVG_URL : LOGOS.LOGO_SVG_URL}
      alt={LOGOS.LOGO_ALT}
      css={css({
        height: "auto",
        width: "100%",
        ...parentStyles,
      })}
    />
  );
}
