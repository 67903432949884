// vendor
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

// internal
import { NotesPanel } from "../Shared/NotesPanel";
import { useCustomer } from "../../hooks";
import { formatTimestampToLocal, removeUndefinedFromObject } from "../../utils";
import { Customer } from "../../types";
import { FormTextInput } from "../../components";
import { CUSTOMER_UPDATE_SCHEMA } from "../../utils";

interface IFormInput {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  street_address?: string;
  city?: string;
  state?: string;
  zip_code?: string;
}

export function CustomerDetailForm({
  customer,
  onClose,
  onUpdate,
}: {
  customer: Customer | null;
  onClose: () => void;
  onUpdate: (payload: Partial<Customer>) => void;
}) {
  const { control, handleSubmit } = useForm<IFormInput>({
    resolver: yupResolver(CUSTOMER_UPDATE_SCHEMA),
    defaultValues: {
      first_name: customer?.first_name ?? "",
      last_name: customer?.last_name ?? "",
      email: customer?.email ?? "",
      phone: customer?.phone ?? "",
      street_address: customer?.street_address ?? "",
      city: customer?.city ?? "",
      state: customer?.state ?? "",
      zip_code: customer?.zip_code ?? "",
    },
  });

  if (!customer) {
    return null;
  }

  async function handleUpdate(data: IFormInput) {
    const output = removeUndefinedFromObject(data);
    await onUpdate(output);
    onClose();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button
          variant="text"
          size="medium"
          startIcon={<CloseIcon />}
          onClick={onClose}
          sx={{ marginBottom: "6px" }}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput
          name="first_name"
          control={control}
          label="First Name"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput
          name="last_name"
          control={control}
          label="Last Name"
          size="medium"
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          name="email"
          control={control}
          label="Email"
          size="medium"
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          name="street_address"
          control={control}
          label="Street Address"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput
          name="city"
          control={control}
          label="City"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput
          name="state"
          control={control}
          label="State"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput
          name="zip_code"
          control={control}
          label="Zip Code"
          size="medium"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput
          name="phone"
          control={control}
          label="Phone"
          size="medium"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleSubmit(handleUpdate)}
          fullWidth
          sx={{ mb: 2 }}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
}

export function CustomerDetailView({
  customer,
  onEdit,
}: {
  customer: Customer | null;
  onEdit: () => void;
}) {
  if (!customer) {
    return null;
  }
  return (
    <>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="text"
          size="medium"
          startIcon={<EditIcon />}
          onClick={onEdit}
        >
          Edit
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {"First Name:"}
          </Typography>
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {"Last Name:"}
          </Typography>
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {"Email:"}
          </Typography>
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {"City:"}
          </Typography>
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {"State:"}
          </Typography>
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {"Created Date:"}
          </Typography>
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {"Updated Date:"}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body1" component="p">
            {customer?.first_name ?? "-"}
          </Typography>
          <Typography variant="body1" component="p">
            {customer?.last_name ?? "-"}
          </Typography>
          <Typography variant="body1" component="p">
            {customer?.email ?? "-"}
          </Typography>
          <Typography variant="body1" component="p">
            {customer?.city ?? "-"}
          </Typography>
          <Typography variant="body1" component="p">
            {customer?.state ?? "-"}
          </Typography>
          <Typography variant="body1" component="p">
            {customer?.created_date
              ? formatTimestampToLocal(customer?.created_date)
              : "-"}
          </Typography>
          <Typography variant="body1" component="p">
            {customer?.updated_date
              ? formatTimestampToLocal(customer?.updated_date)
              : "-"}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export function CustomerDetail({ customerUUID }: { customerUUID: string }) {
  const { customer, updateTargetCustomer } = useCustomer(customerUUID);
  const [editing, setEditing] = useState<boolean>(false);

  function handleFormClose() {
    setEditing(false);
  }

  return (
    <Box>
      <Typography variant="h3" component="h3" sx={{ marginBottom: "24px" }}>
        {"Customer Detail"}
      </Typography>
      <Box sx={{ padding: "24px 0", borderTop: "1px solid lightgray" }}>
        {!!editing ? (
          <CustomerDetailForm
            customer={customer}
            onUpdate={updateTargetCustomer}
            onClose={handleFormClose}
          />
        ) : (
          <CustomerDetailView
            customer={customer}
            onEdit={() => setEditing(true)}
          />
        )}
      </Box>
      {!editing && (
        <Box sx={{ padding: "24px 0", borderTop: "1px solid lightgray" }}>
          <NotesPanel customerUuid={customerUUID} />
        </Box>
      )}
    </Box>
  );
}
