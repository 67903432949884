// vendor
import { useState } from "react";

// material ui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// types
import { Widget } from "../../types";
import { useAllWidgets } from "../../hooks";

export type WidgetSelectProps = {
  value?: string;
  onChangeFromParent: (widget_uuid: string) => void;
  stylesOverrides?: any;
};

export function WidgetSelect({
  value,
  onChangeFromParent,
  stylesOverrides = {},
}: WidgetSelectProps) {
  const [limit, setLimit] = useState<number>(100);
  const { widgetListData = { widgets: [], total: 0 } } = useAllWidgets({
    page: 0,
    limit,
  });

  const handleWidgetChange = (event: SelectChangeEvent) => {
    const newID = event.target.value;
    onChangeFromParent(newID);
  };

  return (
    <FormControl sx={{ width: "100%", ...stylesOverrides }}>
      <InputLabel id="select-category-label">Select Widget</InputLabel>
      <Select
        labelId="select-widget-label"
        id="select-widget"
        value={value ? value : ""}
        label="Select Widget"
        onChange={handleWidgetChange}
      >
        {widgetListData.widgets.map((wigt: Widget) => (
          <MenuItem key={wigt.uuid} value={wigt.uuid}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: "50px",
                  paddingRight: "18px",
                }}
                src={wigt.images?.[0]?.image_url}
                alt={wigt.widget_title}
                loading="lazy"
              />
              <Typography variant="body1" component="p">
                {wigt.widget_title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
        <MenuItem value="">
          <Typography variant="body1" component="p">
            {"Clear"}
          </Typography>
        </MenuItem>
        {limit < widgetListData.total && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="text"
              onClick={() => setLimit((oldLimit) => oldLimit + 50)}
            >
              Load more
            </Button>
          </Box>
        )}
      </Select>
    </FormControl>
  );
}
