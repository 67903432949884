// vendor
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

type FormInputProps = {
  name: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  control: any;
  type?: string;
  size?: "small" | "medium";
  styleOverrides?: any;
  required?: boolean;
  disabled?: boolean;
};

// reference https://blog.logrocket.com/using-material-ui-with-react-hook-form/

export function FormTextInput({
  name,
  control,
  label,
  placeholder,
  helperText = " ",
  type,
  size = "small",
  styleOverrides = {},
  required = false,
  disabled = false,
}: FormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : helperText}
          size={size}
          error={!!error}
          onChange={onChange}
          value={value ?? ""}
          fullWidth
          label={label}
          placeholder={placeholder}
          variant="outlined"
          type={type}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
            required,
          }}
          sx={styleOverrides}
          FormHelperTextProps={{
            sx: { "&:first-letter": { textTransform: "capitalize" } },
          }}
        />
      )}
    />
  );
}

export function FormTextInputWithIcon({
  name,
  control,
  label,
  placeholder,
  helperText = " ",
  type,
  size = "small",
  styleOverrides = {},
  required = false,
}: FormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : helperText}
          size={size}
          error={!!error}
          onChange={onChange}
          value={value ?? ""}
          fullWidth
          label={label}
          placeholder={placeholder}
          variant="outlined"
          type={type}
          InputLabelProps={{
            shrink: true,
            required,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" type="submit">
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={styleOverrides}
          FormHelperTextProps={{
            sx: { "&:first-letter": { textTransform: "capitalize" } },
          }}
        />
      )}
    />
  );
}
