// vendor
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// internal
import { useAuth } from "../../providers";
import { FormTextInput, Logo } from "../../components";
import { FORGOT_PASSWORD_SCHEMA } from "../../utils";
import { LoggedOutRoutesEnum } from "../../constants";

interface IFormInput {
  email: string;
}

export function ForgotPassword() {
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<IFormInput>({
    resolver: yupResolver(FORGOT_PASSWORD_SCHEMA),
  });

  function handleReset(data: IFormInput) {
    resetPassword(data.email)
      .then(() => navigate(LoggedOutRoutesEnum.LOGIN_PAGE))
      .catch(() => {
        // show error banner
      });
  }
  return (
    <>
      <Logo parentStyles={{ marginBottom: "4rem" }} />
      <FormTextInput
        name="email"
        control={control}
        label="Email"
        size="medium"
      />
      <Button
        size="large"
        variant="contained"
        onClick={handleSubmit(handleReset)}
        sx={{ mt: 2, mb: 2 }}
        fullWidth
      >
        Reset Password
      </Button>
      <Button
        variant="text"
        onClick={() => navigate(LoggedOutRoutesEnum.LOGIN_PAGE)}
      >
        Cancel
      </Button>
    </>
  );
}
