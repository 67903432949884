import { useState, useEffect } from "react";
import { getAllOrgImages } from "../../handlers";
import { Image } from "../../types";

type RequestState = {
  inProgress: boolean;
  error: boolean;
  imageData: { images: Image[]; total: number };
};

type HookState = {
  inProgress: boolean;
  error: boolean;
  imageData: { images: Image[]; total: number };
};

export function useAllImages(
  page: number,
  limit: number,
  already_in_use: boolean,
): HookState {
  const [requestState, setRequestState] = useState<RequestState>({
    inProgress: false,
    error: false,
    imageData: { images: [], total: 0 },
  });

  const getImages = async (
    parentPage: number,
    parentLimit: number,
    parentInUse: boolean,
  ): Promise<void> => {
    setRequestState({
      inProgress: true,
      error: false,
      imageData: { images: [], total: 0 },
    });
    return getAllOrgImages(parentPage, parentLimit, parentInUse)
      .then((resp) => {
        setRequestState({
          inProgress: false,
          error: false,
          imageData: resp,
        });
      })
      .catch(() => {
        setRequestState({
          inProgress: false,
          error: true,
          imageData: { images: [], total: 0 },
        });
      });
  };

  useEffect(() => {
    getImages(page, limit, already_in_use);
  }, [page, limit, already_in_use]);

  return {
    inProgress: requestState.inProgress,
    error: requestState.error,
    imageData: requestState.imageData,
  };
}
