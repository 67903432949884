import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Timestamp } from "firebase/firestore";

import type { FirebaseTimestamp } from "../../types";

export function formatTimestampToLocal(timestamp: string) {
  const date = new Date(timestamp);
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newTime = toZonedTime(date, timezone);
  const pattern = "dd MMM, yyyy - hh:mma";
  return format(newTime, pattern);
}

export function formatTimestampToLocalDate(timestamp: string) {
  const date = new Date(timestamp);
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newTime = toZonedTime(date, timezone);
  const pattern = "dd MMM, yyyy";
  return format(newTime, pattern);
}

export function formatTimestampToLocalAlt(timestamp: string) {
  const date = new Date(timestamp);
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newTime = toZonedTime(date, timezone);
  const pattern = "MM/dd/yyyy - hh:mma";
  return format(newTime, pattern);
}

export function formatFirebaseTimestamp(firebaseTimestamp: FirebaseTimestamp) {
  let timestamp: any = "";

  if (
    !firebaseTimestamp?.toDate &&
    firebaseTimestamp?._seconds &&
    firebaseTimestamp?._nanoseconds
  ) {
    timestamp = new Timestamp(
      firebaseTimestamp?._seconds,
      firebaseTimestamp?._nanoseconds,
    );
  } else if (
    !firebaseTimestamp?.toDate &&
    !firebaseTimestamp?._seconds &&
    !firebaseTimestamp?._nanoseconds
  ) {
    return "";
  } else {
    timestamp = firebaseTimestamp;
  }
  const date = timestamp.toDate();
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newTime = toZonedTime(date, timezone);
  const pattern = "MM/dd/yyyy - hh:mma";
  return format(newTime, pattern);
}

export function formatDateOnly(timestamp: string) {
  const date = new Date(timestamp);
  const pattern = "MM/dd/yyyy";
  return format(date, pattern);
}

export const formatPhone = (phoneStr = "") => {
  const newStr = phoneStr.replace(/\D/g, "");
  const match = newStr.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneStr;
};

export const formatZip = (zipStr: string) => {
  const newStr = zipStr.replace(/\D/g, "");
  const match = newStr.match(/^(\d{5})$/);
  if (match) {
    return match[0];
  }
  return zipStr;
};

export const formatCurrency = (num?: number) => {
  if (!num) return;
  const numStr = num + "";
  if (numStr.includes(".")) {
    return numStr;
  }
  const newStr = numStr.replace(/\D/g, "");
  return Number(newStr).toLocaleString("en-US");
};

export const formatMonetaryAmounts = (numStr: string) => {
  if (numStr.includes(".")) {
    return numStr;
  }
  const newStr = numStr.replace(/\D/g, "");
  return `$${Number(newStr).toLocaleString("en-US")}`;
};
