// vendor
import { useState, Suspense } from "react";
import { useRecoilValue } from "recoil";

// material ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/material/styles";

// internal
import { useMessages } from "../../../providers";
import { generateVerificationCode } from "../../../handlers";
import { firebaseUserAtom } from "../../../recoil";
import { ProgressContainer } from "../../../components";
import { ChatDetailsEnum } from "../../../constants";
import { CustomerPanel } from "./CustomerPanel";
import { WidgetPanel } from "./WidgetPanel";
import { NotesPanel } from "../../Shared/NotesPanel";

type ChatDetailsProps = {
  handleExit: () => void;
};

export type ConversationStatus = "AI_ACTIVE" | "HUMAN_CHAT" | "REP_ENGAGED";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function StatusMessage({ status }: { status: ConversationStatus }) {
  const theme = useTheme();

  if (status === "AI_ACTIVE") {
    return (
      <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
        <strong
          style={{ color: "#101115" }}
        >{`${ChatDetailsEnum.STATUS_LABEL}: `}</strong>
        {ChatDetailsEnum.STATUS_ONE}
      </Typography>
    );
  }
  if (status === "HUMAN_CHAT") {
    return (
      <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
        <strong
          style={{ color: "#101115" }}
        >{`${ChatDetailsEnum.STATUS_LABEL}: `}</strong>
        {ChatDetailsEnum.STATUS_TWO}
      </Typography>
    );
  }
  if (status === "REP_ENGAGED") {
    return (
      <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
        <strong
          style={{ color: "#101115" }}
        >{`${ChatDetailsEnum.STATUS_LABEL}: `}</strong>
        {ChatDetailsEnum.STATUS_THREE}
      </Typography>
    );
  }
  return null;
}

function ButtonStatus({
  status,
  enableAiChat,
  enableHumanChat,
}: {
  status: ConversationStatus;
  enableAiChat: () => void;
  enableHumanChat: () => void;
}) {
  return (
    <ButtonGroup
      variant="outlined"
      aria-label="outlined button group"
      sx={{
        marginTop: "12px",
        paddingBottom: "12px",
      }}
    >
      <Button
        onClick={enableAiChat}
        startIcon={
          status === "AI_ACTIVE" ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        variant={status === "AI_ACTIVE" ? "contained" : undefined}
      >
        {ChatDetailsEnum.BUTTON_LABEL_ONE}
      </Button>
      <Button
        onClick={enableHumanChat}
        startIcon={
          status === "HUMAN_CHAT" ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        variant={status === "HUMAN_CHAT" ? "contained" : undefined}
      >
        {ChatDetailsEnum.BUTTON_LABEL_TWO}
      </Button>
      <Button
        startIcon={
          status === "REP_ENGAGED" ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        variant={status === "REP_ENGAGED" ? "contained" : undefined}
      >
        {ChatDetailsEnum.BUTTON_LABEL_THREE}
      </Button>
    </ButtonGroup>
  );
}

export function ChatDetails(props: ChatDetailsProps) {
  const { handleExit } = props;
  const {
    targetConversation,
    toggleAiChatOnTarget,
    addCurrentUserToConversation,
  } = useMessages();
  const firebaseUser = useRecoilValue(firebaseUserAtom);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [verificationLoading, setVerificationLoading] =
    useState<boolean>(false);

  const isActiveParticipant =
    targetConversation?.members &&
    firebaseUser?.uid &&
    targetConversation?.members.includes(firebaseUser?.uid);

  const buttonStatus: ConversationStatus = targetConversation?.is_ai_enabled
    ? "AI_ACTIVE"
    : isActiveParticipant
      ? "HUMAN_CHAT"
      : "REP_ENGAGED";

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const enableAiChat = () => {
    if (!targetConversation?.is_ai_enabled) {
      toggleAiChatOnTarget(true);
    }
  };

  const enableHumanChat = () => {
    if (!!firebaseUser?.uid) {
      addCurrentUserToConversation();
    }
  };

  const sendVerificationCodeMessage = async () => {
    if (targetConversation?.conversation_uid) {
      setVerificationLoading(true);
      await generateVerificationCode(targetConversation.conversation_uid);
      setVerificationLoading(false);
    }
  };

  return (
    <Box>
      <Box>
        <Button
          size="large"
          onClick={handleExit}
          sx={{
            marginBottom: "6px",
            backgroundColor: "#FFFFFF",
            paddingLeft: 0,
          }}
        >
          {ChatDetailsEnum.BACK_LINK_TEXT}
        </Button>
        <Typography variant="body1">
          <StatusMessage status={buttonStatus} />
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonStatus
            status={buttonStatus}
            enableAiChat={enableAiChat}
            enableHumanChat={enableHumanChat}
          />
        </Box>
      </Box>
      <Box sx={{ borderTop: "1px solid lightgray" }}>
        <Box>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="customer-details-tabs"
            centered
            variant="fullWidth"
          >
            <Tab label={ChatDetailsEnum.TAB_LABEL_ONE} {...a11yProps(0)} />
            <Tab label={ChatDetailsEnum.TAB_LABEL_TWO} {...a11yProps(1)} />
            <Tab label={ChatDetailsEnum.TAB_LABEL_THREE} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Suspense fallback={<ProgressContainer />}>
          <TabPanel value={activeTab} index={0}>
            {targetConversation && (
              <CustomerPanel
                customerUuid={targetConversation.customer_uuid}
                handleSendVerification={sendVerificationCodeMessage}
                verificationLoading={verificationLoading}
                buttonStatus={buttonStatus}
              />
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            {targetConversation && (
              <NotesPanel customerUuid={targetConversation.customer_uuid} />
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            {targetConversation && (
              <WidgetPanel widgetUuid={targetConversation.widget_uuid} />
            )}
          </TabPanel>
        </Suspense>
      </Box>
    </Box>
  );
}
