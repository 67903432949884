// vendor
import axios from "axios";

// internal
import { getHeaders, API_BASE } from "../shared";

// types
import type {
  CustomerSearchResponse,
  CustomerNotesResponse,
  Customer,
  CustomerSearchFilters,
} from "../../types";

export const getTargetCustomerByUuid = async (customerUuid: string) => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/customers/uuid/${customerUuid}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch target customer", e);
  }
  return result;
};

export const getAllAssignedCustomers = async (
  filters: CustomerSearchFilters,
): Promise<CustomerSearchResponse | null> => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "POST",
      url: `${API_BASE}/api/customers/search`,
      headers,
      data: JSON.stringify(filters),
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch customers", e);
  }
  return result;
};

export const getAllCustomerNotes = async (
  customer_uuid: string,
  page: number,
  limit: number,
): Promise<CustomerNotesResponse> => {
  let result = null;
  try {
    const headers = await getHeaders(false);
    const resp = await axios({
      method: "GET",
      url: `${API_BASE}/api/customers/notes/uuid/${customer_uuid}?page=${page}&limit=${limit}`,
      headers,
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to fetch customer notes", e);
  }
  return result;
};

export const postCustomerNote = async (payload: {
  customer_id: number;
  text: string;
}): Promise<void> => {
  try {
    const headers = await getHeaders(true);
    await axios({
      method: "POST",
      url: `${API_BASE}/api/customers/notes`,
      headers,
      data: JSON.stringify(payload),
    });
  } catch (e: unknown) {
    console.error("Failed to post customer note", e);
  }
};

export const updateCustomer = async (
  customerID: number,
  payload: Partial<Customer>,
) => {
  let result = null;
  try {
    const headers = await getHeaders(true);
    const resp = await axios({
      method: "PUT",
      url: `${API_BASE}/api/customers/${customerID}`,
      headers,
      data: JSON.stringify(payload),
    });
    result = resp.data;
  } catch (e: unknown) {
    console.error("Failed to update customer", e);
  }
  return result;
};
