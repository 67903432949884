// vendor
import { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Toolbar from "@mui/material/Toolbar";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@mui/material/Alert";

// internal
import { useAllOrgUsers, useAppUser } from "../../hooks";
import { formatTimestampToLocalAlt, ADD_USER_SCHEMA } from "../../utils";
import { FormTextInput, MenuTableCell } from "../../components";
import { WidgetUser } from "../../types";

interface AddUserFormInput {
  first_name: string;
  last_name: string;
  email: string;
}

export function AddUserSection({
  onAddUser,
  inProgress,
  onClose,
}: {
  onAddUser: (data: AddUserFormInput) => void;
  inProgress: boolean;
  onClose: () => void;
}) {
  const isDesktop = useMediaQuery("(min-width:600px)");
  const { control, handleSubmit } = useForm<AddUserFormInput>({
    resolver: yupResolver(ADD_USER_SCHEMA),
  });

  async function handleUpdate(data: AddUserFormInput) {
    await onAddUser({
      ...data,
      email: data.email.toLowerCase(),
    });
  }

  return (
    <Box sx={{ width: !isDesktop ? "100%" : "50%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingRight: "24px",
        }}
      >
        <Typography variant="h6" component="h6" sx={{ marginBottom: "24px" }}>
          {"Add Users"}
        </Typography>
        <Button
          variant="text"
          size="large"
          startIcon={<CloseIcon />}
          onClick={onClose}
        >
          {"Close"}
        </Button>
      </Box>
      <Box>
        <FormTextInput
          name="email"
          control={control}
          label="Email"
          size="small"
          styleOverrides={{ mb: "12px" }}
        />
        <FormTextInput
          name="first_name"
          control={control}
          label="First name"
          size="small"
          styleOverrides={{ mb: "12px" }}
        />
        <FormTextInput
          name="last_name"
          control={control}
          label="Last Name"
          size="small"
          styleOverrides={{ mb: "12px" }}
        />
        {inProgress ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleSubmit(handleUpdate)}
            fullWidth
          >
            Add
          </Button>
        )}
      </Box>
    </Box>
  );
}

export function TeamTab() {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const { userListData, addUser, deleteUser, inProgress } = useAllOrgUsers(
    page,
    limit,
  );
  const { appUser } = useAppUser();

  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [removeUser, setRemoveUser] = useState<{
    modalOpen: boolean;
    target: WidgetUser | null;
  }>({ modalOpen: false, target: null });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const transformedUserList = useMemo(() => {
    return userListData.users.map((usr, idx) => {
      return {
        ...usr,
        entry_number: idx + 1 + page * limit,
        created_date: formatTimestampToLocalAlt(usr.created_date),
        updated_date: formatTimestampToLocalAlt(usr.updated_date),
      };
    });
  }, [userListData.users, page, limit]);

  function handleModalOpen(user: WidgetUser) {
    setRemoveUser({
      modalOpen: true,
      target: user,
    });
  }

  function handleModalClose() {
    setRemoveUser({
      modalOpen: false,
      target: null,
    });
  }

  async function handleUserRemove() {
    if (removeUser?.target?.id) {
      await deleteUser(removeUser.target.id);
      handleModalClose();
    }
  }

  async function handleAddUser(data: any) {
    await addUser(data);
    setShowAdd(false);
  }

  return (
    <Box>
      {showAdd ? (
        <AddUserSection
          onAddUser={handleAddUser}
          inProgress={inProgress}
          onClose={() => setShowAdd(false)}
        />
      ) : (
        <Box sx={{ marginBottom: "24px" }}>
          <Typography variant="h5" component="h5" sx={{ marginBottom: "24px" }}>
            {"Users"}
          </Typography>
          <Paper sx={{ width: "100%", mb: 2 }}>
            {inProgress && <LinearProgress />}
            <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={() => setShowAdd(true)}>
                <AddCircleIcon color="primary" />
              </IconButton>
            </Toolbar>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" padding="normal">
                      {"Entry"}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {"Email"}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {"First name"}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {"Last name"}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {"Role"}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {"Created"}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {"Updated"}
                    </TableCell>
                    <TableCell align="left" padding="normal" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transformedUserList.map((row, idx) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.firebase_uid}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">{row.entry_number}</TableCell>
                        <TableCell align="left">{row.email ?? "-"}</TableCell>
                        <TableCell align="left">{row.first_name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell align="left">
                          {row?.role?.role_type ?? "-"}
                        </TableCell>
                        <TableCell align="left">{row?.created_date}</TableCell>
                        <TableCell align="left">{row?.updated_date}</TableCell>
                        <TableCell>
                          {appUser?.id !== row.id &&
                            row?.role?.role_type !== "Owner" && (
                              <MenuTableCell
                                options={[
                                  {
                                    key: "remove-user",
                                    label: "Remove user",
                                    onClickOption: () => handleModalOpen(row),
                                  },
                                ]}
                              />
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={userListData.total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <Dialog
            onClose={handleModalClose}
            open={removeUser.modalOpen}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>{`Do you wish to continue removing ${removeUser.target?.first_name} ${removeUser.target?.last_name}?`}</DialogTitle>
            <DialogContent>
              <Alert severity="warning">
                {"Warning this action is irreversible!"}
              </Alert>
              <DialogContentText sx={{ marginTop: "24px" }}>
                {
                  "The user will lose access to all company resources and all assigned widgets and customers will be re-assigned to the user that holds the Owner role."
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose}>Cancel</Button>
              <Button onClick={handleUserRemove}>Continue</Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
}
