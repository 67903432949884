// material ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

// internal
import { formatTimestampToLocal } from "../../../utils";
import { useCustomer } from "../../../hooks";

// types
import type { ConversationStatus } from "./ChatDetails";

export function CustomerPanel({
  customerUuid,
  handleSendVerification,
  verificationLoading,
  buttonStatus,
}: {
  customerUuid: string;
  handleSendVerification: () => void;
  verificationLoading: boolean;
  buttonStatus: ConversationStatus;
}) {
  const { customer, inProgress, error } = useCustomer(customerUuid);

  if (!!inProgress) {
    return <CircularProgress color="primary" sx={{ height: "150px" }} />;
  }

  if (!!error) {
    return (
      <Alert severity="error">There was an error loading customer data.</Alert>
    );
  }

  return (
    <Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>First Name: </strong>
          {customer?.first_name}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>Last Name: </strong>
          {customer?.last_name}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>Email: </strong>
          {customer?.email}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>Phone: </strong>
          {customer?.phone}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>Street Address: </strong>
          {customer?.street_address}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>City: </strong>
          {customer?.city}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>State: </strong>
          {customer?.state}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>Zip Code: </strong>
          {customer?.zip_code}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" component="p">
          <strong>Created Date: </strong>
          {customer?.created_date
            ? formatTimestampToLocal(customer?.created_date)
            : ""}
        </Typography>
      </Box>
    </Box>
  );
}

// <Box sx={{ paddingTop: "24px" }}>
//         {!verificationLoading ? (
//           <Button
//             variant="contained"
//             onClick={handleSendVerification}
//             disabled={buttonStatus !== "HUMAN_CHAT"}
//           >
//             Generate Verification Code
//           </Button>
//         ) : (
//           <CircularProgress color="primary" />
//         )}
//       </Box>
