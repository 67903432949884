// vendor
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";
import { Suspense } from "react";

// internal
import theme from "./theme";
import AppRouter from "./router";
import {
  AuthProvider,
  NotificationProvider,
  FeatureFlagProvider,
  WalkThroughProvider,
  UserStatsProvider,
} from "../providers";
import { ProgressContainer } from "../components";

// styles
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<ProgressContainer />}>
        <CssBaseline />
        <RecoilRoot>
          <NotificationProvider>
            <FeatureFlagProvider>
              <AuthProvider>
                <WalkThroughProvider>
                  <UserStatsProvider>
                    <AppRouter />
                  </UserStatsProvider>
                </WalkThroughProvider>
              </AuthProvider>
            </FeatureFlagProvider>
          </NotificationProvider>
        </RecoilRoot>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
