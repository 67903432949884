// vendor
import React, { useState } from "react";

// material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CreateIcon from "@mui/icons-material/Create";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

// internal
import { useCustomerNotes } from "../../hooks";
import { formatTimestampToLocal } from "../../utils";
import { ChatDetailsEnum } from "../../constants";

export function NotesPanel({ customerUuid }: { customerUuid: string }) {
  const [note, setNote] = useState<string>("");
  const { customerNotes, postNote, inProgress, error } = useCustomerNotes(
    customerUuid,
    0,
    10,
  );

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    if (note.trim() === "") {
      alert("Enter valid note");
      return;
    }
    await postNote(note);
    setNote("");
  }

  if (!!inProgress && customerNotes?.notes?.length < 1) {
    return <CircularProgress color="primary" sx={{ height: "150px" }} />;
  }

  if (!!error) {
    return (
      <Alert severity="error">There was an error loading CRM notes.</Alert>
    );
  }

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          maxHeight: "400px",
          width: "100%",
          overflow: "scroll",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {customerNotes.notes.map((note) => (
          <Box key={note.id} sx={{ marginBottom: "12px" }}>
            <Typography variant="caption" component="p" color="gray">
              {formatTimestampToLocal(note.created_date)}
            </Typography>
            <Typography variant="subtitle2" component="p">
              {note.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          height: "125px",
          width: "100%",
          marginTop: "24px",
        }}
      >
        <form onSubmit={onSubmit}>
          <FormControl sx={{ width: "100%", height: "100%" }}>
            <TextField
              id="outlined-multiline-static"
              label={ChatDetailsEnum.NOTES_INPUT_PLACEHOLDER}
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="primary" type="submit">
                      <CreateIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </form>
      </Box>
    </Box>
  );
}
