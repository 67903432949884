// vendor
import React, { useState, useEffect, useMemo } from "react";
import { truncate as _truncate } from "lodash";

// material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import LinearProgress from "@mui/material/LinearProgress";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// internal
import { WidgetSelect } from "../../components";
import { useConversations } from "../../hooks";
import { ActiveConversationTableEnum } from "../../constants";
import { formatFirebaseTimestamp } from "../../utils";

// types
import type { ConversationsSearchFilters } from "../../types";

type ActiveChatsTableProps = {
  handleOnClickView: (conversationUid: string) => void;
};

export function ActiveChatsTable(props: ActiveChatsTableProps) {
  const { handleOnClickView } = props;
  const [selectedWidgetUuid, setSelectedWidgetUuid] = useState<string>("");
  const [needsSupportOnly, setNeedsSupportOnly] = useState<"true" | "false">(
    "false",
  );
  const { conversationsData, getConversations, searchFilters, inProgress } =
    useConversations();

  const transformedActiveList = useMemo(() => {
    return conversationsData.conversations.map((chat) => {
      return {
        id: chat.conversation_uid,
        intervene: chat?.requires_support,
        msg_count: chat?.message_count,
        active: chat.is_active ? "Yes" : "No",
        public: chat.is_public ? "Yes" : "No",
        last_message: chat?.last_message?.text
          ? _truncate(chat?.last_message?.text, {
              length: 50,
              separator: " ",
            })
          : "",
        created: chat?.created_at
          ? formatFirebaseTimestamp(chat?.created_at)
          : "",
      };
    });
  }, [conversationsData]);

  useEffect(() => {
    getConversations({
      ...searchFilters,
      page: 0,
      limit: 10,
      filters: { is_ai_enabled: true },
    });
  }, []);

  useEffect(() => {
    const filters: ConversationsSearchFilters = {
      ...searchFilters,
      page: 0,
      limit: 10,
      filters: { is_ai_enabled: true },
    };

    if (selectedWidgetUuid?.length > 0) {
      filters.filters.widget_uuid = selectedWidgetUuid;
    }

    if (needsSupportOnly === "true") {
      filters.filters.requires_support = true;
    }

    getConversations(filters);
  }, [selectedWidgetUuid, needsSupportOnly]);

  const total = conversationsData.total ?? 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    getConversations({ ...searchFilters, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    getConversations({
      ...searchFilters,
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  return (
    <Box sx={{ marginBottom: "12px" }}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {inProgress && <LinearProgress />}
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {ActiveConversationTableEnum.TABLE_HEADER}
            </Typography>
            <FormControlLabel
              sx={{ minWidth: "fit-content" }}
              control={
                <Checkbox
                  checked={needsSupportOnly === "true"}
                  onChange={() =>
                    setNeedsSupportOnly((existing) => {
                      if (existing === "true") {
                        return "false";
                      } else {
                        return "true";
                      }
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Requires support only"
            />
            <WidgetSelect
              onChangeFromParent={(uuid) => setSelectedWidgetUuid(uuid)}
              stylesOverrides={{ margin: "12px" }}
              value={selectedWidgetUuid}
            />
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    {ActiveConversationTableEnum.COLUMN_ONE}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {ActiveConversationTableEnum.COLUMN_TWO}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {ActiveConversationTableEnum.COLUMN_THREE}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {ActiveConversationTableEnum.COLUMN_FOUR}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {ActiveConversationTableEnum.COLUMN_FIVE}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {ActiveConversationTableEnum.COLUMN_SIX}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transformedActiveList.map((row) => {
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleOnClickView(row.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="left">
                        {row.intervene ? (
                          <NotificationImportantIcon color="error" />
                        ) : null}
                      </TableCell>
                      <TableCell align="left">{row.active}</TableCell>
                      <TableCell align="left">{row.public}</TableCell>
                      <TableCell align="left">{row.msg_count}</TableCell>
                      <TableCell align="left">
                        <Typography variant="caption">
                          {row.last_message}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{row.created}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={total}
            rowsPerPage={searchFilters.limit}
            page={searchFilters.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
