// vendor
import { useRef, useEffect } from "react";

// material ui
import Box from "@mui/material/Box";

// internal
import { Message, UserState } from "../../../types";
import { MessageBubble } from "./MessageBubble";

const REACT_APP_AI_SERVICE_AGENT_UID =
  process.env.REACT_APP_AI_SERVICE_AGENT_UID ?? "";

export function MessageWindow({
  messages,
  user,
}: {
  messages: Message[];
  user: UserState;
}) {
  const scrollRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (scrollRef && scrollRef?.current) {
      const callback = function (mutationsList: any[]) {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList" && scrollRef?.current) {
            scrollRef.current.scroll({
              top: scrollRef.current.scrollHeight,
              behavior: "smooth",
            });
          }
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(scrollRef.current, { childList: true });
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(242, 238, 238, .5)",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        paddingBottom: "10px",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      id="chat-area"
      ref={scrollRef}
    >
      {messages?.map((message: Message) => (
        <MessageBubble
          key={message?.message_uid}
          message={message}
          align={
            message.author_uid === user?.uid ||
            message.author_uid === REACT_APP_AI_SERVICE_AGENT_UID ||
            message.author_uid === null
              ? "right"
              : "left"
          }
        />
      ))}
    </Box>
  );
}
